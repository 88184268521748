import { Col, Container, Row } from "reactstrap";
import Wizard from './../../components/Wizard'
import { useEffect, useRef, useState } from 'react'
import AboutProperty from "views/property/about";
import Media from "views/property/media";
import Additional from "views/property/additional";
import Company from "./company/index";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import { useParams } from "react-router-dom";
import { propertyConstants } from "redux/constants";
import { appTitle } from "utils";
import { checkNotChannelPartner } from "utils";
import { FiCopy } from "react-icons/fi";
import clipboardCopy from "clipboard-copy";
import { toast } from "react-toastify";
import { useGetCode } from "hooks/useTokenSellect";
const AddEditProperty = () => {
    const dispatch = useDispatch()
    const { property_id } = useParams();
    const { isPropertyLoading, property } = useSelector(state => state.propertyReducer);
    const ref = useRef(null)
    const [stepper, setStepper] = useState(null)
    var sc = property?.step_completed;
    const { data: code } = useGetCode({
        id: property_id
    })
    appTitle(property_id ? 'Edit Property' : 'Add Property')
    const steps = [
        {
            id: 'step-1',
            title: 'About Asset',
            subtitle: sc >= 1.3 ? 'Completed' : 'In Progress',
            icon: <i className='fa fa-home'></i>,
            content: <AboutProperty parentStepper={stepper} />,
        },
        {
            id: 'step-2',
            title: 'Media & Docs',
            subtitle: sc >= 1.3 && sc < 2.2 ? 'In Progress' : (sc >= 2.2 ? 'Completed' : 'Not Completed'),
            icon: <i className='fa fa-home'></i>,
            content: <Media parentStepper={stepper} />,
            isCompleted: sc >= 1.3
        },
        {
            id: 'step-3',
            title: 'Other Details',
            subtitle: !property?.location ? 'In Progress' : (property?.location ? 'Completed' : 'Not Completed'),
            icon: <i className='fa fa-home'></i>,
            content: <Additional parentStepper={stepper} />,
        }
    ]
    if (checkNotChannelPartner()) {
        steps.push(
            {
                id: 'step-4',
                title: 'Company & DAO',
                subtitle: sc >= 2.2 && sc < 3.4 ? 'In Progress' : (sc >= 3.4 ? 'Completed' : 'Not Completed'),
                icon: <i className='fa fa-home'></i>,
                content: <Company parentStepper={stepper} />,
            })
    }
    useEffect(() => {
        if (property_id) getProperty(property_id)
        else dispatch({ type: propertyConstants.PROPERTY_EMPTY })
    }, []);
    const getProperty = async (id) => {
        dispatch(propertyActions.getProperty(id))
    }
    const handleCopy = (text) => {
        clipboardCopy(text)
            .then(() => {
                toast.success('Text copied to clipboard!');
            })
            .catch((error) => {
                toast.error('Failed to copy text:' + error?.toString());
            });
    };
    return (
        <Container className="mt-5" fluid>
            <div id="card-overlay" className={isPropertyLoading ? 'd-block' : 'd-none'}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <Wizard
                type='modern-horizontal'
                ref={ref}
                steps={steps}
                className='shadow-none'
                options={{
                    linear: false
                }}
                instance={el => setStepper(el)}
            />
            <Row>
                {
                    !checkNotChannelPartner() &&
                    <>
                        {
                            code && code?.status == 'Active' && <Col className="d-flex" sm={{ size: 6, offset: 1 }}>
                                <div>
                                    <div>
                                        <p className="mb-0" style={{ color: '#A8B1BD', fontWeight: 500 }}>Asset ID: </p>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <p className="mr-2 mb-0" style={{ color: '#1F2329', fontWeight: 600 }}>{code.code}</p>
                                        <FiCopy onClick={() => handleCopy(code.code)} className="cursor-pointer" style={{ color: '#725AEC', fontSize: '20px' }} />
                                    </div>
                                </div>
                            </Col>
                        }
                    </>
                }
            </Row>
        </Container>
    )
}

export default AddEditProperty;