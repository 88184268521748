import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormFeedback,
    Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MdChevronLeft } from "react-icons/md";
const otpSchema = yup.object().shape({
    otp: yup.string().length(4, 'Otp must be 4 digit'),
});

const Otp = () => {
    const { register, handleSubmit, control, formState: { errors, isValid, isDirty } } = useForm({ resolver: yupResolver(otpSchema) });
    const dispatch = useDispatch();
    const verifyingOtp = useSelector(state => state.verifyOtpReducer.verifyingOtp);
    const auth = useSelector(state => state.authReducer.user);
    const user = useSelector(state => state.loginReducer.user);
    const { state } = useLocation();
    const [tmp, setTmp] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (user === undefined && auth != null) {
            history.replace('/auth/login')
        }
    }, [user]);

    function onOtpSubmit(data) {
        data.user_id = user.user_id
        data.role = user?.role
        dispatch(userActions.verifyOtp(data, history));
    }

    function resendOtp(e) {
        e.preventDefault();
        dispatch(userActions.resendOtp({
            phone: user?.phone,
            role: user?.role,
        }));
    }

    return (
        <>
            {/* <Col lg="5" md="7"> */}
            {/* <Card className="bg-white shadow border-0"> */}
            <div className="px-lg-5 px-md-4 py-lg-5">
                <div className="text-center  mb-4">
                    <img
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            objectFit: "contain",
                            marginBottom: 20
                        }}
                        alt="..."
                        src={require("./../../assets/img/brand/playstore-icon.png").default}
                    />
                    <h3 className="rz-heading rz-text-20 ">Verify OTP</h3>
                    <div style={{color:"#6E6B7B"}} className="Montserrat-400">
                        Enter the OTP sent to your mobile number
                    </div>
                    <div  className=" Montserrat-400 mt-3" style={{ color: "#6E6B7B",textAlign:"left" }}>OTP </div>
                    <Form role="form" onSubmit={handleSubmit(onOtpSubmit)}>
                        <FormGroup className="mb-0 mt-2">
                       
                            <input {...register('otp')} className={`form-control ${errors.otp ? 'is-invalid' : ''}`} placeholder='Enter 4 digit code here' />
                            <FormFeedback> {errors.otp?.message} </FormFeedback>
                        </FormGroup>
                       {state.type=='REGISTER' && <FormGroup>
                            <div className="mt-n2 pl-2 d-flex align-items-center">
                                <input checked={tmp} onChange={(event) => setTmp(event.target.checked)} type="checkbox" style={{ width: "18px",cursor:'pointer',accentColor:"#7367F0" }} /><p className="mb-0 ml-2">I agree to <Link  >privacy policy & terms</Link></p>
                            </div>
                        </FormGroup>}
                        <Row>
                            <Col>
                                <a href="#" color="link" onClick={resendOtp}>Resend Otp</a>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Button type="submit" className="my-4 w-100 rz-button-primary"  disabled={verifyingOtp || !tmp}>
                                {verifyingOtp ? 'Please wait...' : state.type=='REGISTER'?'Sign up':'Sign in'}
                            </Button>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div onClick={()=>history.goBack()} className="d-flex cursor-pointer justify-content-center align-items-center" style={{color:"#7367F0"}}>
                                <MdChevronLeft className="mr-1" style={{fontSize:"20px"}}/>
                                Go Back 
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {/* </Card> */}
            {/* </Col> */}
        </>
    );
};

export default Otp;
