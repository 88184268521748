import React, { useState } from 'react'
import { Card, CardBody, Button, CardSubtitle, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import OrderList from './OrderList';
import { moneyFormat } from 'utils';
import tickIcon from './../../../../assets/img/tick.svg';
import orderIcon from './../../../../assets/img/briefcase.png';
import balanceIcon from './../../../../assets/img/total_balance.png';
import deductionIcon from './../../../../assets/img/deduction.png';
import { useParams } from 'react-router-dom';
import { useSellertWiseWithdraw } from 'hooks/useFeeManagement ';
import { FaArrowLeft } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
const styles = {
    cardStyle: {
        display: 'flex',
        marginTop: "3%",
        justifyContent: "space-between"
    },
    textStyle: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#82868B',
    },
    numberStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '24px',
        color: '#7367F0',
    }
}
const spanStyle = {
    color: "#5E5873",
    fontFamily: 'Montserrat',
    fontWeight: 400,
}
const textStyle = {
    color: "#5E5873",
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 500,
}
export default function MyCommissionById({
    data,
    total,
    type,
    asset
}) {
    const headingstyle = {
        fontFamily: 'Montserrat',
        color: "#5E5873",
        fontSize: '24px',
        fontWeight: 500,
    }
    const assetIdStyle = {
        fontFamily: 'Montserrat',
        color: '#82868B',
        fontSize: "14px",
        fontWeight: "500"
    }
    const ColorCard = ({ color, title, subTitle, icon }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };
        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingTop: "40px",
                    paddingBottom: "40px"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{title}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                    <div style={{
                        marginLeft: 2
                    }}>
                        <img src={icon} />
                    </div>
                </CardBody>
            </Card>
        );
    };
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const user = JSON.parse(localStorage.getItem('user'));
    const { mutate: sellerWithdraw } = useSellertWiseWithdraw()
    const { property_id } = useParams();
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
    const onWithdraw = async () => {
        await sellerWithdraw({
            seller_id: user.id,
            property_id,
            amount: total.totalAmountSum,
            type: type == 1 ? 'Asset Seller' : 'Token Seller'
        },
            {
                onSuccess: (res) => {
                    setModal(true)
                }
            })
    }
    const history = useHistory()
    return (
        <>
            <div className="px-4">
                <div className="row mx-0">
                    <div className='col-6 pl-2 d-flex align-items-start'>
                        <div className='mr-2 mt-1'>
                            <FaArrowLeft className='cursor-pointer' onClick={history.goBack} style={{ fontSize: "20px", color: "#6E6B7B" }} />
                        </div>
                        <div>
                            <h3 className='mb-0' style={headingstyle}>{asset?.property_name}</h3>
                            <p className='mb-0' style={assetIdStyle}>Asset ID-: #{asset?.property_id}</p>
                        </div>
                    </div>
                    <div className='col-4'>
                    </div>
                    <div className='col-1 mr-1'>
                        <button
                            type="button"
                            onClick={toggle1}
                            className={`btn  rz-button-primary`}

                        >
                            Withdraw amount
                        </button>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className='col-10'>
                        <div className='row'>
                            <div className="col-4">
                                <ColorCard
                                    color="#7C278A"
                                    title={moneyFormat(total?.totalAmountSum || 0)}
                                    subTitle="Total Commission"
                                    icon={balanceIcon}
                                />
                            </div>
                            <div className="col-4">
                                <ColorCard
                                    color="#E9573E"
                                    title={(total?.totalOrders || 0)}
                                    subTitle="Total Orders"
                                    icon={orderIcon}
                                />
                            </div>
                            <div className="col-4">
                                <ColorCard
                                    color="#31A2C1"
                                    title={moneyFormat(total?.totalDeduction || 0)}
                                    subTitle="Taxes & Deduction"
                                    icon={deductionIcon}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-2'>
                    <OrderList data={data} />
                </div>
            </div>
            <Modal centered={true} isOpen={modal1} toggle={toggle1} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle1} close={closeBtn}>
                    <h1 className="mt-1" style={{ ...headingstyle, fontSize: "18px" }}>Confirm amount withdraw request </h1>
                </ModalHeader>
                <ModalBody className='py-0'>
                    <p style={textStyle}>
                        Please confirm the details and submit for request Amount
                    </p>
                    <div ><span style={spanStyle}>Amount :</span> {total?.totalAmountSum} </div>


                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={toggle1} className='rz-button-outline-primary' >
                        cancel
                    </Button>
                    <Button for='request' onClick={() => { onWithdraw(); toggle1() }} className='rz-button-primary'>
                        Confirm
                    </Button>

                </ModalFooter>
            </Modal>
            <Modal centered={true} isOpen={modal} toggle={toggle} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle} close={closeBtn}>
                    <h1 className="mt-1" style={{ ...headingstyle, fontSize: "18px" }}>Approve </h1>
                </ModalHeader>
                <ModalBody className='py-0'>

                    <div className='px-5'>
                        <div className='d-flex justify-content-center'>
                            <img src={tickIcon} />
                        </div>
                        <p className='text-center mt-2 mb-0' style={{ ...styles.numberStyle, color: "#4B4B4B", fontSize: "18px" }}>Request is submitted successfully</p>
                        <p className='text-center mt-1' style={textStyle}>
                            You shall get a confirmation mail once the request is
                            verified by the Ryzer super admin.
                        </p>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={toggle} className='rz-button-primary' >
                        OK
                    </Button>

                </ModalFooter>
            </Modal>
        </>
    )
}
