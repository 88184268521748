import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux'

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "./layouts/Admin.js";
import CompanyLayout from "./layouts/Company";
import BookingLayout from "./layouts/Booking";
import AuthLayout from "./layouts/Auth.js";
import { isLogin } from './utils';
import "assets/css/style.css";
import { QueryClient, QueryClientProvider } from 'react-query'
import { SocketEndPoint } from "./socket";

import {
    EthereumClient,
    modalConnectors,
    walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { arbitrum, mainnet, polygon, polygonMumbai } from "wagmi/chains";
import Booking from "views/booking/Booking";
import Unsubscribe from "views/booking/Unsubscribe";
import Unsubscribed from "views/booking/Unsubscribed";
import './assets/scss/main/style.scss';
import { userActions } from "redux/actions";
const authed = isLogin();
const queryClient = new QueryClient()


const App = ({ }) => {

    const chains = [arbitrum, mainnet, polygon, polygonMumbai];
    const dispatch = useDispatch();

    // Wagmi client
    const { provider } = configureChains(chains, [
        walletConnectProvider({ projectId: "3abac61830e4350995a73ae80bc36c82" }),
    ]);
    const wagmiClient = createClient({
        autoConnect: true,
        connectors: modalConnectors({ appName: "web3Modal", chains }),
        provider,
    });

    // Web3Modal Ethereum Client
    const ethereumClient = new EthereumClient(wagmiClient, chains);

    useEffect(() => {
        console.log(SocketEndPoint);
        SocketEndPoint.emit("clientconnection", "mehul")
        SocketEndPoint.on("shop", shop => {
            console.log("shop", shop)
        })
    }, [SocketEndPoint])

    useEffect(() => {
        dispatch(userActions.getMe());
    }, [authed])


    return (
        <>
            <WagmiConfig client={wagmiClient}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/booking" render={(props) => <Booking {...props} />} />
                            <Route path="/unsubscribe/:token" render={(props) => <Unsubscribe {...props} />} />
                            <Route path="/admin" render={(props) => authed ? <AdminLayout {...props} /> : <Redirect to="/auth/login" />} />
                            <Route path="/company" render={(props) => authed ? <CompanyLayout {...props} /> : <Redirect to="/auth/login" />} />
                            <Route path="/auth" render={(props) => !authed ? <AuthLayout {...props} /> : <Redirect to="/admin" />} />
                            <Redirect to='/auth' />
                        </Switch>
                        <ToastContainer toastClassName='h5' />
                    </BrowserRouter>
                </QueryClientProvider>,
            </WagmiConfig>
            <Web3Modal
                projectId="3abac61830e4350995a73ae80bc36c82"
                ethereumClient={ethereumClient}
            />
        </>

    );
};

export default App;

