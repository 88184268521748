import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
    Input,
} from "reactstrap";
import {
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import moment from "moment";
import { moneyFormat } from "utils";
import { useEffect, useState } from "react";
import Paginate from "components/Paginate";
import { CheckPermission, PERMISSIONS, RESOURCE, checkNotChannelPartner } from "../../../utils";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { toast } from "react-toastify";
import {
    useGetTokenSellerDetails,
    useAddTokenSellerDetails,
    useGetTokenSellerGetCount
} from "hooks/useTokenSellect";
import { AiOutlineEye } from "react-icons/ai";
import { useForm } from "react-hook-form";

const TokenPropertyList = () => {
    const dispatch = useDispatch();
    const {
        register,
        watch,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {

        },
    });
    const { search } = useLocation();
    const page = queryString.parse(search)?.page
    const { properties_token_seller, pager, gettingPropertyTokenSeller } = useSelector(
        (state) => state.getPropertiesTokenSellerReducer
    );
    const [isStep1ModalOpen, setIsStep1ModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const { mutate: addTokenSeller } = useAddTokenSellerDetails();
    const { data: getCount, refetch: refetchCount } = useGetTokenSellerGetCount();
    const { data: kycDetails, refetch } = useGetTokenSellerDetails();
   
    const [error, setError] = useState(true);
    // useEffect(() => {
    //     if (kycDetails) {
    //         reset({
    //             total_experience: kycDetails?.total_experience,
    //             city: kycDetails?.city,
    //             start_working_date: kycDetails?.start_working_date,
    //             current_state_employment: kycDetails?.current_state_employment,
    //             work_before: kycDetails?.work_before,
    //         });
            
    //     }
    // }, [kycDetails]);

    const onInit = () => {
        dispatch(propertyActions.getTokenSellerProperties({ page: page ? page : 1 }));
    }
    const values=watch()
    useEffect(()=>{
        if (values.total_experience && values.city && values.start_working_date && values.current_state_employment) {
            setError(false)
        } else {
            setError(true)
        }
    },[values])
    
    const onSubmit = async (values) => {
        const data = {
            ...values,
            properties: selectedIds
        }
        await addTokenSeller(data, {
            onSuccess: (res) => {
                setSelectedIds([])
                onInit();
                refetch();
                toast.success(res?.message)
                setIsStep1ModalOpen(false);
            },
            onError: (err) => {
                toast.error(err)
            },
            onFailure: (err) => {
                toast.error('Sorry! Something went wrong. Please try again')
            }
        })
    };

    useEffect(() => "do something when id changes", [page]);
    useEffect(() => {
        localStorage.removeItem("property_id")
        localStorage.removeItem("amenities")
        localStorage.removeItem("locationfeature")
        localStorage.removeItem("channel")
    });
    useEffect(() => {
        onInit()
    }, [page]);

    const setLocalStorage = async (item) => {
        localStorage.setItem("property_id", item.id)
    }

    const handleCheckboxChange = async (event, item) => {
        const itemId = item.id;
        const isChecked = event.target.checked;
        if (isChecked) {
            if ((selectedIds.length + (parseFloat(getCount || 0))) < 3) {
                setSelectedIds([...selectedIds, itemId]); // Add the ID to the array
            } else {
                event.target.checked = false; // Uncheck the checkbox
            }
        } else {
            setSelectedIds(selectedIds.filter(id => id !== itemId)); // Remove the ID from the array
        }
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button
                            onClick={() => setIsStep1ModalOpen(true)}
                            disabled={selectedIds?.length <= 0}
                            className="rz-button-primary" >
                            Request to Partner
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <div className="col">
                        <Card className="shadow">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Asset Name</th>
                                        <th scope="col">Asset ID</th>
                                        <th scope="col">Created Date</th>
                                        <th scope="col">commission %</th>
                                        <th scope="col">asset VALUE</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gettingPropertyTokenSeller && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {properties_token_seller && properties_token_seller.length > 0 ?
                                        properties_token_seller.map((item, i) => (
                                            <tr key={i}>
                                                <th>
                                                    <input
                                                        id={`tooltip-checkbox-${item.id}`}
                                                        type="checkbox"
                                                        style={{ width: '18px', cursor: 'pointer' }}
                                                        checked={selectedIds?.includes(item.id)}
                                                        onChange={event => handleCheckboxChange(event, item)}
                                                    />
                                                    {getCount == 3 && (
                                                        <UncontrolledTooltip target={`tooltip-checkbox-${item.id}`}>
                                                            Exceeded maximum number of allowed properties.
                                                        </UncontrolledTooltip>
                                                    )}
                                                    {(selectedIds.length >= 3
                                                        && !selectedIds?.includes(item.id)) && (
                                                            <UncontrolledTooltip target={`tooltip-checkbox-${item.id}`}>
                                                                Max 3 assets
                                                            </UncontrolledTooltip>
                                                        )}
                                                    {((parseFloat(getCount) > 0 && parseFloat(getCount) != 3) &&
                                                        selectedIds.length + parseFloat(getCount) >= 3 &&
                                                        !selectedIds?.includes(item.id))
                                                        && (
                                                            <UncontrolledTooltip target={`tooltip-checkbox-${item.id}`}>
                                                                {`Exceeded maximum number of allowed properties.You can request ${3 - parseInt(getCount)} asset.`}
                                                            </UncontrolledTooltip>
                                                        )}
                                                </th>
                                                <th
                                                    scope="row">
                                                    <Media
                                                        style={
                                                            !CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) ?
                                                                {
                                                                    "pointer-events": "none"
                                                                } : {}}
                                                        className="align-items-center">
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/view_asset_details/${item.id}`}
                                                            className="avatar rounded-circle mr-3"
                                                        >
                                                            <img alt="..." src={item?.featured_image} />
                                                        </Link>
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/view_asset_details/${item.id}`}>
                                                            <span className="mb-0 text-sm">
                                                                <div>{item.name}</div>
                                                                <small className="text-muted">
                                                                    {item?.location?.address}
                                                                </small>
                                                            </span>
                                                        </Link>
                                                    </Media>
                                                </th>
                                                <td>{(item.id)}</td>
                                                <td>{moment(item.created_at).format("D-MMM-Y")}</td>
                                                <td>{(item?.propertyPercentage?.filter((d) => d.name == "Brokerage Fee"))?.[0]?.value || 0}</td>
                                                <td>
                                                    <span id={`tooltip-${item.id}`}>
                                                        {moneyFormat(item.total_property_value)}
                                                    </span>
                                                    <UncontrolledTooltip target={`tooltip-${item.id}`}>
                                                        Current YEAR
                                                    </UncontrolledTooltip>
                                                </td>
                                                <td>
                                                    <Link
                                                        onClick={() => setLocalStorage(item)}
                                                        to={`/admin/view_asset_details/${item.id}`}
                                                    >
                                                        <AiOutlineEye style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {properties_token_seller?.length <= 0 &&
                                <NotFound
                                    message="Property Not Found."
                                />
                            }
                            {((pager && pager.pages.length > 0) && (properties_token_seller && properties_token_seller.length > 0)) && (
                                <Paginate path="/admin/seller" pager={pager} />
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
            <Modal isOpen={isStep1ModalOpen} backdrop={true} className="modal-md modal-fullscreen" style={{ "marginRight": 0, "marginTop": 0 }}>
                <Form onSubmit={handleSubmit(onSubmit)}  style={{ height: "100vh" }}>
                    <div className="px-4 pt-4 d-flex justify-content-between">
                        <div>
                            <h3 className="rz-heading rz-text-20">Request to Partner</h3>
                            <h3 className="Montserrat-400 rz-text-16 mt-3" style={{ color: "#4B4B4B" }} id="exampleModalLabel">
                                Give us a few details about your experience as a Channel Partner
                            </h3>
                        </div>
                        <div>
                            <Button
                                type="button"
                                className="btn-close btn-link bg-white"
                                onClick={() => setIsStep1ModalOpen(false)}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </div>
                    </div>
                    <ModalBody>
                        <FormGroup>
                            <Label htmlFor="total_experience">Total experience as a Real estate broker? *</Label>
                            <select
                                type="select"
                                id="total_experience"
                                className="form-control"
                                {...register('total_experience')}
                                required
                            >
                                <option value="">Select Experience</option>
                                <option value="1">1 year</option>
                                <option value="2">2 years</option>
                                <option value="3">3 years</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="city">Primary City & State of work *</Label>
                            <input
                                className="form-control"
                                type="text"
                                id="city"
                                placeholder="City"
                                {...register('city')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="start_working_date">When did u start working as a Real estate broker? *</Label>
                            <input
                                type="date"
                                className="form-control"
                                id="start_working_date"
                                name="start_working_date"
                                max={'9999-12-31'}
                                {...register('start_working_date')}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="current_state_employment">Current state of Employment? *</Label><br />
                            <select
                                type="select"
                                id="current_state_employment"
                                className="form-control"
                                {...register('current_state_employment')}
                            >
                                <option value="">Select Employment</option>
                                <option value="Self Employee">Self Employee</option>
                                <option value="Business">Business</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="work_before">Where have you worked before? (Optional)</Label>
                            <input
                                type="text"
                                id="work_before"
                                className="form-control"
                                {...register('work_before')}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="rz-button-outline-primary mr-3" onClick={() => setIsStep1ModalOpen(!isStep1ModalOpen)}>
                            Cancel
                        </Button>

                        <Button
                            type="submit"
                            disabled={error}
                            className="rz-button-primary"
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default TokenPropertyList;
