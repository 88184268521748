import { useMutation, useQuery } from "react-query";
import { api } from "../helpers";
const useAddTokenSellerDetails = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/token-seller`, data);
        }
    );
};
const useGetTokenSellerGetCount = (options) => {
    return useQuery([`useGetTokenSellerGetCount`, options],
        async () => {
            const { data } = await api.get(`/token-seller/getCount`);
            return data;
        }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    });
};
const useGetTokenSellerMyCommission = (options) => {
    return useQuery([`useGetTokenSellerMyCommission`, options],
        async () => {
            const data = await api.get(`/token-seller/admin/my-commission`);
            return data;
        }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    });
};
const useGetTokenSellerMyCommissionById = (options = {}) => {
    return useQuery([`useGetTokenSellerMyCommissionById${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const data = await api.get(`/token-seller/admin/my-commission/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
const useUpdateTokenSellerDetails = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/token-seller/superadmin/update`, data);
        }
    );
};
const useGetTokenSellerDetails = (options = {}) => {
    return useQuery([`useGetTokenSellerDetails`, options],
        async ({ queryKey }) => {
            const { data } = await api.get(`/token-seller/details`);
            return data;
        }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    });
}
const useGetCode = (options = {}) => {
    return useQuery([`useGetCode`, options],
        async ({ queryKey }) => {

            const { data } = await api.get(`/token-seller/getCodeforAsset/${options.id}`);
            return data;
        }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    });
}
const useGetSpeceficData = (options = {}, enable) => {
    return useQuery([`getSpeceficData`, options],
        async ({ queryKey }) => {

            const { data } = await api.get(`/property/getSpeceficData/${options.id}?select=id,property_type_id,pitch_deck`);
            return data;
        }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enable: enable
    });
}

export {
    useGetCode,
    useGetSpeceficData,
    useAddTokenSellerDetails,
    useGetTokenSellerDetails,
    useUpdateTokenSellerDetails,
    useGetTokenSellerGetCount,
    useGetTokenSellerMyCommission,
    useGetTokenSellerMyCommissionById
};
