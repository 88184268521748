import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "redux/actions";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import { HiOutlinePlusCircle } from "react-icons/hi";
// yup schema
const documentSchema = yup.object().shape({
    document: yup.string().required(),
    state: yup.string().required(),
});

const AddEditDocumentChecklist = () => {
    const history = useHistory();
    const { documetnChecklist, loading } = useSelector(
        (state) => state.addEditDocumentChecklistReducer
    );

    const { countryStates: country } = useSelector(
        (state) => state.getCountryReducer
    );
    const dispatch = useDispatch();
    const [documentList, setDocumentList] = useState([]);
    const [document, setDocument] = useState("");
    const [format, setForamt] = useState("");
    const [description, setDescription] = useState("");
    const [stateName, setStateName] = useState("");
    const [stateId, setStateId] = useState(1);
    const [states, setStates] = useState([]);

    const { id } = useParams();

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ resolver: yupResolver(documentSchema) });

    // name of state from dropdown change function
    const handleStateChange = (e) => {
        setStateName(e.target.value);
        const stateInfo = country?.states.find((state) => {
            return state.name == e.target.value;
        });

        const temp = [];
        stateInfo?.documentList.map((doc) => {
            temp.push({
                name: doc.name,
                type: doc.type,
                doc_id: doc.id,
            });
        });

        setDocumentList(temp);
        setStateId(stateInfo?.id);
    };

    const handleDocumentChange = (e) => {
        setDocument(e.target.value);
    };

    // functior for add a new document
    const appendNewDocument = () => {
        const previousDocList = [...documentList];
        const newDoc = {
            name: document.trim(),
            type: format,
            description: description
        };
        previousDocList.push(newDoc);

        setDocumentList(previousDocList);
        setDocument("");
        setDescription("");
        setForamt("")
    };

    // document remove function
    const documentRemove = (index) => {
        const filteredDoc = documentList.filter((doc, i) => {
            return i != index;
        });
        setDocumentList(filteredDoc);
    };

    // function fired on cancel button
    const handleCancel = () => {
        const state = country.states.find((state) => {
            return state.id == stateId;
        });
        if (state) {
            setDocumentList([...state.documentList]);
        } else {
            setDocumentList([]);
        }
        history.push("/admin/configuration");
    };

    // form on submit function
    const handleSubmitFunc = () => {
        console.log({
            state_id: stateId,
            documents: [...documentList],
        });

        dispatch(
            configActions.addEditDocumentChecklist(
                {
                    state_id: stateId,
                    documents: [...documentList],
                },
                stateId
            )
        );
    };

    useEffect(() => {
        dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
    }, []);

    useEffect(() => {
        if (country?.states) {
            const options = [];
            country?.states.map((item) => {
                options.push({ value: item.id, label: item.name });
            });
            setStates(options);
            setStateId(id);
        }

        setDocumentList(country?.states[id - 1]?.documentList || []);
    }, [country]);

    return (
        <Container fluid className="mt-2">
            <Card className="card bg-white p-4 mt-5">
                <Row>
                    <Col>
                        <Form className="" onSubmit={(e) => e.preventDefault()}>
                            <h5>State*</h5>
                            <Row>
                                <Col xs="12" md="6">
                                    <select
                                        onChange={(e) => handleStateChange(e)}
                                        className="form-control mb-2 "
                                    >
                                        {states.map((state) => {
                                            return (
                                                <option
                                                    selected={state.value == id}
                                                    key={state.value}
                                                    value={state.name}
                                                >
                                                    {state.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <hr />
                            <div className="mb-2 ">
                                {documentList?.length > 0 && <h3>Document List</h3>}
                                {documentList?.map((document, index) => {
                                    return (
                                        <Row key={index}>
                                            <Col xs="12" md="6">
                                                <div className=" mb-2 bg-secondary px-3 py-1 rounded d-flex align-items-center justify-content-between  mb-3">
                                                    <span> {document.name} </span>
                                                    <span> {document.type} </span>
                                                    <Button outline onClick={() => documentRemove(index)}>
                                                        <i className="fas fa-times    "></i>
                                                    </Button>

                                                </div>
                                            </Col>
                                            <Col xs="12" md="12">
                                                <div className=" mb-2 px-3 py-1 rounded 
                                                d-flex flex-direction-col align-items-center justify-content-left  mb-3">
                                                    <br></br>
                                                    <p>{document?.description}</p>
                                                </div>

                                            </Col>
                                            <br></br>
                                        </Row>
                                    );
                                })}
                            </div>

                            <Row className="">
                                <Col xs="12" md="6">
                                    <Label> Name of Document* </Label>
                                    <input
                                        value={document}
                                        onChange={handleDocumentChange}
                                        className="form-control mb-2 "
                                        type="text"
                                        placeholder="Name of the Document"
                                    />
                                </Col>

                                <Col xs="12" md="5">
                                    <Label> Select File Type* </Label>
                                    <select onChange={(e) => setForamt(e.target.value)} className="form-control mb-2 ms-1">
                                        <option value=''>Select File Type</option>
                                        <option value='image/png'>.png Image Format</option>
                                        <option value='image/jpeg'>.jpeg Image Format</option>
                                        <option value='image/svg+xml'>.svg Image Format</option>
                                        <option value='application/pdf'>.pdf Format</option>
                                        <option value='application/msword'>.doc Microsoft Word Format</option>
                                        <option value='application/vnd.openxmlformats-officedocument.wordprocessingml.document'>.docx Microsoft Word Format</option>
                                    </select>
                                </Col>
                                <Col xs="12" md="12">
                                    <Label> Description </Label>
                                    <textarea
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                        className="form-control"
                                        rows={5} cols={10}
                                        placeholder="Enter Address">
                                    </textarea>
                                </Col>
                                <Col className="mt-3" xs="12" md="5" >
                                    <Button
                                        color='primary'
                                        disabled={document == '' || format == '' || description == ''}
                                        onClick={appendNewDocument}
                                        className='rz-button-primary mt-2'>
                                        <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} />  Add Document Type
                                    </Button>
                                </Col>
                            </Row>

                            <Card className="mt-5 bg-white border-0">
                                <Row>
                                    <Col className="text-right ">
                                        <Button onClick={handleCancel} className="rz-button-outline-primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={documentList?.length == 0}
                                            className="rz-button-primary ml-3"
                                            onClick={handleSubmitFunc}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
};

export default AddEditDocumentChecklist;
