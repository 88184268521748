import React, { Fragment } from 'react'
import { Badge, Button, Col, Row } from 'reactstrap';
import {
    usePropertyActivityQuery,
    useUpdatePropertyActivityClassMutation,
    useCreatePropertyActivityClassMutation
} from 'hooks/usePropertyActivity';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

const PropertyUpdates = ({ stepper, type, parentStepper }) => {
    const { data: propertyActivity } = usePropertyActivityQuery(
        localStorage.getItem("property_id") ? localStorage.getItem("property_id") : 0
    );
    const { mutate: updateExpense, isLoading: updating } =
        useUpdatePropertyActivityClassMutation();
    const { mutate: addPropertyUpdate, } =
        useCreatePropertyActivityClassMutation();

    const onPropertyStatusChange = (id, e) => {
        e?.preventDefault()
        updateExpense({
            variables: {
                id: id,
                input: {
                    status: e.target.checked ? true : false,
                },
            },
        });
    };
    const onAddPropertyActivityAdd = () => {
        addPropertyUpdate({
            variables: {
                input: {
                    property_id: localStorage.getItem("property_id")
                },
            },
        });
    };
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Asset Updates</h3>
            </div>
            <Row>
                <Col>
                    {propertyActivity && (
                        <div className="accordion">
                            {
                                propertyActivity?.map((item, i) => {
                                    return (
                                        <div className="card" key={i}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between mt-4">
                                                    <div>
                                                        <h3>{item?.type}</h3>
                                                        <p>{item?.message}</p>
                                                    </div>
                                                    <div>
                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={item.status == true ? true : false}
                                                                name={`property_activity-status`}
                                                                onChange={(e) =>
                                                                    onPropertyStatusChange(item.id, e)
                                                                }
                                                                className="custom-control-input"
                                                                id={`property-activity-status-${item.id}`}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`property-activity-status-${item.id}`}
                                                            >
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-${item.status == true
                                                                        ? "success"
                                                                        : "primary"
                                                                        } mr-4`}
                                                                >
                                                                </Badge>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                </Col>
            </Row>
            <div className='d-flex align-items-center justify-content-between mt-4'>
                <div>
                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next mr-3' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                    {
                        (propertyActivity?.length <= 0) ?
                            <Button type='submit' className='rz-button-primary' onClick={() => onAddPropertyActivityAdd()}>
                                <span className='align-middle d-sm-inline-block d-none'>Sync Property Activity</span>
                            </Button>
                            : <></>
                    }
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                    <Link to='/admin/property'>
                        <Button type='button' className='rz-button-outline-primary'>
                            Cancel
                        </Button>
                    </Link>

                    <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>

                    <Button type='submit' className='rz-button-primary' onClick={() => stepper.next()}>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default PropertyUpdates;