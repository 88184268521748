import {
    usePropertyExpenseQuery,
    useCreatePropertyExpenseClassMutation,
    useUpdatePropertyExpenseClassMutation,
    useDeletePropertyExpenseMutation
} from 'hooks/usePropertyExpense';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import moment from 'moment'
import NotFound from 'components/NotFound/NotFound';
import { toast } from 'react-toastify';
import { useMutatePropertyupdateEscalation } from 'hooks/useProperty';
import { useDispatch, useSelector } from 'react-redux';
import { propertyActions } from 'redux/actions';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { HiOutlineDocumentText } from 'react-icons/hi';

const IncomeExpense = ({ parentStepper, stepper, type }) => {

    var months = moment.monthsShort();
    const property = useSelector(state => state.propertyReducer.property);
    const currentYear = new Date().getFullYear()
    const { data } = usePropertyExpenseQuery(
        localStorage.getItem("property_id") ?
            localStorage.getItem("property_id") : 0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [incomeExpese, setincomeExpese] = useState([]);
    const FaqSchema = yup.object().shape({
        type: yup.string().required(),
        amount: yup.string().required(),
        note: yup.string().required(),
    })

    const { mutate: addExpense, isLoading: creating } =
        useCreatePropertyExpenseClassMutation();
    const { mutate: updateExpense, isLoading: updating } =
        useUpdatePropertyExpenseClassMutation();
    const { mutate: updateEscalation, isLoading: updatingEs } =
        useMutatePropertyupdateEscalation();

    const { mutate: deleteExpense } = useDeletePropertyExpenseMutation();
    const [filtterData, setFilterData] = useState({
        year: "",
        month: "",
        type: ""
    })
    useEffect(() => {
        setincomeExpese(data)
    }, [data]);

    useEffect(() => {
        const fData = data?.filter((d) =>
        ((filtterData.year != "" ? d.year == filtterData.year : true) &&
            (filtterData.month != "" ? d.month == filtterData.month : true) &&
            (filtterData.type != "" ? d.type == filtterData.type : true)))
        setincomeExpese(fData)
    }, [filtterData]);

    const onSubmit = (data) => {
        setIsModalOpen(!isModalOpen)
        data.property_id = localStorage.getItem("property_id");
        if (data?.id) {
            updateExpense({
                variables: {
                    id: data?.id,
                    input: {
                        ...data,
                    },
                },
            });
        } else {
            addExpense({
                variables: {
                    input: {
                        ...data,
                    },
                },
            });
        }
    }
    const addEditModal = (faqId) => {
        reset()
        if (faqId != null)
            var faq = data.find(({ id }) => id == faqId);
        setValue('note', faq?.note)
        setValue('type', faq?.type)
        setValue('id', faq?.id)
        setValue('amount', faq?.amount)
        setValue('month', faq?.month)
        setValue('year', faq?.year)
        setIsModalOpen(!isModalOpen)
    }

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(FaqSchema)
    })
    const { register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        reset: reset2,
        watch: watch2,
        formState:
        { errors: errors2 } } = useForm({
        })
    const removePropertyFaq = (faqId) => {
        if (window.confirm('Are you sure?'))
            deleteExpense(faqId)
    }
    const year = watch2('year');
    const dispatch = useDispatch()

    useEffect(() => {
        if (property) {
            var pp = property?.propertyPerformance?.find(o => o.year == year);
            setValue2('income_escalation', pp?.income_escalation || 0)
            setValue2('expense_escalation', pp?.expense_escalation || 0)
            setValue2('target_irr', pp?.target_irr || 0)
            setValue2('avg_rental_yeild', pp?.avg_rental_yeild || 0)
        }
    }, [year]);

    const onSubmitEscalation = async (data) => {
        if (data?.year == '' || data?.year == 'Select Year') {
            toast.error("Please Select Year");
            return
        }
        if (!data?.target_irr) {
            toast.error("Target IRR is required.");
            return
        }
        await updateEscalation(
            {
                ...data,
                property_id: localStorage.getItem("property_id")
            },
            {
                onSuccess: async (res) => {
                    toast.success("Property Updated Successfully.")
                    dispatch(propertyActions.getProperty(localStorage.getItem("property_id")))
                },
                onError: (error) => {
                    toast.error("Something Went Wrong.");
                }
            }
        );
    }

    return (
        <>
            {property?.property_type_id != 1 ?
                <Fragment>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3 className='mb-0 rz-text-primary Montserrat-500'>Update Expense and Income Escalation </h3>
                    </div>
                    <hr></hr>
                    <Form onSubmit={handleSubmit2(onSubmitEscalation)}>
                        <Row className='mt-3'>
                            <FormGroup tag={Col} md='3'>
                                <Label
                                    style={{ fontSize: 14 }}
                                >
                                    Select Year *</Label>
                                <select
                                    className={classnames({ 'is-invalid': errors2?.year, 'form-control': true })}
                                    {...register2(`year`)} id="year">
                                    <option >Select Year</option>
                                    <option selected={currentYear == 2027 ? true : false} >2027</option>
                                    <option selected={currentYear == 2026 ? true : false} >2026</option>
                                    <option selected={currentYear == 2025 ? true : false} >2025</option>
                                    <option selected={currentYear == 2024 ? true : false}>2024</option>
                                    <option selected={currentYear == 2023 ? true : false}>2023</option>
                                </select>
                            </FormGroup>
                            {
                                (property?.stage == 'Pre-leased') && (
                                    <>
                                        <FormGroup tag={Col} md='4'>
                                            <Label className='form-label'
                                                style={{ fontSize: 14 }}
                                                htmlFor={`income_escalation`}>
                                                Income escalation (%)
                                            </Label>
                                            <input
                                                id={`income_escalation`}
                                                {...register2(`income_escalation`)}
                                                className={classnames({ 'is-invalid': errors2.income_escalation, 'form-control': true })}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='4'>
                                            <Label className='form-label'
                                                style={{ fontSize: 14 }}
                                                htmlFor={`expense_escalation`}>
                                                Expense escalation (%)
                                            </Label>
                                            <input
                                                id={`expense_escalation`}
                                                {...register2(`expense_escalation`)}
                                                className={classnames({ 'is-invalid': errors2.expense_escalation, 'form-control': true })}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='4'>
                                            <Label className='form-label'
                                                style={{ fontSize: 14 }}
                                                htmlFor={`avg_rental_yeild`}>
                                                Avr Rental Yield (%)
                                            </Label>
                                            <input
                                                id={`avg_rental_yeild`}
                                                {...register2(`avg_rental_yeild`)}
                                                className={classnames({ 'is-invalid': errors2.avg_rental_yeild, 'form-control': true })}
                                            />
                                        </FormGroup>
                                    </>
                                )
                            }
                            <FormGroup tag={Col} md='6'>
                                <Label className='form-label'
                                    style={{ fontSize: 14 }}
                                    htmlFor={`target_irr`}>
                                    Target IRR (%)
                                </Label>
                                <input
                                    id={`target_irr`}
                                    {...register2(`target_irr`)}
                                    className={classnames({ 'is-invalid': errors2.target_irr, 'form-control': true })}
                                />
                            </FormGroup>
                            <Col md="6" className='mt-4'>
                                <Button className='rz-button-primary ml-3' type='submit'>
                                    <HiOutlineDocumentText style={{ fontSize: "18px" }} />
                                    <span className='align-middle d-sm-inline-block d-none'>Submit</span>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <hr></hr>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3 className='mb-0 rz-text-primary Montserrat-500'>Expense and Income</h3>
                    </div>
                    <hr></hr>
                    {
                        (property?.stage == 'Pre-leased') && (
                            <>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex flex-row pr-2 w-100 justify-content-between'>
                                        <div className='col-4'>
                                            <select
                                                onChange={(e) => setFilterData({
                                                    ...filtterData,
                                                    year: e.target.value
                                                })}
                                                className='form-control'>
                                                <option selected value=''>Select Year</option>
                                                {[2023, 2024, 2025, 2026, 2027]?.map((month) => (
                                                    <option value={month}>{month}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-4'>
                                            <select
                                                onChange={(e) => setFilterData({
                                                    ...filtterData,
                                                    type: e.target.value
                                                })}
                                                className='form-control'>
                                                <option value=''>Select Type</option>
                                                <option value='income'>Income</option>
                                                <option value='expense'>Expense</option>
                                            </select>
                                        </div>
                                        <div className='col-4'>
                                            <select
                                                onChange={(e) => setFilterData({
                                                    ...filtterData,
                                                    month: e.target.value
                                                })}
                                                className='form-control'
                                                id="month">
                                                <option selected value=''>Select Month</option>
                                                {months?.map((month) => (
                                                    <option value={month}>{month}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <button onClick={() => addEditModal()} className="btn btn-primary" type="button">
                                        <i className='fa fa-plus'></i> Add Income/Expense
                                    </button>
                                </div>
                                <Row className="mt-2">
                                    <Col>
                                        <Table responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col"> TYPE </th>
                                                    <th scope="col"> Amount</th>
                                                    <th scope="col"> Month</th>
                                                    <th scope="col"> Year</th>
                                                    <th scope="col"> Actions</th>
                                                    <th scope="col">
                                                        Note
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {incomeExpese?.map((item) => {
                                                    return (
                                                        <tr className="bg-white" key={item.id}>
                                                            <td>
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-${item.type == "income"
                                                                        ? "success"
                                                                        : "danger"
                                                                        } mr-4`}
                                                                >
                                                                    {item.type}
                                                                </Badge> </td>
                                                            <td> {item.amount} </td>
                                                            <td> {item?.month} </td>
                                                            <td> {item?.year} </td>
                                                            <td >
                                                                <button onClick={() => removePropertyFaq(item.id)} className="text-muted btn-sm btn btn-link" type="button">
                                                                    <i className='fa fa-trash'></i>
                                                                </button>
                                                                <button onClick={() => addEditModal(item.id)} className="text-muted btn-sm btn btn-link" type="button">
                                                                    <i className='fa fa-edit'></i>
                                                                </button>
                                                            </td>
                                                            <td> {item.note} </td>

                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                        {incomeExpese?.length <= 0 &&
                                            <NotFound
                                                message="Please refine your filter."
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <ModalHeader>
                                            Add Expense or Income
                                        </ModalHeader>
                                        <ModalBody>
                                            <FormGroup>
                                                <Label className='form-label' htmlFor={`amount`}>
                                                    Amount
                                                </Label>
                                                <input
                                                    {...register(`amount`)}
                                                    className={classnames({ 'is-invalid': errors.amount, 'form-control': true })}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className='form-label' htmlFor={`type`}>
                                                    Type *
                                                </Label>
                                                <select className={classnames({ 'is-invalid': errors.type, 'form-control': true })} {...register(`type`)}
                                                    id="type">
                                                    <option value=''>Select Type</option>
                                                    <option value='income'>Income</option>
                                                    <option value='expense'>Expense</option>
                                                </select>
                                            </FormGroup>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label className='form-label' htmlFor={`month`}>
                                                            Select Month (All Mense for All Month)*
                                                        </Label>
                                                        <select
                                                            className={classnames({ 'is-invalid': errors.month, 'form-control': true })}
                                                            {...register(`month`)}
                                                            id="month">
                                                            <option selected value='all'>All</option>
                                                            {months?.map((month) => (
                                                                <option value={month}>{month}</option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label className='form-label' htmlFor={`year`}>
                                                            Select Year*
                                                        </Label>
                                                        <select
                                                            className={classnames({ 'is-invalid': errors.year, 'form-control': true })}
                                                            {...register(`year`)}
                                                            id="year">
                                                            {[2023, 2024, 2025, 2026, 2027]?.map((month) => (
                                                                <option value={month}>{month}</option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup>
                                                <Label className='form-label' htmlFor={`note`}>
                                                    Notes
                                                </Label>
                                                <textarea
                                                    rows='4'
                                                    {...register(`note`)}
                                                    className={classnames({ 'is-invalid': errors.note, 'form-control': true })}
                                                />
                                            </FormGroup>


                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={() => setIsModalOpen(!isModalOpen)}>
                                                Cancel
                                            </Button>
                                            {' '}
                                            <Button
                                                color="primary"
                                            >
                                                {'Save'}
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                            </>
                        )
                    }
                    <div className='mt-5 align-item-center d-flex justify-content-between'>
                        <div>
                            <Button type='button' color='success' onClick={() => { property?.share_type == 'NCD' ? stepper.next() : parentStepper.next() }} className='btn-next' >
                                <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                                <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                            </Button>
                        </div>
                        <div className='d-flex align-item-center justify-content-end'>
                            <Link to='/admin/property'>
                                <Button type='button' className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                            </Link>
                            <Button type='button' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => stepper.previous()}>
                                <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Back</span>
                            </Button>
                            <Button type='button' onClick={() => { property?.share_type == 'NCD' ? stepper.next() : parentStepper.next() }} className='btn-next rz-button-primary'>
                                <BsArrowRightShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Next</span>

                            </Button>
                        </div>
                    </div>

                </Fragment > :
                <>
                    <div className='empty_data'>
                        <h2>There is no data available for this type property</h2>
                    </div>

                    <div className='mt-5 align-item-center d-flex justify-content-between'>
                        <div>
                            <Button type='button' color='success' onClick={() => { property?.share_type == 'NCD' ? stepper.next() : parentStepper.next() }} className='btn-next' >
                                <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                                <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                            </Button>
                        </div>
                        <div className='d-flex align-item-center justify-content-end'>
                            <Link to='/admin/property'>
                                <Button type='button' className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                            </Link>
                            <Button type='button' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => stepper.previous()}>
                                <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Back</span>
                            </Button>
                            <Button type='button' onClick={() => { property?.share_type == 'NCD' ? stepper.next() : parentStepper.next() }} className='btn-next rz-button-primary'>
                                <BsArrowRightShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Next</span>

                            </Button>
                        </div>
                    </div>
                </>
            }
        </>


    )
}

export default IncomeExpense
