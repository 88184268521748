import React from 'react'
import { propertyActions } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import ABI from './../../../contract/abi/INDR.json';
import bytecode from './../../../contract/bytecode/INDR.json';
import { ethers } from 'ethers';
import { getExplorer } from '../../../helpers/networks';
import { useUpdatePropertyINDR } from '../../../hooks/useProperty';
import { Button, Card, Col, Label, Row } from "reactstrap";
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { addTokenToMetamask } from 'utils';
import { useWeb3ModalNetwork } from '@web3modal/react';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

export default function CreateINDR({ stepper }) {
  const dispatch = useDispatch();
  const property = useSelector(state => state.propertyReducer.property);
  const { selectedChain } = useWeb3ModalNetwork()

  const { mutate } = useUpdatePropertyINDR()
  const { isConnected, address } = useAccount()
  const handleSTO = async () => {
    if (!isConnected) {
      toast.error("Please connet your wallet first.")
      return;
    }
    if (selectedChain?.id != property?.blockchain_metadata?.id) {
      toast.error(`Please switch network to ${property?.blockchain_metadata?.name}`)
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const factory = new ethers.ContractFactory(ABI, bytecode, signer)
    var contract;
    try {
      contract = await factory.deploy(property?.name, "INDR");
    }
    catch (err) {
      console.log(err);
      return;
    }
    var data = {}
    data.property_id = property?.id
    data.indr_token_address = contract?.address;
    data.indr_token_deploy = true;
    await mutate(data, {
      onSuccess: (res) => {
        console.log(res);
        stepper.next();
      }
    })
    dispatch(propertyActions.getProperty(property?.id))
  }
  return (
    <>
      <div className='content-header'>
        <h3 className='mb-0 rz-heading rz-text-18'>Create IDRR Wrapper Token</h3>
      </div>
      {!property?.indr_token_deploy ? (
        <Card className="p-4">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h3 className='mb-0 rz-heading rz-text-16'>IDRR Wrapper Token</h3>
            </Col>
            <Col className='d-flex align-items-center justify-content-end'>
              <Button type='button'
                className='rz-button-primary'
                onClick={() => handleSTO()}  >
                Deploy INDR Wrapper Token
              </Button>
            </Col>
          </Row>
        </Card>
      ) : (
        <>
          <a
            className="btn btn-primary mt-1"
            href={`${property?.blockchain_metadata?.blockExplorers?.default?.url}/token/${property?.indr_token_address}`}
            target="_blank"
            rel="noreferrer"
          >
            View INDR Wrapper on Explorer
          </a>
          <Button
            color="primary"
            className='mt-1'
            onClick={() => addTokenToMetamask(
              property?.indr_token_address,
              "INDR",
              18,
            )}
          >
            Add Token Into Metamask
          </Button>
          <Col className="my-4" md="12" xs="12">
            <Label> Your INDR Wrapper Symbol</Label>
            <h1>INDR</h1>
          </Col>
          <Col className="my-4" md="12" xs="12">
            <Label> Your Security INDR Wrapper token Name</Label>
            <h1>{property?.name}</h1>
          </Col>
          <Col className="my-4" md="12" xs="12">
            <Label> Your Security token Address</Label>
            <h1>{property?.indr_token_address}</h1>
          </Col>
        </>

      )}
      <div className='mt-5 d-flex align-items-center justify-content-between'>
        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
          <span className='align-middle d-sm-inline-block d-none'>Skip</span>
          <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
        </Button>
        <div className='d-flex align-items-center justify-content-end'>
          <Link to='/admin/property'>
            <Button type='button' className='rz-button-outline-primary'>
              Cancel
            </Button>
          </Link>
          <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
            <BsArrowLeftShort style={{ fontSize: "22px" }} />
            <span className='align-middle d-sm-inline-block d-none'>Back</span>
          </Button>
          <Button type='button'
            className='rz-button-primary'
            onClick={() => stepper.next()}  >
            <BsArrowRightShort style={{ fontSize: "22px" }} />
            Next
          </Button>
        </div>
      </div>
    </>
  )
}

