import { useWeb3Modal } from "@web3modal/react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import './../../assets/scss/style.scss';
import {
    Nav,
    Button,
} from "reactstrap";
import clipboardCopy from 'clipboard-copy';
import { useAccount, useDisconnect } from "wagmi";
import { userService } from "../../redux/services";
import { checkNotChannelPartner } from "utils";
import { useHistory } from "react-router-dom";
import avtar from './../../assets/img/Active.png';
import { MdNotificationsNone } from "react-icons/md";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
const AdminNavbar = ({
    brandText,
    selected,
    setSelected,
}) => {

    const user = JSON.parse(localStorage.getItem("user") || {})
    const { isConnected, address } = useAccount()
    const { me } = useSelector((state) => state.meReducer);
    const handleCopy = (text) => {
        clipboardCopy(text)
            .then(() => {
                toast.success('Text copied to clipboard!');
            })
            .catch((error) => {
                toast.error('Failed to copy text:' + error?.toString());
            });
    };
    const history = useHistory();
    const [seller, setSeller] = useState(selected);
    const { open } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const loginIntoWeb3 = async () => {
        if (!isConnected)
            await open()
        else
            await disconnect();
    }
    const onPropertyBookingChange = (e) => {
        localStorage.setItem('selected', e.target.checked);
        setSeller(e.target.checked);
        setSelected(e.target.checked);
        if (e.target.checked) {
            if (me?.kyc?.status == 'auto_approved') {
                history.push('/admin/property');
            } else {
                history.push('/admin/kyc');
            }
        }
        else {
            if (me?.kyc?.status == 'auto_approved') {
                history.push('/admin/seller');
            } else {
                history.push('/admin/kyc');
            }

        }
    };
    return (
        <div className="navbar-wrapper">
            <nav className="nav-top navbar py-2 navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>
                <div className="d-flex align-items-center">
                    <div>
                        <Link
                            className="h4 mb-0 text-uppercase  d-inline-block"
                            to="/"
                        >
                            {brandText}
                        </Link>
                    </div>
                </div>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item mr-1 d-flex align-items-center dropdown no-arrow mx-2">
                        <MdNotificationsNone style={{ fontSize: "25px" }} className="cursor-pointer" />
                    </li>
                    <li className="nav-item d-flex align-items-center  dropdown no-arrow">
                        {
                            (!checkNotChannelPartner()
                                && me?.kyc?.status == 'auto_approved'
                                && window.location.pathname != '/admin/kyc'
                            ) && (
                                <Nav className="align-items-center d-none d-md-flex ml-3" navbar>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div >
                                            {selected ? "Asset Seller" : "Token Seller"}
                                        </div>
                                        <div className="custom-control custom-switch ml-2 ">
                                            <input
                                                type="checkbox"
                                                name={`property_booking`}
                                                defaultChecked={selected}
                                                onChange={(e) => onPropertyBookingChange(e)}
                                                className="custom-control-input"
                                                id={`token-seller`}
                                            />
                                            <label className={`custom-control-label seller-switch-label ${seller ? 'asset' : 'token'}-switch-label`}
                                                htmlFor={`token-seller`}
                                            >
                                            </label>
                                        </div>
                                    </div>
                                </Nav>
                            )
                        }
                    </li>
                    <li>
                        {checkNotChannelPartner() && (
                            <Nav className="align-items-center d-none d-md-flex" navbar>
                                <Button
                                    className="wallet-button"
                                    onClick={() => loginIntoWeb3()}>
                                    {isConnected ?
                                        `Disconnect (${address})` :
                                        "Connect Wallet"}
                                </Button>
                            </Nav>
                        )}
                    </li>
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link d-flex align-items-center dropdown-toggle img-profile" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div><p className="mr-2 d-none user-name d-lg-inline">
                                {user ? user?.firstname || "" + " " + user?.lastname || "" : "UnNamed"}
                            </p>
                                {!checkNotChannelPartner() && <p className="user-role">
                                    Channel Partner
                                </p>}
                            </div>
                            <img style={{
                                width: "38px",
                                borderRadius: "50%",
                                height: "38px"
                            }}
                                src={user?.avatar || avtar} />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/auth/login" onClick={() => userService.logout()}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>

                </ul>
            </nav>
        </div>
    );
};

export default AdminNavbar;

{/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */ }
{/* <li className="nav-item dropdown no-arrow d-sm-none">
                        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-search fa-fw"></i>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                            aria-labelledby="searchDropdown">
                            <form className="form-inline mr-auto w-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small"
                                placeholder="Search for..." aria-label="Search"
                                aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                        </div>
                    </li> */}
{/* <li className="nav-item mr-3 d-flex align-items-center dropdown no-arrow mx-1">
                        <div className="d-flex align-items-center">
                            <img src={country}/>
                            <div className="ml-2 country-name">English</div>
                        </div>
                    </li> */}
{/* <!-- Nav Item - Alerts --> */ }
{/* <li className="nav-item mr-3 d-flex align-items-center dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={notify} />
                            <span className="badge badge-danger badge-counter">3</span>
                        </a>
                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="alertsDropdown">
                            <h6 className="dropdown-header">
                                Alerts Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-primary">
                                        <i className="fas fa-file-alt text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 12, 2019</div>
                                    <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-success">
                                        <i className="fas fa-donate text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 7, 2019</div>
                                    $290.29 has been deposited into your account!
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="mr-3">
                                    <div className="icon-circle bg-warning">
                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                    </div>
                                </div>
                                <div>
                                    <div className="small text-gray-500">December 2, 2019</div>
                                    Spending Alert: We've noticed unusually high spending for your account.
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                        </div>
                    </li> */}

{/* <!-- Nav Item - Messages --> */ }
{/* <li className="nav-item dropdown no-arrow mx-1">
                        <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-envelope fa-fw"></i>

                            <span className="badge badge-danger badge-counter">7</span>
                        </a>

                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="messagesDropdown">
                            <h6 className="dropdown-header">
                                Message Center
                            </h6>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div className="font-weight-bold">
                                    <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                        problem I've been having.</div>
                                    <div className="small text-gray-500">Emily Fowler · 58m</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                        alt="..." />
                                    <div className="status-indicator"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">I have the photos that you ordered last month, how
                                        would you like them sent to you?</div>
                                    <div className="small text-gray-500">Jae Chun · 1d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                        alt="..." />
                                    <div className="status-indicator bg-warning"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Last month's report looks great, I am very happy with
                                        the progress so far, keep up the good work!</div>
                                    <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                </div>
                            </a>
                            <a className="dropdown-item d-flex align-items-center" href="#">
                                <div className="dropdown-list-image mr-3">
                                    <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                        alt="..." />
                                    <div className="status-indicator bg-success"></div>
                                </div>
                                <div>
                                    <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                        told me that people say this to all dogs, even if they aren't good...</div>
                                    <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                </div>
                            </a>
                            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                        </div>
                    </li> */}

{/* <!-- Topbar Search --> */ }
{/* <form
                    className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                    <div className="input-group">
                        <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                    aria-label="Search" aria-describedby="basic-addon2" />
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                        <i className="fas fa-search fa-sm"></i>
                    </button>
                </div>
                    </div>
                </form> */}

// useEffect(() => {
//     if (chainId !== process.env.REACT_APP_CHAINID && isAuthenticated) {
//         toast.error("Please Change your Network to Polygon Mumbai");
//         setcheckChainId(false);
//     }
//     else
//         setcheckChainId(true);

// }, [chainId])
// const { switchNetwork } = useChain();
// const { authenticate, authError, isAuthenticated, chainId,
//     logout } = useMoralis();

// const loginIntoWeb3 = async () => {
// if (!checkChainId) {
//     await switchNetwork("0x13881")
//     setcheckChainId(true);
//     return;
// }
// if (!isAuthenticated && checkChainId) {
//     await authenticate({ signingMessage: "My custom message" })
//         .then(async (user) => {
//             console.log('user', user)
//             if (authError || user == undefined) {
//                 if (authError != undefined && authError.message == "Non ethereum enabled browser") {
//                     window.open(process.env.REACT_APP_METMASK_LINK, "_blank");
//                 }
//                 else {
//                     toast.error(authError.message)
//                 }
//             }
//             else {
//                 toast.success("Wallet Connected.")
//                 window.localStorage.setItem("connectorId", "metamask");
//             }
//         }).catch((err) => {
//             console.log(err)
//         })
// }
// else {
//     await logout()
//     window.localStorage.removeItem("connectorId");
//     toast.success("Wallet Disconnected.")
// }
// }

// <div className='pb-8 pt-0'>
//     <Navbar className="navbar-top navbar-dark " expand="md" id="navbar-main">
//         <Container fluid className="justify-content-between">
//             <div className="d-flex">
//                 <Nav className="align-items-center d-none d-md-flex" navbar>
//                     <UncontrolledDropdown nav>
//                         <DropdownToggle className="pr-0" nav>
//                             <Media className="align-items-center">
//                                 {user?.avatar !== null ?
//                                     <img
//                                         style={{
//                                             width: "30px",
//                                             height: "30px",
//                                             objectFit: 'contain',
//                                             borderRadius: "50px"
//                                         }}
//                                         src={user?.avatar}
//                                     /> : <span className="avatar avatar-sm rounded-circle"></span>
//                                 }
//                                 <Media className="ml-2 d-none d-lg-block">
//                                     <span className="mb-0 text-sm font-weight-bold text-dark">
//                                         {user ? user?.firstname + " " + user?.lastname : "UnNamed"}
//                                     </span>
//                                 </Media>
//                             </Media>
//                         </DropdownToggle>
//                         <DropdownMenu className="dropdown-menu-arrow" right>
//                             {/* <DropdownItem className="noti-title" header tag="div">
//                             <h6 className="text-overflow m-0">Welcome!</h6>
//                         </DropdownItem> */}
//                             {/* <DropdownItem to="/admin/user-profile" tag={Link}>
//                             <i className="ni ni-single-02" />
//                             <span>My profile</span>
//                         </DropdownItem>
//                         <DropdownItem to="/admin/user-profile" tag={Link}>
//                             <i className="ni ni-settings-gear-65" />
//                             <span>Settings</span>
//                         </DropdownItem>
//                         <DropdownItem to="/admin/user-profile" tag={Link}>
//                             <i className="ni ni-calendar-grid-58" />
//                             <span>Activity</span>
//                         </DropdownItem>
//                         <DropdownItem to="/admin/user-profile" tag={Link}>
//                             <i className="ni ni-support-16" />
//                             <span>Support</span>
//                         </DropdownItem>
//                         <DropdownItem divider /> */}
//                             <DropdownItem href="/auth/login" onClick={() => userService.logout()}>
//                                 <i className="ni ni-user-run" />
//                                 <span>Logout</span>
//                             </DropdownItem>
//                         </DropdownMenu>
//                     </UncontrolledDropdown>
//                 </Nav>

//                 <Nav className="align-items-center d-none d-md-flex ml-3" navbar>
//                     <Button
//                         className="btn btn-primary"
//                         onClick={() => loginIntoWeb3()}>
//                         {isConnected ?
//                             `Disconnect (${address})` :
//                             "Connect Wallet"}
//                     </Button>
//                 </Nav>
//             </div>
//         </Container>
//     </Navbar>
// </div>