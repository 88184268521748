import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { propertyActions } from 'redux/actions';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { configActions } from 'redux/actions';
import { PERMISSIONS } from 'utils';
import { RESOURCE } from 'utils';
import { CheckPermission } from 'utils';
import { useMutatePropertyCompanyAmity } from 'hooks/useProperty';
import LocationFeature from './LocationFeature';
import { propertyService } from 'redux/services';
import { toast } from 'react-toastify';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Amenity = ({ stepper, type }) => {
    const dispatch = useDispatch()
    const [savedAmenity, setSavedAmenity] = useState([]);
    const [getAmenity, setGetAmenity] = useState([]);
    const { property } = useSelector(state => state.propertyReducer);
    // const { amenity } = useSelector(state => state.getAmenityReducer);
    const [amenity, setamenity] = useState([])
    const AmenitySchema = yup.object().shape({
        amenities: yup.array().min(1, 'Please select at least 1 amenity')
    })
    const { register, handleSubmit, reset, setValue, getValues, control, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(AmenitySchema)
    })
    const { mutate } = useMutatePropertyCompanyAmity();

    useEffect(() => {
        if (property) {
            let amm = []
            if (property?.propertyAmenity) {
                setGetAmenity(property?.propertyAmenity)
                property?.propertyAmenity.map(o => amm.push(o.amenity_id));
                setSavedAmenity(amm)
            }
        }
    }, [property]);

    useEffect(() => {
        if (getAmenity) {
            let amm = []
            if (getAmenity) {
                getAmenity.map(o => amm.push(o.amenity_id));
                setSavedAmenity(amm)
            }
        }
    }, [getAmenity]);

    useEffect(() => {
        if (localStorage.getItem('amenities')) {
            setamenity(JSON.parse(localStorage.getItem('amenities') || {}))
        }
    }, [localStorage.getItem('amenities')]);

    const onSubmit = async () => {
        trigger();
        const data = {
            property_id: property.id,
            amenities: savedAmenity,
            step_completed:
                property?.step_completed > 3.2 ? property?.step_completed : 3.2
        }
        await propertyService.storeAdditionalTwo(data)
            .then(
                res => {
                    setGetAmenity(res?.data)
                    toast.success(res.message)
                },
                error => {
                    toast.error(error)
                }
            );
    }

    const onPropertyStatusChange = async (id, e) => {
        const amData = getAmenity?.filter(o => o.amenity_id == id?.id);
        if (amData?.length <= 0) {
            toast.error("Please Save Data Before Change Status");
            return
        }
        let data = {
            property_id: property.id,
            id: amData?.length > 0 && amData[0].id,
            status: e.target.checked ? "Done" : "inprogress",
        };
        await mutate(
            data,
            {
                onSuccess: async (res) => {
                    toast.success(res.message)
                    setGetAmenity(res?.data)
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        );
    };
    const checkStatus = (id) => {
        const data = getAmenity?.filter(o => o.amenity_id == id?.id);
        return data?.length > 0 && data[0].status
    }

    function addOrRemoveFromArray(element) {
        let array = [...savedAmenity];
        const index = array.indexOf(parseFloat(element));
        if (index === -1) {
            array.push(parseFloat(element));
        } else {
            array.splice(index, 1);
        }
        setSavedAmenity(array)
    }

    return (
        <>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Features & Amenities</h3>
            </div>
            <Card className="bg-white shadow border-0 p-4 mt-3">
                <LocationFeature></LocationFeature>
            </Card>
            <Card className="bg-white shadow border-0 p-4">
                <div className='content-header mb-4 mt-4'>
                    <h3 className='mb-0 rz-heading rz-text-16'>Update Amenities</h3>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {amenity && (
                        <Row>
                            {
                                amenity.map((item, i) => {
                                    return (
                                        <FormGroup tag={Col} md='12' className="p-2" check key={i}>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <input type="checkbox"
                                                    onChange={(e) => addOrRemoveFromArray(e.target.value)}
                                                    checked={savedAmenity.includes(item.id)}
                                                    value={item.id} className="form-control mr-4"
                                                    id={item.id} style={{ width: '20px' }} />
                                                <div>
                                                    <img src={item.icon} width='20' className='mr-2' />
                                                    {item.name}
                                                </div>
                                                <div className="custom-control custom-switch" style={{
                                                    marginLeft: 20,
                                                }}>
                                                    {
                                                        savedAmenity.includes(item.id) && (
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)}
                                                                    checked={checkStatus(item) == "Done" ? true : false}
                                                                    onChange={(e) =>
                                                                        onPropertyStatusChange(item, e)
                                                                    }
                                                                    value={"Done"}
                                                                    className="custom-control-input"
                                                                    id={`property-aminity_status-${item.id}`}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`property-aminity_status-${item.id}`}
                                                                >
                                                                    <Badge
                                                                        color=""
                                                                        className={`badge badge-pill badge-${checkStatus(item) == "Done"
                                                                            ? "success"
                                                                            : "primary"
                                                                            } mr-4 mt-0`}
                                                                    >
                                                                        {checkStatus(item)}
                                                                    </Badge>
                                                                </label>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        </FormGroup>
                                    )
                                })
                            }
                        </Row>
                    )}
                    {errors.amenities && (
                        <small className="form-text text-danger">{errors.amenities?.message}</small>
                    )}
                    <div className='d-flex justify-content-center mt-4'>
                        <Button type='submit' className='rz-button-primary'>
                            <span className='align-middle d-sm-inline-block d-none'>Save Aminity</span>
                        </Button>
                    </div>
                </Form>
            </Card>
            <div className='d-flex align-items-center justify-content-between mt-5'>
                <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                    <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                    <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                </Button>
                <div className='d-flex align-items-center justify-content-end'>
                    <Link to='/admin/property'>
                        <Button type='button' className='rz-button-outline-primary'>
                            Cancel
                        </Button>
                    </Link>
                    <Button className='rz-button-outline-primary ml-3 mr-2' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>

                    <Button type='button' onClick={() => stepper.next()} className='rz-button-primary'>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Amenity;