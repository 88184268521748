import { combineReducers } from "redux";
import { userConstants } from "../constants";

import { loginReducer } from "./user/login.reducer";
import { authReducer } from "./user/auth.reducer";
import { meReducer } from "./user/me.reducer";
import { verifyOtpReducer } from "./user/verify.reducer";

import { propertyReducer } from "./property/property.reducer";
import { addEditPropertyReducer } from "./property/add-edit-property.reducer";
import { getPropertiesReducer } from "./property/get-properties.reducer";
import { getPropertiesPartnerReducer } from "./property/get-properties-partner.reducer";
import { getPropertiesTokenSellerReducer, getTokenSellerMyAsset, getTokenSellerSuperAdmin } from "./property/get-properties-token-seller.reducer";
import { savePropertySvgReducer } from "./property/save-property-svg.reducer";

import { getEmployeeReducer } from "./employee/get-employee.reducer";
import { getCountryReducer } from "./config/get-country.reducer";
import { getAmenityReducer } from "./config/get-amenity.reducer";
import { getLocationFeatureReducer } from "./config/get-amenity.reducer";
import { postAmenityReducer } from "./config/post-amenity.reducer";
import { updateAmenityReducer } from "./config/update-amenity.reducer";
import { deleteAmenityReducer } from "./config/delete-amenity.reducer";
import { addEditDocumentChecklistReducer } from "./config/add-edit-document-checklist.reducer";
import { getLegalChecklistReducer } from "./config/get-legal-checklist-reducer";
import { addLegalChecklistReducer } from "./config/add-legal-checklist-reducer";
import { updateLegalChecklistReducer } from "./config/edit-legal-checklist-reducer";
import { deleteLegalChecklistReducer } from "./config/delete-legal-checklist-reducer";
import { getOrdersReducer } from "./order/get-order.reducer";
import { getSingleOrderReducer } from "./order/get-single-order.reducer";
import { updateOrderStatusReducer } from "./order/update-status.reducer";
import { postRolesReducer } from "./roles/post-roles.reducer";
import { getRolesReducer } from "./roles/get-roles.reducer";
import { updateRolesReducer } from "./roles/update-roles.reducer";
import { deleteRolesReducer } from "./roles/delete-roles.reducer";
import { addEmployeeReducer } from "./employee/add-employee.reducer";
import { updateEmployeeReducer } from "./employee/update-employee.reducer";
import { deleteEmployeeReducer } from "./employee/delete-employee.reducer";
import { getSettingReducer } from "./setting/get-setting.reducer";
import { settingReducer } from "./setting/setting.reducer";
import { getPercentageReducer } from "./percentage/get-percentage.reducer";
import { getPropertyPercentageReducer } from "./percentage/get-property-percentage.reducer";
import { percentageReducer } from "./percentage/percentage.reducer";
import { getUsersReducer } from "./user/get-users.reducer";
import { getVideoLinkReducer } from "./config/config.reducer"
import { getPopuptextReducer } from "./config/config.reducer"
import { getCityLocationReducer } from "./config/config.reducer"
import { getAdminNotifications } from "./notification/notification.reducer"
import { getAccountReducer} from './account/get-account.reducer';
import { postAccountReducer} from './account/post-account.reducer';
import { viewAccountReducer } from "./account/view-account.reducer";
const appReducer = combineReducers({
  loginReducer,
  meReducer,
  authReducer,
  verifyOtpReducer,
  propertyReducer,
  addEditPropertyReducer,
  getPropertiesReducer,
  getPropertiesPartnerReducer,
  savePropertySvgReducer,
  getEmployeeReducer,
  getCountryReducer,
  getAmenityReducer,
  getLocationFeatureReducer,
  addEditDocumentChecklistReducer,
  getLegalChecklistReducer,
  addLegalChecklistReducer,
  updateLegalChecklistReducer,
  deleteLegalChecklistReducer,
  postAmenityReducer,
  deleteAmenityReducer,
  updateAmenityReducer,
  getOrdersReducer,
  getSingleOrderReducer,
  updateOrderStatusReducer,
  postRolesReducer,
  getRolesReducer,
  updateRolesReducer,
  deleteRolesReducer,
  getAccountReducer,
  postAccountReducer,
  viewAccountReducer,
  addEmployeeReducer,
  updateEmployeeReducer,
  deleteEmployeeReducer,
  getSettingReducer,
  settingReducer,
  getPercentageReducer,
  getPropertyPercentageReducer,
  percentageReducer,
  getUsersReducer,
  getPopuptextReducer,
  getVideoLinkReducer,
  getCityLocationReducer,
  getPropertiesPartnerReducer,
  getPropertiesTokenSellerReducer,
  getTokenSellerMyAsset,
  getTokenSellerSuperAdmin,
  getAdminNotifications
});

export const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    console.log("CLEAR ALL REDUCER STATES");
    state = undefined;
  }

  return appReducer(state, action);
};
