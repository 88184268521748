import { configConstants } from "redux/constants";

export function addEditDocumentChecklistReducer(state = {}, action) {
  switch (action.type) {
    case configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_REQUEST:
      return { loading: true, document: action.payload };
    case configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_SUCCESS:
      return { documetnChecklist: action.payload };
    case configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_FAILURE:
      return { loading: false };
    default:
      return {};
  }
}
