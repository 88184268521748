import { api } from "../../helpers";

export const configService = {
  getCountryStates,
  getAmenitiesByPropertyType,
  getLocationFeatureByPropertyType,
  addEditDocumentChecklist,
  getPropertyLegals,
  addLegalChecklist,
  updateLegalChecklist,
  deleteLegalChecklist,
  postAmenitiesByPropertyType,
  deleteAmenitiesByPropertyType,
  updateAmenitiesByPropertyType,
  getVideoLink,
  getPopuptext,
  getLocationCityWise
};

function getAmenitiesByPropertyType(property_type_id) {
  return api.get(`/config/amenity/${property_type_id}`);
}

function getLocationFeatureByPropertyType(property_type_id) {
  return api.get(`/config/locationfeature/${property_type_id}`);
}

function getLocationCityWise() {
  return api.get(`/config/location`);
}

function postAmenitiesByPropertyType(data) {
  return api.post(`/config/amenity`, data);
}

function deleteAmenitiesByPropertyType(id) {
  return api.delete(`/config/amenity/${id}`);
}

function updateAmenitiesByPropertyType(id, data) {
  return api.put(`/config/amenity/${id}`, data);
}

function getCountryStates(country_id) {
  return api.get(`/config/country/${country_id}`);
}
function addEditDocumentChecklist(document, stateId) {
  return api.post(`/config/document-checklist`, document);
}

function getPropertyLegals() {
  return api.get(`/config/property-legal`);
}

function addLegalChecklist(data) {
  return api.post(`/config/property-legal`, data);
}

function updateLegalChecklist(data, id) {
  return api.put(`/config/property-legal/${id}`, data);
}

function deleteLegalChecklist(id) {
  return api.delete(`/config/property-legal/${id}`);
}

function getVideoLink() {
  return api.get(`/config/videolink`);
}
function getPopuptext() {
  return api.get(`/config/popuptext`);
}

