import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { uploadImage } from 'helpers/image.helper';
import { useUpdateChannelKycDetails } from 'hooks/useMeQuery';
import { useUpdateKycDetails, useGetKycDetails } from 'hooks/useMeQuery';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModalHeader, Badge, Button, Card, Col, Container, Modal, Row } from 'reactstrap'
import {
    Form,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import * as yup from "yup";

const kycSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Invalid email format'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    dob: yup.string().required('Date of birth is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
});

export default function KYC() {
    const {
        register,
        watch,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(kycSchema),
        defaultValues: {
        },
    });
    const history = useHistory()
    const [step1Error, setStep1Error] = useState(true);
    const [step2Error, setStep2Error] = useState(true);
    const [step3Error, setStep3Error] = useState(true);
    const [step4Error, setStep4Error] = useState(true);
    const values = watch();
    useEffect(() => {
        if (values.firstName && values.lastName && values.dob && values.email && values.city && values.state && values.profilePic.length > 0) {
            setStep1Error(false)
        } else {
            setStep1Error(true)
        }
        if (values.aadhar && values.aadharBack.length > 0 && values.aadharFront.length > 0) {
            setStep2Error(false)
        } else {
            setStep2Error(true)
        }
        if (values.pan && values.panFront.length > 0) {
            setStep3Error(false)
        } else {
            setStep3Error(true)
        }
        if (values.account && values.ifsc) {
            setStep4Error(false)
        } else {
            setStep4Error(true)
        }
    }, [values])
    const [isStep1ModalOpen, setIsStep1ModalOpen] = useState(false);
    const [isStep2ModalOpen, setIsStep2ModalOpen] = useState(false);
    const [isStep3ModalOpen, setIsStep3ModalOpen] = useState(false);
    const [isStep4ModalOpen, setIsStep4ModalOpen] = useState(false);
    const { mutate: updateChannelParnetKyc } = useUpdateChannelKycDetails()
    const { data: kycDetails, refetch } = useGetKycDetails()
    const onSubmitChannel = async (status) => {
        let data = {
            email_verification_status: false,
            aadhar_verification_status: false,
            pan_verification_status: false,
            video_verification_status: false
        }
        data.user_id = kycDetails.id
        data.status = status;
        await updateChannelParnetKyc(data, {
            onSuccess: (res) => {
                refetch()
                // toast.success(res?.message);
            }
        })
    };
    useEffect(() => {
        if (kycDetails?.kyc?.status == 'auto_approved') {
            localStorage.setItem("kyc", JSON.stringify(kycDetails?.kyc || {}))
            history.push('/admin/index')
        }
    }, [kycDetails?.kyc?.status])
    const { mutate: updateKyc } = useUpdateKycDetails()
    const onSubmitStep1Modal = async () => {
        if (!getValues("email")) {
            toast.error("Please provide your email address")
            return;
        }

        if (!getValues("firstName")) {
            toast.error("Please provide your First name")
            return;
        }
        if (!getValues("lastName")) {
            toast.error("Please provide your Last name")
            return;
        }
        if (!getValues("dob")) {
            toast.error("Please provide your Date of Birth")
            return;
        }


        if (!getValues("city")) {
            toast.error("Please provide your City")
            return;
        }
        if (!getValues("state")) {
            toast.error("Please provide your State")
            return;
        }
        const data = {
            email: getValues("email"),
            firstname: getValues("firstName"),
            lastname: getValues("lastName"),
            dob: getValues("dob"),
            address: getValues("address"),
            reraAggentNumber: getValues("reraAggentNumber"),
        }
        if (getValues("profilePic").length > 0) {
            data.avatar = await uploadImage(getValues("profilePic")[0])
        }
        await updateKyc(data, {
            onSuccess: async (res) => {
                setIsStep1ModalOpen(false);
                if (!kycDetails?.kyc?.aadhar_verification_status) {
                    setIsStep2ModalOpen(true)
                }
                await refetch()
                toast.success('Step 1 Complete - On to Aadhar Details!')
            },
            onError: (err) => {
                toast.error(err)
            },
            onFailure: (err) => {
                console.log("err", err)
                toast.error('Sorry! Something went wrong. Please try again')
            }
        })
    }



    const onSubmitStep2Modal = async () => {
        if (!getValues("aadhar")) {
            toast.error("Please provide your Aadhar number")
            return;
        }
        const aadhaarRegex = /^\d{12}$/;
        if (!aadhaarRegex.test(getValues("aadhar"))) {
            toast.error("Please enter a valid Aadhar number")
            return;
        }
        const data = {
            aadhar_metadata: {
                aadhar_number: getValues("aadhar"),
            },
        }
        if (getValues("aadharFront").length > 0) {
            data.aadhar_metadata.front_photo = await uploadImage(getValues("aadharFront")[0])
        }
        else {
            toast.error("Please upload the front photo of your Aadhar")
            return;
        }
        if (getValues("aadharBack").length > 0) {
            data.aadhar_metadata.back_photo = await uploadImage(getValues("aadharBack")[0])
        }
        else {
            toast.error("Please upload the back photo of your Aadhar")
            return;
        }
        await updateKyc(data, {
            onSuccess: async (res) => {
                setIsStep2ModalOpen(false);
                if (!kycDetails?.kyc?.pan_verification_status) {
                    setIsStep3ModalOpen(true)
                }
                await refetch()
                toast.success('Step 2 Complete - On to PAN Details!')
            },
            onError: (err) => {
                toast.error(err)
            },
            onFailure: (err) => {
                toast.error('Sorry! Something went wrong. Please try again')
            }
        })
    }
    const onSubmitStep3Modal = async () => {
        if (!getValues("pan")) {
            toast.error("Please provide your PAN number")
            return;
        }
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
        if (!panRegex.test(getValues("pan"))) {
            toast.error("Please enter a valid PAN number")
            return;
        }
        const data = {
            pan_metadata: {
                pan_number: getValues("pan"),
            },
        }
        if (getValues("panFront").length > 0) {
            data.pan_metadata.front_photo = await uploadImage(getValues("panFront")[0])
        }
        else {
            toast.error("Please upload the photo of your PAN card")
            return;
        }
        await updateKyc(data, {
            onSuccess: async (res) => {
                await refetch()
                toast.success('Step 3 Complete - On to Bank Details!')
                setIsStep3ModalOpen(false);
                if (!kycDetails?.kyc?.video_verification_status) {
                    setIsStep4ModalOpen(true)
                }
            },
            onError: (err) => {
                toast.error(err)
            },
            onFailure: (err) => {
                toast.error('Sorry! Something went wrong. Please try again')
            }
        })
    }
    const onSubmitStep4Modal = async () => {
        if (!getValues("account")) {
            toast.error("Please provide your Account number")
            return;
        }
        if (!getValues("ifsc")) {
            toast.error("Please provide your Bank's IFSC code")
            return;
        }
        const data = {
            bank_acount_metadata: {
                account_number: getValues("account"),
                RERA_number: getValues("reraAggentNumber"),
                ifsc: getValues("ifsc")
            }
        }
        await updateKyc(data, {
            onSuccess: async (res) => {
                console.log("respose", res)
                if (res?.status == 200) {
                    await refetch()
                    toast.success('You have successfully submitted your KYC details');
                    setIsStep4ModalOpen(false);
                }
                else {
                    toast.error(res?.message);
                }
            },
            onError: (err) => {
                toast.error(err || 'Sorry! Something went wrong. Please try again')
            },
            onFailure: (err) => {
                console.log("error - respose", err)
                // toast.error('Something went wrong')
            }
        })

    }

    const isPersonalDetailsVerified = () => {
        return kycDetails?.kyc?.email_verification_status
    }
    return (
        <>
            <Container fluid className="mt-2 px-md-5 px-1 ">
                <h2 className='Montserrat-500 rz-text-24' style={{ color: "#5E5873" }}>KYC Process </h2>
                {
                    (
                        kycDetails?.kyc?.email_verification_status === true &&
                        kycDetails?.kyc?.aadhar_verification_status == true &&
                        kycDetails?.kyc?.pan_verification_status == true &&
                        kycDetails?.kyc?.video_verification_status == true
                    )
                        ?
                        <Card className="card bg-white p-4">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '20px',
                                }}
                            >

                                <i
                                    className=
                                    {
                                        (kycDetails?.kyc?.status == "processing" || kycDetails?.kyc?.status == "pending") ?
                                            "fas fa-clock" :
                                            kycDetails?.kyc?.status == "rejected" ?
                                                "fas fa-times-circle" :
                                                kycDetails?.kyc?.status == "auto_approved" ?
                                                    "fas fa-check-circle" : ``
                                    }
                                    style={{
                                        fontSize: '80px',
                                        color:
                                            (kycDetails?.kyc?.status == "processing" || kycDetails?.kyc?.status == "pending") ?
                                                "#FF9F43" :
                                                kycDetails?.kyc?.status == "rejected" ?
                                                    "#EA5455" :
                                                    kycDetails?.kyc?.status == "auto_approved" ?
                                                        "#28C76F" : ``
                                    }} />
                                <h2 className='mt-2'>Hey {kycDetails?.firstname} {kycDetails?.lastname}, 👋</h2>
                                <div style={{
                                    textAlign: "center",
                                    width: "40%"
                                }}>
                                    <p className='mt-2'>
                                        {
                                            (kycDetails?.kyc?.status == "processing" || kycDetails?.kyc?.status == "pending") ?
                                                `Your KYC submission is successful. 
                                                You will receive an email to sign a Memorandum of Understanding (MOU) between you and Ryzer. 
                                                Once approved by our team, you can fully utilize the platform.` :
                                                kycDetails?.kyc?.status == "rejected" ?
                                                    `Sorry! The KYC details you have uploaded seems to be incorrect. Please try again` :
                                                    kycDetails?.kyc?.status == "auto_approved" ?
                                                        `Your KYC has been successfully completed. You can now start listing your asstes` : ``
                                        }
                                    </p>
                                    {
                                        (kycDetails?.kyc?.status == "rejected" && kycDetails?.kyc?.remark != "") && (
                                            <p className='mt-2'>
                                                <span style={{ color: "#82868B" }}>Reason for decline :</span>
                                                {
                                                    kycDetails?.kyc?.status == "rejected" ?
                                                        kycDetails?.kyc?.remark :
                                                        ""
                                                }
                                            </p>
                                        )
                                    }
                                    {/* {
                                        kycDetails?.kyc?.status == "auto_approved" && (
                                            <Row>
                                                <Col className="d-flex justify-content-center">
                                                    <Link
                                                        onClick={() => localStorage.setItem("kyc", JSON.stringify(kycDetails?.kyc || {}))}
                                                        className="btn rz-button-primary" to="/admin/index">
                                                        Go to Dashboard
                                                    </Link>
                                                </Col>
                                            </Row>
                                        )
                                    } */}
                                    {
                                        (kycDetails?.kyc?.status == "rejected") && (
                                            <div className="d-flex mt-2 justify-content-center align-items-center">
                                                <button
                                                    type="button"
                                                    onClick={() => onSubmitChannel('pending')}
                                                    className="btn btn-danger">Retry</button>
                                            </div>
                                        )

                                    }
                                </div>
                            </div>
                        </Card>
                        :
                        <Card className="card bg-white p-4">
                            <div>
                                <h2 style={{ color: "#4B4B4B" }} className='Montserrat-500'>{"Let’s get you started!"}</h2>
                                <p className='Montserrat-400' style={{ color: "#6E6B7B" }}>{"There are 4 steps to complete your KYC where you need to keep your ID’s and documents ready  to make this process faster."}</p>
                            </div>
                            <div className="accordion">
                                {
                                    [{
                                        message: "Personal details",
                                        button: "Enter details",
                                        setModal: setIsStep1ModalOpen,
                                        disabled: isPersonalDetailsVerified()
                                    },
                                    {
                                        message: "Aadhar verification",
                                        button: "Upload File",
                                        setModal: setIsStep2ModalOpen,
                                        disabled: kycDetails?.kyc?.aadhar_verification_status
                                    },
                                    {
                                        message: "PAN verification",
                                        button: "Upload File",
                                        setModal: setIsStep3ModalOpen,
                                        disabled: kycDetails?.kyc?.pan_verification_status
                                    },
                                    {
                                        message: "Account verification",
                                        setModal: setIsStep4ModalOpen,
                                        button: "Validate Account",
                                        disabled: kycDetails?.kyc?.video_verification_status
                                    }
                                    ]?.map((item, i) => {
                                        return (
                                            <Card key={i} className='px-4 mt-1 py-2'>
                                                <div className="d-flex justify-content-between align-item-center">
                                                    <div className="d-flex justify-content-between">
                                                        <h3 className='Montserrat-500 ryzer-text-primary'>Step {i + 1} :</h3>
                                                        <p className='ml-2 Montserrat-400' style={{ color: "#6E6B7B" }}>{item?.message}</p>
                                                    </div>
                                                    <div className="justify-content-center align-item-center">
                                                        {item.disabled ?

                                                            <>
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-success mr-4`}
                                                                >
                                                                    {"Done"}
                                                                </Badge>
                                                            </>
                                                            :
                                                            <Button
                                                                onClick={() => item.setModal(true)}
                                                                className='rz-button-primary'
                                                                disabled={item.disabled}>
                                                                {item?.button}
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })
                                }
                            </div>
                            <Modal isOpen={isStep1ModalOpen} backdrop={true} className="modal-md modal-fullscreen" style={{ "marginRight": 0, "marginTop": 0 }}>
                                <Form>
                                    <div className="px-4 pt-4 d-flex justify-content-between">
                                        <div>
                                            <h2 className='rz-heading rz-text-18'>KYC process</h2>
                                            <h3 className="rz-text-18 Montserrat-500 mt-3" style={{ color: "#4B4B4B" }} id="exampleModalLabel">
                                                Step 1: Personal details
                                            </h3>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                className="btn-close btn-link bg-white"
                                                onClick={() => setIsStep1ModalOpen(false)}
                                            >
                                                <i className="fas fa-times"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`email`}>
                                                Email address *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                placeholder="Enter your email address"
                                                {...register("email")}
                                            // {...register("email", { required: "Email is required" })}
                                            />
                                            {/* {errors.email && <span>{errors.email.message}</span>} */}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`firstName`}>
                                                First Name *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                placeholder="Enter your First name"
                                                {...register("firstName", {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`lastName`}>
                                                Last Name *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                placeholder="Enter your last name"
                                                {...register("lastName", {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`dob`}>
                                                Date of Birth *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="date"
                                                max={new Date().toISOString().split('T')[0]}
                                                // placeholder="Doe"
                                                {...register("dob", {})}
                                            />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <Label className="form-label" htmlFor={`address`}>
                                                Address
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                {...register("address", {})}
                                            />
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`address`}>
                                                Current city *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                placeholder='Enter your current city'
                                                type="text"
                                                {...register("city", {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`address`}>
                                                Current state *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                placeholder='Enter your current state'
                                                type="text"
                                                {...register("state", {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`profilePic`}>
                                                Photo (.png or .jpg) *<br />
                                            </Label>

                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="profilePic"
                                                    type="file"
                                                    placeholder="image"
                                                    accept="image/*"
                                                    {...register("profilePic", {})}
                                                />
                                                <label className="custom-file-label" for="profilePic">
                                                    Choose file
                                                </label>
                                                {watch('profilePic')?.[0]?.name || ''}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`reraAggentNumber`}>
                                                Certification number
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                placeholder="Enter your Certification number"
                                                {...register("reraAggentNumber", {})}
                                            />
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='rz-button-outline-primary mr-3' onClick={() => setIsStep1ModalOpen(!isStep1ModalOpen)}>
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={step1Error}
                                            className='rz-button-primary'
                                            onClick={async () => {
                                                await onSubmitStep1Modal()
                                            }}>
                                            Next
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                            {/* modal 1 */}
                            <Modal isOpen={isStep2ModalOpen} className="modal-md" style={{ "marginRight": 0, "marginTop": 0 }}>
                                {/* <Form onSubmit={handleSubmit(onSubmitStep2Modal)}> */}
                                <Form style={{ height: "100vh" }}>
                                    <div className="px-4 pt-4 d-flex justify-content-between">
                                        <div>
                                            <h3 className="rz-text-18 Montserrat-500 mt-3" style={{ color: "#4B4B4B" }} id="exampleModalLabel">
                                                Step 2 : Aadhar verification
                                            </h3>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                className="btn-close btn-link bg-white"
                                                onClick={() => setIsStep2ModalOpen(false)}
                                            >
                                                <i className="fas fa-times"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`aadhar`}>
                                                Aadhar number *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                maxLength={12}
                                                placeholder="Enter your 12 digit Aadhar number"
                                                {...register("aadhar", {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`aadharFront`}>
                                                Front side photo (.png or .jpg) *<br />
                                            </Label>

                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="aadharFront"
                                                    type="file"
                                                    placeholder="image"
                                                    accept="image/*"
                                                    {...register("aadharFront", {})}
                                                />
                                                <label className="custom-file-label" for="aadharFront">
                                                    Choose file
                                                </label>
                                                {watch('aadharFront')?.[0]?.name || ''}
                                            </div>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`aadharBack`}>
                                                Back side photo (.png or .jpg) *<br />
                                            </Label>

                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="aadharBack"
                                                    type="file"
                                                    placeholder="image"
                                                    accept="image/*"
                                                    {...register("aadharBack", {})}
                                                />
                                                <label className="custom-file-label" for="aadharBack">
                                                    Choose file
                                                </label>
                                                {watch('aadharBack')?.[0]?.name || ''}
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='rz-button-outline-primary mr-3' onClick={() => setIsStep2ModalOpen(false)}>
                                            Cancel
                                        </Button>

                                        <Button
                                            onClick={async () => {
                                                await onSubmitStep2Modal()
                                            }}
                                            disabled={step2Error}
                                            className='rz-button-primary'
                                        >
                                            Next
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Modal>

                            {/* modal 2 */}
                            <Modal isOpen={isStep3ModalOpen} className="modal-md" style={{ "marginRight": 0, "marginTop": 0 }}>
                                {/* <Form onSubmit={handleSubmit(onSubmitStep3Modal)}> */}
                                <Form style={{ height: "100vh" }}>
                                    <div className="px-4 pt-4 d-flex justify-content-between">
                                        <div>
                                            <h3 className="rz-text-18 Montserrat-500 mt-3" style={{ color: "#4B4B4B" }} id="exampleModalLabel">
                                                Step 3 : PAN verification
                                            </h3>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                className="btn-close btn-link bg-white"
                                                onClick={() => setIsStep3ModalOpen(false)}
                                            >
                                                <i className="fas fa-times"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`pan`}>
                                                PAN number *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                maxLength={12}
                                                placeholder="Enter your PAN number"
                                                {...register("pan", { pattern: "/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i" })}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`panFront`}>
                                                Front side photo (.png or .jpg) *<br />
                                            </Label>

                                            <div className="custom-file">
                                                <input
                                                    className="custom-file-input"
                                                    id="panFront"
                                                    type="file"
                                                    placeholder="image"
                                                    accept="image/*"
                                                    {...register("panFront", {})}
                                                />
                                                <label className="custom-file-label" for="panFront">
                                                    Choose file
                                                </label>
                                                {watch('panFront')?.[0]?.name || ''}
                                            </div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='rz-button-outline-primary' onClick={() => setIsStep3ModalOpen(false)}>
                                            Cancel
                                        </Button>

                                        <Button
                                            onClick={async () => {
                                                await onSubmitStep3Modal()
                                            }}
                                            disabled={step3Error}
                                            className='rz-button-primary'>
                                            Next
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Modal>

                            {/* modal 3 */}
                            <Modal isOpen={isStep4ModalOpen} className="modal-md" style={{ "marginRight": 0, "marginTop": 0 }}>
                                {/* <Form onSubmit={handleSubmit(onSubmitStep4Modal)}> */}
                                <Form style={{ height: "100vh" }}>
                                    <div className="px-4 pt-4 d-flex justify-content-between">
                                        <div>
                                            <h3 className="rz-text-18 Montserrat-500 mt-3" style={{ color: "#4B4B4B" }} id="exampleModalLabel">
                                                Step 4 : Account verification
                                            </h3>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                className="btn-close btn-link bg-white"
                                                onClick={() => setIsStep4ModalOpen(false)}
                                            >
                                                <i className="fas fa-times"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`account`}>
                                                Account number *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                placeholder="Enter your unique Bank Account number"
                                                {...register("account", {})}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label" htmlFor={`ifsc`}>
                                                IFSC number *
                                            </Label>
                                            <input
                                                className="form-control mb-3"
                                                type="text"
                                                placeholder="Enter your Bank IFSC number"
                                                {...register("ifsc", {})}
                                            />
                                        </FormGroup>
                                        {/* <h3 className="text-primary">Verify account</h3> */}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button className='rz-button-outline-primary' onClick={() => setIsStep4ModalOpen(false)}>
                                            Cancel
                                        </Button>

                                        <Button
                                            onClick={async () => {
                                                await onSubmitStep4Modal()
                                            }}
                                            disabled={step4Error}
                                            className='rz-button-primary'>
                                            Submit
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                        </Card>
                }
            </Container>
        </>
    )
}

{/* Upload Document File */ }
{/* {selectedDoc && (
                <Modal isOpen={isModalOpen} centered={true}>
                    <ModalHeader
                        close={<i className="fa fa-times"></i>}
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                        Upload Document
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={(e) => handleChange(e, selectedDoc)}
                                    accept={selectedDoc?.type}
                                />
                                <label className="custom-file-label" for="customFile">
                                    Choose file
                                </label>
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            )} */}
{/* <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="px-4 pt-4 d-flex justify-content-between">
                                <div>
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Add New Feature
                                    </h5>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        className="btn-close btn-link bg-white"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        <i className="fas fa-times    "></i>
                                    </Button>
                                </div>
                            </div>
                            <ModalBody>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`question`}>
                                        Title
                                    </Label>
                                    <input
                                        className="form-control mb-3"
                                        type="text"
                                        placeholder="Feature New"
                                        {...register("feature", {})}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`description`}>
                                        Description
                                    </Label>
                                    <input
                                        className="form-control mb-3"
                                        type="text"
                                        placeholder="Description for feature New"
                                        {...register("description", {})}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`answer`}>
                                        Attachment <br />
                                        <small> (Allowed PNG format only) </small>
                                    </Label>

                                    <div className="custom-file">
                                        <input
                                            className="custom-file-input"
                                            id="customFile"
                                            type="file"
                                            placeholder="image"
                                            accept="image/*"
                                            {...register("image", {})}
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            Choose file
                                        </label>
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => setIsModalOpen(!isModalOpen)}>
                                    Cancel
                                </Button>

                                <Button color="primary">Save</Button>
                            </ModalFooter>
                        </Form>
                    </Modal> */}

{/* modal 5 */ }
{/* <Modal isOpen={isStep5ModalOpen} className="modal-md" style={{ "marginRight": 0, "marginTop": 0 }}>
                        <Form >
                            <div className="px-4 pt-4 d-flex justify-content-between">
                                <div>
                                    <h3 className="modal-title" id="exampleModalLabel">
                                        Step 5 : Profile picture
                                    </h3>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        className="btn-close btn-link bg-white"
                                        onClick={() => setIsStep5ModalOpen(false)}
                                    >
                                        <i className="fas fa-times"></i>
                                    </Button>
                                </div>
                            </div>
                            <ModalBody>

                                <FormGroup>
                                    <Label className="form-label" htmlFor={`profilePic`}>
                                        Photo (.png or .jpg) <br />
                                    </Label>

                                    <div className="custom-file">
                                        <input
                                            className="custom-file-input"
                                            id="profilePic"
                                            type="file"
                                            placeholder="image"
                                            accept="image/*"
                                            {...register("profilePic", {})}
                                        />
                                        <label className="custom-file-label" for="profilePic">
                                            Choose file
                                        </label>
                                        {watch('profilePic')?.[0]?.name || ''}
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => setIsStep5ModalOpen(false)}>
                                    Cancel
                                </Button>

                                <Button
                                    onClick={() => {
                                        setIsStep5ModalOpen(false);
                                        setIsStep6ModalOpen(true)
                                    }}
                                    color="primary">
                                    Next
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal> */}

{/* <Modal isOpen={isStep6ModalOpen} className="modal-md" style={{ "marginRight": 0, "marginTop": 0 }}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="px-4 pt-4 d-flex justify-content-between">
                                <div>
                                    <h3 className="modal-title" id="exampleModalLabel">
                                        Step 6 : RERA Agent Number (Optional)
                                    </h3>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        className="btn-close btn-link bg-white"
                                        onClick={() => setIsStep6ModalOpen(false)}
                                    >
                                        <i className="fas fa-times"></i>
                                    </Button>
                                </div>
                            </div>
                            <ModalBody>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`reraAggentNumber`}>
                                        RERA Agent number *
                                    </Label>
                                    <input
                                        className="form-control mb-3"
                                        type="text"
                                        placeholder="98765467898765478"
                                        {...register("reraAggentNumber", {})}
                                    />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => setIsStep6ModalOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        setIsStep6ModalOpen(false);
                                        onSubmit();
                                    }}
                                    color="primary">
                                    Complete
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal> */}