import PropertyList from "views/property/PropertyList";
import ChannelPatnerPropertyList from "views/property/ChannelPatnerPropertyList";
import AddEditProperty from "views/property/AddEditProperty";
import AddEditCompany from "views/company/AddEditCompany";

import Login from "views/auth/Login.js";
import Otp from "views/auth/Otp";
import AddEditDocumentChecklist from "views/config/document-checklist/AddEditDocumentChecklist";
import AddEditLegalChecklist from "views/config/legal-checklist/AddEditLegalChecklist";
import Config from "views/config/configuration/Config";
import Amenities from "views/config/amenities/index";
import OrdersList from "views/orders/OrdersList";
import SingleOrder from "views/orders/SingleOrder";
import AddUpdateRoles from "views/role/AddUpdateRoles";
import RoleList from "views/role/RoleList";
import EmployeeList from "views/employee/EmployeeList";
import EmployeeListRole from "views/employee/EmployeeListRole";
import AddEditEmployee from "views/employee/AddEditEmployee";
import EmployeeReview from "views/employee/Reviews"
import CustomerList from "views/customers/CustomerList";
import CustomerDetails from "views/customers/CustomerDetails";
import Setting from "views/setting";
import Reviews from "./views/reviews";
import Feedback from "./views/feedback/AdminFeedback";

import Company from "./views/company/CompanyList";
import { RESOURCE } from "./utils";
import CompanyPropertyList from "views/company/CompanyPropertyList";
import Docs from "views/docs/Docs";
import Register from "views/auth/Register";
import Report from "views/report/Report";
import KYC from "views/channel_partner/KYC";
import ChannelPartnerKYC from "views/employee/ChannelPartnerKYC";
import TokenPropertyList from "views/property/TokenSeller/TokenPropertyList";
import TokenSellerMyAssetList from "views/property/TokenSeller/TokenSellerMyAssetList";
import TokenSellerSuperAdmin from "views/property/TokenSeller/TokenSellerSuperAdmin";
import TokenSellerDetails from "views/property/TokenSeller/TokenSellerDetails";
import NotificationList from "views/notification/NotificationList";
import AccountManagement from "views/platform/AccountManagement";
import AddAccount from "views/platform/AddAccount";
import ViewAccount from "views/platform/ViewAccount";
import AssetSellerCommission from "views/platform/AssetSeller/AssetSellerCommission";
import TokenSellerCommission from "views/platform/TokenSeller/TokenSellerCommission";
import TokenSellerMyCommissionById from "views/platform/TokenSeller/TokenSellerMyCommissionById";
import AssetSellerMyCommissionById from "views/platform/AssetSeller/AssetSellerMyCommissionById";

import PlatformFee from "views/platform/FeeManagement/PlatformFee";
import AssetListById from "views/platform/FeeManagement/AssetListById";
import FeeManagement from "views/platform/FeeManagement/FeeManagement";
import ArchiveCustomerList from "views/customers/ArchiveCustomerList";
import SuperAdminFee from "views/platform/superAdmin/SuperAdminFee";
import SuperAdminWithdrawDetails from "views/platform/superAdmin/SuperAdminWithdrawDetails";
import PitchPresentation from "views/property/PitchPresentation";

var routes = [
    {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-primary",
        component: PropertyList,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/property/create",
        name: "Create Property",
        icon: "ni ni-pin-3 text-orange",
        component: AddEditProperty,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/company/create",
        name: "Create Compnay",
        icon: "ni ni-pin-3 text-orange",
        component: AddEditCompany,
        layout: "/admin",
        sidebar: false,
    }, {
        path: "/company/:company_id/edit",
        name: "Edit Compnay",
        icon: "ni ni-pin-3 text-orange",
        component: AddEditCompany,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/property/:property_id/edit",
        name: "Edit Property",
        icon: "ni ni-pin-3 text-orange",
        component: AddEditProperty,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/listofproperty",
        name: "Company Asset List",
        icon: "fas fa-building",
        component: CompanyPropertyList,
        layout: "/admin",
        permissions_name: RESOURCE?.Configuration,
        sidebar: false,
    },
    {
        path: "/property",
        name: "Asset List",
        icon: "fas fa-building",
        component: PropertyList,
        layout: "/admin",
        sidebar: true,
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-info",
        component: Login,
        layout: "/auth",
        sidebar: false,
    },
    {
        path: "/verify",
        name: "Otp",
        icon: "ni ni-circle-08 text-pink",
        component: Otp,
        layout: "/auth",
        sidebar: false,
    },
    {
        path: "/register",
        name: "Register",
        icon: "ni ni-circle-08 text-pink",
        component: Register,
        layout: "/auth",
        sidebar: false,
    },
    {
        path: "/config/document-checklist/:id",
        name: "Add/Edit Document Checklist",
        icon: "fa fa-home",
        component: AddEditDocumentChecklist,
        layout: "/admin",
        sidebar: false,
    },
    // {
    //     path: "/company",
    //     name: "Company",
    //     icon: "fas fa-city",
    //     component: Company,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Company,
    //     sidebar: true,
    // },
    // {
    //     path: "/configuration",
    //     name: "Config",
    //     icon: "fas fa-info-circle",
    //     component: Config,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Configuration,
    //     sidebar: true,
    // },
    {
        path: "/config/legal-checklist/:id",
        name: "Property Legals",
        icon: "fas fa-info-circle",
        component: AddEditLegalChecklist,
        layout: "/admin",
        sidebar: false,
    },
    // {
    //     path: "/amenities",
    //     name: "Amenites",
    //     icon: "fas fa-hot-tub",
    //     component: Amenities,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Amenity,
    //     sidebar: true,
    // },
    {
        path: "/order/:id",
        name: "Order",
        icon: "fas fa-shopping-cart",
        component: SingleOrder,
        layout: "/admin",
        sidebar: false,
    },
    // {
    //     path: "/order",
    //     name: "Orders",
    //     icon: "fas fa-shopping-cart",
    //     component: OrdersList,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Order,
    //     sidebar: true,
    // },
    {
        path: "/role/add",
        name: "Add Roles",
        icon: "fas fa-user-tag",
        component: AddUpdateRoles,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/role/:id/edit",
        name: "Update Roles",
        icon: "fas fa-dice-d20",
        component: AddUpdateRoles,
        layout: "/admin",
        sidebar: false,
    },
    // {
    //     path: "/role",
    //     name: "Role Setup",
    //     icon: "fas fa-user-tag",
    //     component: RoleList,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Role,
    //     sidebar: true,
    // },
    {
        path: "/customer/:id",
        name: "Customer Details",
        icon: "fas fa-sticky-note",
        component: CustomerDetails,
        layout: "/admin",
        sidebar: false,
    },
    // {
    //     path: "/customer",
    //     name: "Customers",
    //     icon: "fas fa-user-friends",
    //     component: CustomerList,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Customer,
    //     sidebar: true,
    // },
    // {
    //     path: "/archive-customer",
    //     name: "Archive Customers",
    //     icon: "fas fa-user-friends",
    //     component:  ArchiveCustomerList ,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Customer,
    //     sidebar: false,
    // },
    {
        path: "/employee/add",
        name: "Add/Edit Employee Details",
        icon: "fas fa-user-tag",
        component: AddEditEmployee,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/employee/:id/edit",
        name: "Add/Edit fEmployee Details",
        icon: "fas fa-user-tag",
        component: AddEditEmployee,
        layout: "/admin",
        sidebar: false,
    },
    {
        path: "/employee/:id/reviews",
        name: "Employee Reviews",
        icon: "fas fa-mug-hot",
        component: EmployeeReview,
        layout: "/admin"
    },
    // {
    //     path: "/employee",
    //     name: "Employee List",
    //     icon: "fas fa-users",
    //     component: EmployeeList,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Employee,
    //     sidebar: true,
    // },
    {
        path: "/employeerole/:role_name",
        icon: "fas fa-mug-hot",
        component: EmployeeListRole,
        layout: "/admin",
        permissions_name: RESOURCE?.Employee,
    },
    {
        path: "/channel/:id",
        icon: "fas fa-mug-hot",
        component: ChannelPartnerKYC,
        layout: "/admin",
        permissions_name: RESOURCE?.Employee,
    },
    // {
    //     path: "/setting",
    //     name: "Settings",
    //     icon: "fas fa-cog",
    //     component: Setting,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Setting,
    //     sidebar: true,
    // },
    // {
    //     path: "/partner",
    //     name: "Channel Partner Assets",
    //     icon: "fas fa-handshake",
    //     component: ChannelPatnerPropertyList,
    //     layout: "/admin",
    //     sidebar: true,
    //     permissions_name: RESOURCE?.Channel_Partner,
    // },
    // {
    //     path: "/token-seller",
    //     name: "Token Seller Assets",
    //     icon: "fas fa-handshake",
    //     component: TokenSellerSuperAdmin,
    //     layout: "/admin",
    //     sidebar: true,
    //     permissions_name: RESOURCE?.Channel_Partner,
    // },
    {
        path: "/tokensellerdetails",
        name: "Seller Details",
        icon: "fas fa-list",
        component: TokenSellerDetails,
        layout: "/admin",
        sidebar: false,
    },
    // {
    //     path: "/reviews",
    //     name: "Reviews",
    //     icon: "fas fa-star",
    //     component: Reviews,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Reviews,
    //     sidebar: true,
    // },
    // {
    //     path: "/feedback",
    //     name: "Feedback",
    //     icon: "fas fa-comment",
    //     component: Feedback,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Feedback,
    //     sidebar: true,
    // },
    // {
    //     path: "/report",
    //     name: "Report",
    //     icon: "fas fa-chart-bar",
    //     component: Report,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.Report,
    //     sidebar: true,
    // },
    // {
    //     path: "/FeeManagement",
    //     name: "Fee Management",
    //     icon: "fas fa-money-bill-wave",
    //     component: FeeManagement,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.SuperAdmin,
    //     sidebar: true,
    // },
    // {
    //     path: "/account_managament/view/:id",
    //     name: "Account Management",
    //     icon: "fas fa-users",
    //     component: ViewAccount,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.SuperAdmin,
    //     sidebar: false,
    // },
    // {
    //     path: "/account_managament/add",
    //     name: "Account Management",
    //     icon: "fas fa-users",
    //     component: AddAccount,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.SuperAdmin,
    //     sidebar: false,
    // },
    // {
    //     path: "/account_managament",
    //     name: "Account Management",
    //     icon: "fas fa-users",
    //     component: AccountManagement,
    //     layout: "/admin",
    //     permissions_name: RESOURCE?.SuperAdmin,
    //     sidebar: true,
    // },
    // {
    //     path: "/PlatFromFees",
    //     name: "Platfrom Fees",
    //     icon: "fas fa-percentage",
    //     component: PlatFromFees,
    //     layout: "/admin",
    //     sidebar: false,
    // },
    {
        path: "/docs",
        name: "Feedback",
        icon: "fas fa-star",
        component: Docs,
        layout: "/admin",
    },
    {
        path: "/kyc",
        name: "Kyc",
        icon: "fas fa-star",
        component: KYC,
        layout: "/admin",
    },
    {
        path: "/property",
        name: "My Asset List",
        icon: "fas fa-building",
        component: PropertyList,
        layout: "/admin",
        sidebar: true,
        role_type: [1],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/property/:property_id/edit",
        name: "Edit Property",
        icon: "ni ni-pin-3 text-orange",
        component: AddEditProperty,
        layout: "/admin",
        role_type: [1, 2],
        sidebar: false,
    },
    {
        path: "/seller",
        name: "Asset List",//token seller
        icon: "fas fa-building",
        component: TokenPropertyList,
        layout: "/admin",
        sidebar: true,
        role_type: [2],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/myasset",
        name: "My Asset",//token seller
        icon: "fas fa-building",
        component: TokenSellerMyAssetList,
        layout: "/admin",
        sidebar: true,
        role_type: [2],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/view_asset_details/:property_id",
        name: "Asset Details",
        icon: "fas fa-building",
        component: PitchPresentation,
        layout: "/admin",
        sidebar: false,
        role_type: [2],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/commission",
        name: "My Commission",
        icon: "fas fa-percentage",
        component: AssetSellerCommission,
        layout: "/admin",
        sidebar: true,
        role_type: [1],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/commission_view/:property_id",
        name: "Show Commission",
        icon: "fas fa-percentage",
        component: AssetSellerMyCommissionById,
        layout: "/admin",
        role_type: [1],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/tokenseller_commission",
        name: "My Commission",
        icon: "fas fa-percentage",
        component: TokenSellerCommission,
        layout: "/admin",
        sidebar: true,
        role_type: [2],
        permissions_name: RESOURCE?.Property,
    },
    {
        path: "/tokenseller_commission_view/:property_id",
        name: "Show Commission",
        icon: "fas fa-percentage",
        component: TokenSellerMyCommissionById,
        layout: "/admin",
        role_type: [2],
        permissions_name: RESOURCE?.Property,
    },
    // {
    //     path: "/notification",
    //     name: "Notification List",
    //     icon: "fas fa-bell",
    //     component: NotificationList,
    //     layout: "/admin",
    //     sidebar: true,
    //     permissions_name: RESOURCE?.SuperAdmin,
    // },
];
export default routes;
