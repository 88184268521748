import React, { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Label, FormGroup, Row, Col, Button, Form, Input, CustomInput, Card, Badge, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { propertyActions, employeeActions } from './../../../redux/actions'
import { useParams } from 'react-router-dom'
import { PROPERTY_TYPE_VALUE } from 'utils'
import { toast } from 'react-toastify'
import { api } from 'helpers'
import { checkNotChannelPartner } from 'utils'
import { checkAttechment } from 'utils'
import { BsArrowRightShort } from "react-icons/bs";
import { DatePicker } from 'reactstrap-date-picker';
import { Link } from 'react-router-dom'
const CustomModal = ({ title, content, onClose, status, onSubmitChannel }) => {
    const [reason, setReason] = useState('');

    const onSubmit = () => {
        onSubmitChannel(status, reason);
        onClose();
    }
    return (
        <Modal isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>{title}</ModalHeader>
            <ModalBody>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                    }}
                >
                    {title == "Decline" &&
                        <i className="fas fa-times-circle" style={{
                            fontSize: '80px',
                            color: "#EA5455"
                        }} />
                    }
                    {title == "Hold" &&
                        <i className="fas fa-pause-circle" style={{
                            fontSize: '80px',
                            color: "#EA5455"
                        }} />
                    }
                    {title == "Approve" &&
                        <i className="fas fa-check-circle" style={{
                            fontSize: '80px',
                            color: "#28C76F"
                        }} />
                    }

                    <div>
                        <p className='mt-2'
                            style={{
                                textAlign: "center",
                                // width: "40%"
                            }}>
                            {content}
                        </p>
                        {title != "Approve" &&

                            <Form>
                                <Row>
                                    <FormGroup tag={Col}>
                                        <Label className='form-label' htmlFor={`reason`}>
                                            Reason
                                        </Label>
                                        <input
                                            id={`reason`}
                                            placeholder='Enter a reason '
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            className={classnames({ 'form-control': true })}
                                        />
                                    </FormGroup>
                                </Row>
                            </Form>
                        }

                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn btn-outline-primary"
                    onClick={onClose}>
                    No
                </Button>
                <Button
                    color='primary'
                    className='btn-next'
                    onClick={onSubmit}>
                    Yes
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const BasicDetails = ({ stepper, parentStepper, type }) => {
    const dispatch = useDispatch()
    const [estimateDate, setEstimateDate] = useState('');
    const [initialteDate, setInitiatedDate] = useState('');
    const [managers, setManagers] = useState([]);
    const property = useSelector(state => state.propertyReducer.property);
    const employees = useSelector(state => state.getEmployeeReducer);
    const [teanet, setTennet] = useState(false);
    const { property_id } = useParams();
    const [attachement1, setAttachement1] = React.useState("")//logo
    const [attachement2, setAttachement2] = React.useState("")//lease_agreement
    const [attachement3, setAttachement3] = React.useState("")//pricing_sheet
    const [attachement4, setAttachement4] = React.useState("")//development_map
    const [isRejectedOpen, setIsRejectedOpen] = useState(false);
    const [isOnHoldOpen, setIsOnHoldOpen] = useState(false);
    const [isApproveOpen, setIsApproveOpen] = useState(false);
    let today = new Date().toISOString().split('T')[0];
    const handleRejectedOpen = () => {
        setIsRejectedOpen(true);
    };
    const handleOnHoldOpen = () => {
        setIsOnHoldOpen(true);
    };

    const handleApproveOpen = () => {
        setIsApproveOpen(true);
    };

    const handleCloseModals = () => {
        setIsRejectedOpen(false);
        setIsOnHoldOpen(false);
        setIsApproveOpen(false);
    };

    const SignupSchema = yup.object().shape({
        name: yup.string().required(),
        property_type_id: yup.string().required(),
        initiated_year: yup.string().required(),
        share_type: yup.string().required(),
        description: yup.string().required(),
        tenate: yup.object(),
        // total_land_area: yup.string().required(),
        // managers: yup.array()
        //     .of(
        //         yup
        //             .object()
        //             .shape({
        //                 label: yup.string().required(),
        //                 value: yup.string().required()
        //             })
        //     )
        //     .required('Please select manager')
    })

    const { register, handleSubmit, reset,
        getValues,
        setValue, control, watch, trigger, formState: { errors } } = useForm({
            resolver: yupResolver(SignupSchema)
        })
    const onSubmitChannel = (status, reason = "") => {
        let data = {
            property_id: property_id,
            status: status
        };
        if (status != 'Active') {
            data.reason = reason
        }
        dispatch(
            propertyActions.genericPropertyAction("updatePropertyStatus", data)
        );

    };
    useEffect(() => {
        dispatch(employeeActions.getEmployees('?page=1&select=name,id&status=Active&role=3'))
    }, []);

    const property_type_id_watch = useWatch({
        control,
        name: "property_type_id",
    });
    const asset_stage_watch = useWatch({
        control,
        name: "stage",
    });
    const asset_category_watch = useWatch({
        control,
        name: "asset_category",
    });
    const share_type_watch = useWatch({
        control,
        name: "share_type"
    });
    let fieldNames = ['name', 'property_type_id', 'initiated_year', 'description', 'asset_category'];
    if (property_type_id_watch != 1)
        fieldNames.push('stage')
    if (asset_stage_watch == "Pre-leased")
        fieldNames.push('total_land_area')

    const watchAllFields = watch(fieldNames);
    const allFieldsFilled = Object.values(watchAllFields).every(field => field !== undefined && field !== null && field !== '');

    useEffect(() => {
        if (property && property_id) {
            const options = []
            setInitiatedDate(property?.initiated_year);
            if (property?.propertyManager) {
                property.propertyManager.map(item => {
                    options.push({ value: item?.user?.id, label: item?.user?.name })
                })
            }
            if (property?.propertyTenent) {
                setAttachement1(property?.propertyTenent?.logo)
                setAttachement2(property?.propertyTenent?.lease_agreement);
                setAttachement3(property?.propertyTenent?.pricing_sheet);
                setAttachement4(property?.propertyTenent?.development_map);
                setEstimateDate(property?.propertyTenent?.estimated_completion);
                setTennet(true);
            }


            reset({
                name: property.name,
                property_type_id: property.property_type_id,
                initiated_year: property.initiated_year,
                like_to_sell: property?.like_to_sell,
                // rental_status: property.rental_status,
                // ownership_model: property.ownership_model,
                // total_development_area: property?.total_development_area,
                stage: property.stage,
                asset_category: property.asset_category,
                share_type: property.share_type,
                description: property.description,
                managers: options,
                mark_as: property.mark_as,
                rera_number: property.rera_number,
                asset_value: property.asset_value,
                total_land_area: property.total_land_area,
                total_floors_of_tower: property.total_floors_of_tower,
                floor_number: property.floor_number,
                tenate: {
                    tenent_name: property?.propertyTenent?.tenent_name,
                    tenent_location: property?.propertyTenent?.tenent_location,
                    lease_period: property?.propertyTenent?.lease_period,
                    total_lease_period: property?.propertyTenent?.total_lease_period,
                    total_development_area: property?.propertyTenent?.total_development_area,
                    development_map: property?.propertyTenent?.development_map,
                    pricing_sheet: property?.propertyTenent?.pricing_sheet,
                    occupancy_rate: property?.propertyTenent?.occupancy_rate,
                    //total_floors_of_tower: property?.propertyTenent?.total_floors_of_tower,
                    estimated_completion: property?.propertyTenent?.estimated_completion,
                }
            });
        }
    }, [property]);

    const onSubmit = async (data) => {
        trigger()
        // console.log("Alldata",data);
        // return ;
        if (!data?.tenate) {
            data.tenate = {}
        }
        //data.initiated_year=initialteDate;
        if (teanet) {
            if (asset_stage_watch == "Pre-leased") {
                if (!data?.tenate?.tenent_name || data?.tenate?.tenent_name == "") {
                    toast.error("Tenant name is required");
                    return;
                }
                if (!data?.tenate?.tenent_location || data?.tenate?.tenent_location == "") {
                    toast.error("Tenant location is required");
                    return;
                }
                if (!data?.tenate?.lease_period || data?.tenate?.lease_period == "") {
                    toast.error("Lease period is required");
                    return;
                }
                if (!data?.tenate?.total_lease_period || data?.tenate?.total_lease_period == "") {
                    toast.error("Total lease period is required");
                    return;
                }
                if (!data?.tenate?.occupancy_rate || data?.tenate?.occupancy_rate == "") {
                    toast.error("occupancy rate is required");
                    return;
                }
            }
            if (asset_stage_watch == 'Under Construction') {
                if (!data?.tenate?.estimated_completion || data?.tenate?.estimated_completion == "") {
                    toast.error("Estimated Completion is required");
                    return;
                }
                if (!data?.tenate?.total_development_area || data?.tenate?.total_development_area == "") {
                    toast.error("Total Development Area is required");
                    return;
                }
            }

            if (asset_stage_watch == "Developed Land") {
                if (!data?.tenate?.total_development_area || data?.tenate?.total_development_area == "") {
                    toast.error("Total Development Area is required");
                    return;
                }
            }
            // if (!data?.tenate?.development_map || data?.tenate?.development_map == "") {
            //     toast.error("development_map is required");
            //     return;
            // }
            // if (!data?.tenate?.pricing_sheet || data?.tenate?.pricing_sheet == "") {
            //     toast.error("pricing_sheet is required");
            //     return;
            // }
        }
        if (typeof attachement1 != 'string' && attachement1 != undefined) {
            const formDataNew = new FormData();
            formDataNew.append("file", attachement1)
            try {
                const res = await api.post("/file",
                    formDataNew
                );
                data.tenate.logo = res?.url
            } catch (ex) {
                console.log(ex);
            }
        }
        if (typeof attachement2 != 'string' && attachement2 != undefined) {
            const formDataNew = new FormData();
            formDataNew.append("file", attachement2)
            try {
                const res = await api.post("/file",
                    formDataNew
                );

                data.tenate.lease_agreement = res?.url
            } catch (ex) {
                console.log(ex);
            }
        }
        if (typeof attachement3 != 'string' && attachement3 != undefined) {
            const formDataNew = new FormData();
            formDataNew.append("file", attachement3)
            try {
                const res = await api.post("/file",
                    formDataNew
                );

                data.tenate.pricing_sheet = res?.url
            } catch (ex) {
                console.log(ex);
            }
        }
        if (typeof attachement4 != 'string' && attachement4 != undefined) {
            const formDataNew = new FormData();
            formDataNew.append("file", attachement4)
            try {
                const res = await api.post("/file",
                    formDataNew
                );

                data.tenate.development_map = res?.url
            } catch (ex) {
                console.log(ex);
            }
        }
        if (!teanet) {
            data.tenate = {}
        }
        if (Object.keys(errors).length === 0) {
            if (property_id) data.property_id = property_id
            dispatch(propertyActions.aboutOne(data,
                checkNotChannelPartner() ? stepper : parentStepper
            ))
        }
    }

    useEffect(() => {
        if (employees?.employee) {
            const options = []
            employees?.employee?.map(item => {
                if (item.name) {
                    options.push({ value: item.id, label: item.name })
                }
            });
            setManagers(options)
        }
    }, [employees]);

    const onManagerChange = (e) => {
        if (e.length == 0)
            setValue('managers', null)
        else
            setValue('managers', e)
    }

    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 text-muted'>Basic Details</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`name`}>
                            Title <span className='required-star'>*</span>
                        </Label>
                        <input
                            id={`name`}
                            placeholder='eg. The Lake view Park, Brigade '
                            {...register(`name`)}
                            className={classnames({ 'is-invalid': errors.name, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`property_type_id`}>
                            Asset Type <span className='required-star'>*</span>
                        </Label>
                        <select className={classnames({ 'is-invalid': errors.property_type_id, 'form-control': true })} {...register(`property_type_id`)} id="property_type">
                            <option value=''>Select Asset Type</option>
                            <option value={`${PROPERTY_TYPE_VALUE?.LANDPARCEL}`}>Land Parcel</option>
                            <option value={`${PROPERTY_TYPE_VALUE?.RESIDENTIAL}`}>Residential</option>
                            <option value={`${PROPERTY_TYPE_VALUE?.COMMERCIAL}`}>Commercial</option>
                            <option value={`${PROPERTY_TYPE_VALUE?.HOLIDAY_HOME}`}>Holiday Home</option>
                            {/* <option value={`${PROPERTY_TYPE_VALUE?.AGRICULTURE_LAND}`}>Agriculture Land</option> */}
                        </select>
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label>Share Type <span className='required-star'>*</span></Label>
                        <select className={classnames({ 'is-invalid': errors.share_type, 'form-control': true })}
                            {...register(`share_type`)} id="share_type">
                            <option value=''>Select Option</option>
                            <option value='Partner Share Certificate'>Partner Share Certificate</option>
                            <option value='LLP Certificate'>LLP Certificate</option>
                            <option value='Divided'>Divided</option>
                            <option value='Undivided'>Undivided</option>
                            <option value='Private Limited'>Private Limited</option>
                            <option value='NCD'>NCD</option>
                        </select>
                    </FormGroup>
                    {
                        (property_type_id_watch != '') && (<>
                            <FormGroup tag={Col} md='6'>
                                <Label>Asset Category <span className='required-star'>*</span></Label>
                                <select className={classnames({
                                    'is-invalid': errors.asset_category,
                                    'form-control': true
                                })}
                                    {...register(`asset_category`)} id="asset_category">
                                    <option value=''>Select Option</option>
                                    {
                                        share_type_watch == 'NCD' ?
                                            <option value='Suite Rooms'>Suite Rooms</option>
                                            :
                                            property_type_id_watch == 1 ? (
                                                <>
                                                    <option value='Developed Land'>Developed Land</option>
                                                    <option value='Individual Land'>Individual Land</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value='Tower'>Tower</option>
                                                    <option value='Villa'>Villa</option>
                                                </>
                                            )
                                    }
                                </select>
                            </FormGroup>
                            {
                                (property_type_id_watch == 1) &&
                                (
                                    <>
                                        <FormGroup tag={Col} md='6'></FormGroup>
                                    </>
                                )}
                            {property_type_id_watch != 1 &&
                                (
                                    <FormGroup tag={Col} md='6'>
                                        <Label>Asset Stage <span className='required-star'>*</span></Label>
                                        <select className={classnames({
                                            'is-invalid': errors.stage,
                                            'form-control': true
                                        })}
                                            {...register(`stage`)} id="stage">
                                            <option value=''>Select Option</option>
                                            {
                                                share_type_watch == 'NCD' ?
                                                    <>
                                                        <option value='Operating'>Operating</option>
                                                    </>
                                                    :
                                                    <>
                                                        <option value='Under Construction'>Under Construction</option>
                                                        <option value='Pre-leased'>Pre-leased</option>
                                                    </>

                                            }
                                        </select>
                                    </FormGroup>
                                )}

                            {
                                share_type_watch == 'NCD' ?
                                    <FormGroup tag={Col} md='6'>
                                        <Label className='form-label'>Operation Status <span className='required-star'>*</span></Label>
                                        <select className={classnames({ 'is-invalid': errors.initiated_year, 'form-control': true })}
                                            {...register(`initiated_year`)} id="initiated_year">
                                            <option value=''>Select Option</option>
                                            <option value='running'>Running</option>
                                        </select>
                                    </FormGroup>
                                    :
                                    property_type_id_watch != 1 ?
                                        (<FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`initiated_year`}>
                                                {asset_stage_watch == "Under Construction"
                                                    ? <>Construction Started <span className='required-star'>*</span></> : <>Construction Completed on <span className='required-star'>*</span></>}
                                            </Label>
                                            <br />
                                            <input className="form-control" type='date' {...register('initiated_year')} />
                                        </FormGroup>) :
                                        (
                                            asset_category_watch == "Developed Land" && (
                                                <FormGroup tag={Col} md='6'>
                                                    <Label className='form-label' htmlFor={`initiated_year`}>
                                                        Development Started <span className='required-star'>*</span>
                                                    </Label>
                                                    <Controller
                                                        name="initiated_year"
                                                        control={control}
                                                        rules={{ required: 'Date and Time is required' }}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                id="initiated_year"
                                                                {...field}
                                                                selected={field.value || null}
                                                                onChange={(date) => field.onChange(date)}
                                                            />
                                                        )}
                                                    />
                                                </FormGroup>
                                            )
                                        )}

                            <FormGroup tag={Col} md='6'>
                                <Label className='form-label' htmlFor={`rera_number`}>
                                    {"RERA Number"}
                                </Label>
                                <input
                                    id={`RERA Number *`}
                                    placeholder={"1234567890"}
                                    {...register(`rera_number`)}
                                    className={classnames({
                                        'is-invalid': errors?.rera_number,
                                        'form-control': true
                                    })}
                                />
                            </FormGroup>
                            {
                                (property_type_id_watch == 2 || property_type_id_watch == 3 || property_type_id_watch == 4) && (
                                    <>
                                        {
                                            share_type_watch == 'NCD' ?
                                                <FormGroup tag={Col} md='6'>
                                                    <Label className='form-label' >
                                                        Total Suites Rooms <span className='required-star'>*</span>
                                                    </Label>
                                                    <input
                                                        placeholder='2'
                                                        {...register(`total_floors_of_tower`)}
                                                        className={classnames({
                                                            'is-invalid': errors?.total_floors_of_tower,
                                                            'form-control': true
                                                        })}
                                                    />
                                                </FormGroup>
                                                :
                                                <FormGroup tag={Col} md='6'>
                                                    <Label className='form-label' htmlFor={`total_floors_of_tower`}>
                                                        Total floors
                                                    </Label>
                                                    <input
                                                        placeholder='10'
                                                        {...register(`total_floors_of_tower`)}
                                                        className={classnames({
                                                            'is-invalid': errors?.total_floors_of_tower,
                                                            'form-control': true
                                                        })}
                                                    />
                                                </FormGroup>
                                        }
                                    </>
                                )
                            }
                            {
                                (property_type_id_watch == 3 && asset_stage_watch == 'Pre-leased') && (
                                    <>
                                        <FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`floor_number`}>
                                                Floor Number
                                            </Label>
                                            <input
                                                placeholder='2'
                                                {...register(`floor_number`)}
                                                className={classnames({
                                                    'is-invalid': errors?.floor_number,
                                                    'form-control': true
                                                })}
                                            />
                                        </FormGroup>
                                    </>
                                )
                            }
                            {
                                (property_type_id_watch == 1) && (asset_category_watch == 'Individual Land') && (
                                    <>
                                        <FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`total_land_area`}>
                                                Total Constructed Area (in sqft) <span className='required-star'>*</span>
                                            </Label>
                                            <input
                                                id={`total_land_area`}
                                                placeholder='100m'
                                                {...register(`total_land_area`)}
                                                className={classnames({
                                                    'is-invalid': errors.total_land_area,
                                                    'form-control': true
                                                })}
                                            />
                                        </FormGroup>
                                    </>
                                )
                            }
                            {(property_type_id_watch != 1 &&
                                asset_stage_watch == "Pre-leased") &&
                                <FormGroup tag={Col} md='6'>
                                    <Label className='form-label' htmlFor={`total_land_area`}>
                                        Total Constructed Area (in sqft) <span className='required-star'>*</span>
                                    </Label>
                                    <input
                                        id={`total_land_area`}
                                        placeholder='100m'
                                        {...register(`total_land_area`)}
                                        className={classnames({
                                            'is-invalid': errors.total_land_area,
                                            'form-control': true
                                        })}
                                    />
                                </FormGroup>
                            }

                            {
                                !checkNotChannelPartner() && (
                                    <>
                                        <FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`asset_value`}>
                                                {"Asset value "}
                                            </Label>
                                            <input
                                                id={`Enter assetvalue Number`}
                                                placeholder={"Enter Asset value"}
                                                {...register(`asset_value`)}
                                                className={classnames({
                                                    'is-invalid': errors?.asset_value,
                                                    'form-control': true
                                                })}
                                            />
                                        </FormGroup>
                                        <FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`like_to_sell`}>
                                                {"No of units (sqft) that you would like to sell "}
                                            </Label>
                                            <input
                                                placeholder={"E.g. 200"}
                                                {...register(`like_to_sell`)}
                                                className={classnames({
                                                    'is-invalid': errors?.like_to_sell,
                                                    'form-control': true
                                                })}
                                            />
                                        </FormGroup>
                                    </>
                                )
                            }
                            <FormGroup tag={Col} md='12'>
                                <Label htmlFor="about-property">About Property <span className='required-star'>*</span></Label>
                                <textarea
                                    className={classnames({ 'is-invalid': errors.description, 'form-control': true })}
                                    {...register(`description`)}
                                    id="about-property"
                                    rows="4"
                                    placeholder="Enter Description" />
                            </FormGroup>
                            {/* {
                        property_type_id_watch == 1 && (
                            <>
                                <FormGroup tag={Col} md='6'>
                                    <label className='form-label'>
                                        Property brochure
                                    </label>
                                    <input className='form-control'
                                        type="file"
                                        onChange={e => setAttachement1(e.target.files[0])}
                                    />
                                    {typeof attachement1 == "string"  && <a href={`${attachement1}`} target="_blank">View Attachement</a>}
                                </FormGroup>
                                <FormGroup tag={Col} md='6'>
                                    <label className='form-label'>
                                        Pricing table/sheet
                                    </label>
                                    <input className='form-control'
                                        type="file"
                                        onChange={e => setAttachement3(e.target.files[0])}
                                    />
                                    {typeof attachement3 == "string"  && <a href={`${attachement3}`} target="_blank">View Attachement</a>}
                                </FormGroup>
                            </>
                        )
                    } */}
                        </>)}
                </Row>
                {
                    (property_type_id_watch != '') && (<> {(property_type_id_watch == 2 || property_type_id_watch == 3 || property_type_id_watch == 4
                        || (property_type_id_watch == 1 && asset_category_watch == "Developed Land")
                    ) && (
                            <Row
                                style={{
                                    backgroundColor: "#EFEFEF",
                                    borderRadius: 10,
                                    borderWidth: 2,
                                    padding: 10,
                                }}
                                className='my-2'>
                                <Col
                                    className='d-flex justify-content-between' md='12'>
                                    <h3 className='mb-0 text-muted'>
                                        {
                                            share_type_watch=='NCD'?
                                            'Does this asset have Operation details?'
                                            :
                                            property_type_id_watch == 1 ? "Does this asset have Development details?" :
                                                asset_stage_watch == "Under Construction" ?
                                                    "Does this asset have Construction details?"
                                                    : "Does this asset have a Tenant?"}
                                    </h3>
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            checked={teanet}
                                            name={`property_teneta`}
                                            onChange={(e) => setTennet(!teanet)}
                                            className="custom-control-input"
                                            id={`property-teneta`}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={`property-teneta`}
                                        >
                                            <Badge
                                                color=""
                                                className={`badge badge-pill badge-${teanet == true
                                                    ? "success"
                                                    : "primary"
                                                    } mr-4`}
                                            >
                                                {teanet ? "Yes" : "No"}
                                            </Badge>
                                        </label>
                                    </div>
                                </Col>
                                <Col className='d-flex justify-content-between my-2' md='12'>
                                    {
                                        teanet && (
                                            <Row>
                                                {(asset_stage_watch == "Under Construction" || share_type_watch=='NCD') && (
                                                    <>
                                                        {
                                                        share_type_watch!='NCD' &&
                                                        <FormGroup tag={Col} md='6'>
                                                            <Label className='form-label' htmlFor={`tenate.estimated_completion`}>
                                                                Estimated completion <span className='required-star'>*</span>
                                                            </Label>
                                                            {/* <Controller
                                                                name="estimated_completion"
                                                                control={control}
                                                                rules={{ required: 'Date and Time is required' }}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        selected={field.value || new Date()}
                                                                        onChange={(date) => {
                                                                            field.onChange(date);
                                                                            setEstimateDate(date);
                                                                        }}
                                                                    />
                                                                )}
                                                            /> */}
                                                            <input type='date' className='form-control' {...register('tenate.estimated_completion')} />
                                                            {/* <input
                                                            id={`name`}
                                                            placeholder='2'
                                                            {...register(`tenate.estimated_completion`)}
                                                            className={classnames({
                                                                'is-invalid': errors?.tenate?.estimated_completion,
                                                                'form-control': true
                                                            })}
                                                        /> */}
                                                        </FormGroup>}

                                                        <FormGroup tag={Col} md='6'>
                                                            <Label className='form-label' htmlFor={`tenate.total_development_area`}>
                                                                {share_type_watch=='NCD'?'Total Built-up Area (in Sqft) *':'Total development Area (in Sqft)'} <span className='required-star'>*</span>
                                                            </Label>
                                                            <input
                                                                id={`tenate.total_development_area`}
                                                                placeholder='100m'
                                                                {...register(`tenate.total_development_area`)}
                                                                className={classnames({ 'is-invalid': errors?.tenate?.total_development_area, 'form-control': true })}
                                                            />
                                                        </FormGroup>
                                                       
                                                    </>
                                                )}
                                                {asset_stage_watch == "Pre-leased" && (
                                                    <>
                                                        <FormGroup tag={Col} md='6'>
                                                            <Label className='form-label' htmlFor=' Construction Status'>
                                                                Tenent Name <span className='required-star'>*</span>
                                                            </Label>
                                                            <input
                                                                id='tenent_name'
                                                                placeholder='Enter tenent name'
                                                                {...register('tenate.tenent_name')}
                                                                className={classnames({
                                                                    'is-invalid': errors?.tenate?.tenent_name,
                                                                    'form-control': true
                                                                })}
                                                            />
                                                            {errors?.tenate?.tenent_name && (
                                                                <div className='invalid-feedback'>{errors?.tenate?.tenent_name?.message}</div>
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup tag={Col} md="6">
                                                            <Label className="form-label" htmlFor="tenent_location">
                                                                Tenent Location <span className='required-star'>*</span>
                                                            </Label>
                                                            <input
                                                                id="tenent_location"
                                                                placeholder={"Enter City, State"}
                                                                {...register('tenate.tenent_location')}
                                                                className={classnames({
                                                                    'is-invalid': errors?.tenate?.tenent_location,
                                                                    'form-control': true,
                                                                })}
                                                            />
                                                            {errors?.tenate?.tenent_location && (
                                                                <div className="invalid-feedback">{errors.tenate.tenent_location.message}</div>
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup tag={Col} md="6">
                                                            <Label className="form-label" htmlFor="lease_period">
                                                                Lease Period <span className='required-star'>*</span>
                                                            </Label>
                                                            <input
                                                                id="lease_period"
                                                                placeholder="Enter Lease Period"
                                                                {...register('tenate.lease_period')}
                                                                className={classnames({
                                                                    'is-invalid': errors?.tenate?.lease_period,
                                                                    'form-control': true,
                                                                })}
                                                            />
                                                            {errors?.tenate?.lease_period && (
                                                                <div className="invalid-feedback">{errors.tenate.lease_period.message}</div>
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup tag={Col} md='6'>
                                                            <Label className='form-label' htmlFor={`tenate.total_lease_period`}>
                                                                Total Lease Period <span className='required-star'>*</span>
                                                            </Label>
                                                            <input
                                                                id={`name`}
                                                                placeholder={"Exp. 6 Years, 11 Months"}
                                                                {...register(`tenate.total_lease_period`)}
                                                                className={classnames({
                                                                    'is-invalid': errors?.tenate?.total_lease_period,
                                                                    'form-control': true
                                                                })}
                                                            />
                                                        </FormGroup>

                                                        <FormGroup tag={Col} md='6'>
                                                            <Label className='form-label' htmlFor={`tenate.occupancy_rate`}>
                                                                Occupancy Rate (in %) <span className='required-star'>*</span>
                                                            </Label>
                                                            <input
                                                                id={`name`}
                                                                placeholder='2'
                                                                {...register(`tenate.occupancy_rate`)}
                                                                className={classnames({
                                                                    'is-invalid': errors?.tenate?.occupancy_rate,
                                                                    'form-control': true
                                                                })}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup tag={Col} md='6'>

                                                        </FormGroup>

                                                    </>
                                                )}
                                                {/* Only For Land Parcel */}
                                                {(property_type_id_watch == 1 && asset_category_watch == "Developed Land") && (
                                                    <FormGroup tag={Col} md='6'>
                                                        <Label className='form-label' htmlFor={`tenate.total_development_area`}>
                                                            Total development Area (in Sqft) <span className='required-star'>*</span>
                                                        </Label>
                                                        <input
                                                            id={`tenate.total_development_area`}
                                                            placeholder='100m'
                                                            {...register(`tenate.total_development_area`)}
                                                            className={classnames({ 'is-invalid': errors?.tenate?.total_development_area, 'form-control': true })}
                                                        />
                                                    </FormGroup>
                                                )}
                                               { 
                                                share_type_watch!='NCD' &&
                                                <FormGroup tag={Col} md='6'>
                                                    <label className='form-label'>
                                                        {asset_stage_watch == "Pre-leased" ? "Tenant Logo" : "Official Brochure"}
                                                    </label>
                                                    <div className="custom-file cursor-pointer">
                                                        <input
                                                            type="file"
                                                            className='custom-file-input'
                                                            onChange={e => setAttachement1(e.target.files[0])}
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">{attachement1?.name ? attachement1.name : 'Choose file'}</label>
                                                    </div>
                                                    {checkAttechment(attachement1) && <a href={`${attachement1}`} target="_blank">View Attachement</a>}
                                                </FormGroup>}
                                                <FormGroup tag={Col} md='6'>
                                                    <label className='form-label'>
                                                        {share_type_watch=='NCD'?'Asset Specifications':asset_stage_watch == "Pre-leased" ? "Tenant Agreement" : "Specifications"}
                                                    </label>
                                                    <div className="custom-file cursor-pointer">
                                                        <input
                                                            className='custom-file-input'
                                                            type="file"
                                                            onChange={e => setAttachement2(e.target.files[0])}
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">{attachement2?.name ? attachement2.name : 'Choose file'}</label>
                                                    </div>

                                                    {checkAttechment(attachement2) && <a href={`${attachement2}`} target="_blank">View Attachement</a>}
                                                </FormGroup>
                                                {(asset_stage_watch != "Pre-leased" || share_type_watch=='NCD') && (
                                                    <>
                                                        <FormGroup tag={Col} md='6'>
                                                            <label className='form-label'>
                                                                {share_type_watch=='NCD'?'Quarterly Cashflow Sheet':"Pricing sheet"}
                                                            </label>
                                                            <div className="custom-file cursor-pointer">
                                                                <input className='custom-file-input'
                                                                    type="file"
                                                                    onChange={e => setAttachement3(e.target.files[0])}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">{attachement3?.name ? attachement3.name : 'Choose file'}</label>
                                                            </div>

                                                            {checkAttechment(attachement3) && <a href={`${attachement3}`} target="_blank">View Attachement</a>}
                                                        </FormGroup>
                                                        <FormGroup tag={Col} md='6'>
                                                            <label className='form-label'>
                                                                {share_type_watch=='NCD'?'Quarterly Bookings Sheet':"Floor Map"}
                                                            </label>
                                                            <div className="custom-file cursor-pointer">
                                                                <input className='custom-file-input'
                                                                    type="file"
                                                                    onChange={e => setAttachement4(e.target.files[0])}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">{attachement4?.name ? attachement4.name : 'Choose file'}</label>
                                                            </div>

                                                            {checkAttechment(attachement4) && <a href={`${attachement4}`} target="_blank">View Attachement</a>}
                                                        </FormGroup>
                                                    </>
                                                )}
                                            </Row>
                                        )
                                    }
                                </Col>
                            </Row>
                        )}
                        {checkNotChannelPartner() && (
                            <Row>
                                <FormGroup tag={Col} md='6'>
                                    <Label htmlFor="managers">Asset Manager <span className='required-star'>*</span></Label>
                                    <Controller
                                        name="managers"
                                        control={control}
                                        render={({ field, formState }) => {
                                            return (
                                                <Select
                                                    id='managers'
                                                    {...field}
                                                    styles={{
                                                        control: styles => ({
                                                            ...styles,
                                                            boxShadow: formState.errors.managers ? 'none' : styles.boxShadow,
                                                            borderColor: formState.errors.managers ? 'red' : styles.borderColor
                                                        })
                                                    }}
                                                    onChange={onManagerChange}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    className='basic-multi-select'
                                                    options={managers}
                                                    classNamePrefix="select"
                                                />
                                            )
                                        }

                                        }
                                    />
                                </FormGroup>
                                <FormGroup tag={Col} md='6'>
                                    <Label htmlFor="mark_as">Set As</Label>
                                    <select className={classnames({ 'is-invalid': errors.mark_as, 'form-control': true })} {...register(`mark_as`)} id="mark_as">
                                        <option value=''>Select Option</option>
                                        <option>Trending</option>
                                    </select>
                                </FormGroup>
                            </Row>
                        )}
                    </>)
                }
                <div className={`d-flex mt-5 justify-content-${property_id ? "between" : "end"}`}>
                    {property_id &&
                        (<Button type='button' color='success' onClick={() => {
                            if (checkNotChannelPartner())
                                stepper.next()
                            else
                                parentStepper.next()

                        }}
                            className='btn-next' >
                            <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                            <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                        </Button>)
                    }
                    <div>
                        <Link to='/admin/property'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button
                            type='submit'
                            disabled={!allFieldsFilled}
                            className='btn-next rz-button-primary ml-3'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Next</span>

                        </Button>
                    </div>
                    {

                        (checkNotChannelPartner() && localStorage.getItem("channel")) && (
                            <>
                                <div className="d-flex justify-content-start align-items-center">
                                    <button type="button"
                                        // onClick={() => onSubmitChannel("Rejected")}
                                        onClick={handleRejectedOpen}
                                        className="btn btn-danger">Reject</button>
                                    <button type="button"
                                        // onClick={() => onSubmitChannel("Hold")}
                                        onClick={handleOnHoldOpen}
                                        className="btn btn-warning">On Hold</button>
                                    <button type="button"
                                        // onClick={() => onSubmitChannel("Active")}
                                        onClick={handleApproveOpen}
                                        className="btn btn-success">Approve</button>
                                </div>
                            </>
                        )
                    }
                </div>

                {isRejectedOpen && (
                    <CustomModal
                        title="Decline"
                        status="Rejected"
                        content="Are you sure want to decline this asset for further process?If so, please add a valid reason for channel partners to respond and take necessary action."
                        onClose={handleCloseModals}
                        onSubmitChannel={onSubmitChannel}
                    />
                )}

                {isOnHoldOpen && (
                    <CustomModal
                        title="Hold"
                        status="Hold"
                        content="Are you sure want to hold this asset for further process?"
                        onClose={handleCloseModals}
                        onSubmitChannel={onSubmitChannel}
                    />
                )}

                {isApproveOpen && (
                    <CustomModal
                        title="Approve"
                        status="Active"
                        content="Do you wish to go ahead and approve this asset for further process?The status will be updated to the channel partners via mail within few minutes"
                        onClose={handleCloseModals}
                        onSubmitChannel={onSubmitChannel}
                    />
                )}

            </Form>
        </Fragment>
    )
}

export default BasicDetails

{/* <FormGroup tag={Col} md='6'>
                        <Label>Assets stage</Label>
                        <select className={classnames({ 'is-invalid': errors.stage, 'form-control': true })}
                            {...register(`stage`)} id="property_type">
                            <option value=''>Select Option</option>
                            {
                                property_type_id_watch == 3 && (//commericla
                                    <>
                                        <option value='Pre-leased'>Pre-leased</option>
                                        <option value='Construction'>Construction</option>
                                        <option value='Bare shell'>Bare shell</option>
                                        <option value='Warm shell'>Warm shell</option>
                                    </>
                                )
                            }
                            {
                                property_type_id_watch == 2 && (//residential
                                    <>
                                        <option value='Fully rented'>Fully rented</option>
                                        <option value='Construction'>Construction</option>
                                        <option value='Not rented'>Not rented</option>
                                        <option value='Furnished'>Furnished</option>
                                    </>
                                )
                            }
                            {
                                property_type_id_watch == 1 && (//open plot
                                    <>
                                        <option value='Hot location'>Hot location</option>
                                        <option value='Ready to move'>Ready to move</option>
                                        <option value='Farm land'>Farm land</option>
                                        <option value='Outstation'>Outstation</option>
                                    </>
                                )
                            }
                            {
                                (property_type_id_watch == 4 || property_type_id_watch == 5) && (//open plot
                                    <>
                                        <option value='Hot location'>Hot location</option>
                                        <option value='Ready to move'>Ready to move</option>
                                        <option value='Farm land'>Farm land</option>
                                        <option value='Outstation'>Outstation</option>
                                    </>
                                )
                            }
                        </select>
                    </FormGroup> */}