import React from "react";
import { Button, Card, Container, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import moment from "moment";
import { NavLink, useLocation } from "react-router-dom";

const OrderList = ({ data }) => {
  return (
    <>
      <div className="raw mt-5">
        <div className="col">
          <p className="order-list-heading">Orders List</p>
        </div>
      </div>
      <Card className="border-none">
        <Table responsive className="custom-table-div">
          <thead className="page-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                ORDER NO
               
              </th>

              <th>customer name</th>

              <th>
                <div className="d-flex align-items-center">
                  order value
                  
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                commission %
                 
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  order placed on
                  
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                Commission fee
                
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="page-tbody-cell">
            {(
              data?.data?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{ cursor: 'pointer' }}
                      className="text-primary">
                      <NavLink
                        to={{
                          pathname: `/admin/order/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                      >
                        #{order?.id}
                      </NavLink>
                    </td>
                    <td> {order?.name} </td>
                    <td> {order?.total_amount || 0}</td>
                    <td> {order?.fees} </td>
                    <td> {moment(order?.created_at).format("DD-MM-YYYY")} </td>
                    <td> {moneyFormat(order?.commission || 0)} </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default OrderList;
