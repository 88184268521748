import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Col,
    FormFeedback,
    Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import { theme } from "utils/theme";
import { Link } from "react-router-dom";

const loginSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    phone: yup.string().length(10, 'Phone number must be 10 digit'),
});

const Register = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema) });
    const dispatch = useDispatch();
    const loggingIn = useSelector(state => state.loginReducer.loggingIn);
    const history = useHistory();

    function onLoginSubmit(data) {
        data.country_code = '91'
        data.role = 4
        dispatch(userActions.register(data, history));
    }

    return (
        // <Card>
        <div className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
                <img
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        objectFit: "contain"
                    }}
                    alt="..."
                    src={require("./../../assets/img/brand/playstore-icon.png").default}
                />
                <h3 className="mt-2 rz-heading rz-text-20">Hey there! </h3>
                <p style={{ color: "#5E5873" }} className="Montserrat-400">Sign up to portal now to become a channel partner</p>
            </div>
            <Form role="form" onSubmit={handleSubmit(onLoginSubmit)}>
                <FormGroup>
                    <Label for="phone" className=" Montserrat-400" style={{ color: "#6E6B7B" }}> Full name </Label>
                    <input
                        placeholder="Enter your Full name"
                        {...register('name')}
                        className={`form-control ${errors.phone ? 'is-invalid' : ''} `} style={{ color: "#6E6B7B" }} id="phone" />
                    <FormFeedback> {errors.name?.message} </FormFeedback>
                    <Label for="phone" className="mt-3 Montserrat-400" style={{ color: "#6E6B7B" }}> Mobile Number </Label>
                    <input
                        placeholder="Enter your mobile number"
                        {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''} Montserrat-400`} style={{ color: "#6E6B7B" }} id="phone" />
                    <FormFeedback> {errors.phone?.message} </FormFeedback>
                </FormGroup>
                <div className="text-center">
                    <Button type="submit"
                        className="my-1 w-100 rz-button-primary"
                        disabled={loggingIn}>
                        {loggingIn ? 'Please wait...' : 'Next'}
                    </Button>
                </div>
                <div className="text-center text-muted mb-4">
                    <p className="mt-2 "
                        style={{ color: "#5E5873" }}
                    >
                        Already a member?
                        <Link
                            to="/auth/login"
                            className="ryzer-text-primary Montserrat-500"
                            style={{
                                cursor: "pointer",
                                marginLeft: 4
                            }}>
                            Login here
                        </Link>
                    </p>
                </div>
            </Form>
        </div>
        // </Card>
    );
};

export default Register;
