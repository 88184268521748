import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import BadgeImage from "./../../assets/img/Artwork.svg"

const EmptyList = ({ message = "Data not found." }) => {
  return (
    <Row className="my-3 px-3 text-center">
      <Col style={{ height: "400px", flexDirection: 'column' }} className="d-flex justify-content-center align-items-center">
        <img
          style={{
            width: "200px",
            height: "200px",
            objectFit: 'contain',
            marginBottom: "10px"
          }}
          src={BadgeImage} alt="" />
        <p className="Montserrat-400 text-center" style={{ color: "#5E5873", width: "300px" }} >{message}</p>
      </Col>
    </Row>
  );
};

export default EmptyList;
