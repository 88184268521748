import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, FormGroup, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader, Container } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { propertyActions } from 'redux/actions';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import Select from 'react-select'
import PropertySetting from 'views/setting/PropertySetting';
import { useGetSecurityCover } from 'hooks/useAssetInfo';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { useUpdateSecurityCoverMutataion } from 'hooks/useAssetInfo';
import { useDeleteSecurityCoverMutataion } from 'hooks/useAssetInfo';
import { useAddSecurityCoverMutataion } from 'hooks/useAssetInfo';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const SecurityCoverSchema = yup.object().shape({
    header: yup.string().required('Required'),
    body: yup.string().required('Required')
})
const Legal = ({ stepper, type }) => {
    const dispatch = useDispatch()
    const { property } = useSelector(state => state.propertyReducer);
    const country = useSelector(state => state.getCountryReducer.countryStates);
    const [modal2, setModal2] = useState(false);
    const [mode, setMode] = useState("ADD");
    const toggle2 = () => setModal2(!modal2);
    const [collapse, setCollapse] = useState([]);
    const { data: propertySecurityCover, refetch } = useGetSecurityCover({ property_id: property?.id });
    console.log(propertySecurityCover, "propertySecurityCover")
    const { mutate: addSecurityCover, isLoading: addLoading } = useAddSecurityCoverMutataion()
    const { mutate: deleteSecurityCover, isLoading: deleteLoading } = useDeleteSecurityCoverMutataion()
    const { mutate: updateSecurityCover, isLoading: updateLoading } = useUpdateSecurityCoverMutataion()
    const [Id, setId] = useState(null);
    const toggleCollapse = (index) => {
        let collapseCopy = [...collapse];
        collapseCopy[index] = !collapseCopy[index];
        setCollapse(collapseCopy);
    }
    const [states, setStates] = useState([]);
    const [legalDropdown, setLegalDropdown] = useState([]);
    const { register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        reset: reset2,
        trigger: trigger2,
        formState: { errors: errors2, isValid: isValid2 } } = useForm({
            resolver: yupResolver(SecurityCoverSchema)
        })
    const keyHighLightSchema = yup.object().shape({
        current_tranche: yup.string().required('Required'),
        borrower: yup.string().required('Required'),
        security_cover: yup.string().required('Required'),
        interest_payouts: yup.string().required('Required'),
        security_cover_description: yup.string().required('Required')
    })
    const { register: register3,
        handleSubmit: handleSubmit3,
        setValue: setValue3,
        reset: reset3,
        trigger: trigger3,
        formState: { errors: errors3, isValid: isValid3 } } = useForm({
            resolver: yupResolver(keyHighLightSchema)
        })
    const closeBtn = (
        <button className="close" onClick={toggle2} type="button">
            &times;
        </button>
    );
    const onSubmit2 = async (data) => {
        data.property_id = property?.id;
        if (mode == 'ADD') {
            await addSecurityCover(data, {
                onSuccess: () => {
                    refetch();
                    toggle2();
                }
            })
        } else if (mode == 'EDIT') {
            await updateSecurityCover({ data, id: Id }, {
                onSuccess: () => {
                    refetch();
                    toggle2();
                }
            })
        }

    }
    const LegalSchema = yup.object().shape({
        // purchase_price: yup.string().required(),
        // circle_value: yup.string().required(),
        // stamp_duty: yup.string().required(),
        // brokrage_fee: yup.string().required(),
        // legal_fee: yup.string().required(),
        // reserves: yup.string().required(),
        // escrow_account: yup.string().required(),
        escrow_agent: yup.string().required(),
        // legal_advisory: yup.string().required(),
        // asset_management: yup.string().required(),
        // brokrage: yup.string().required(), 
        // legal_state_id:  yup.object().shape({
        //     label: yup.string().required(),
        //     value: yup.string().required()
        // })
    })
    const { register, handleSubmit, reset, setValue, getValues, control, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(LegalSchema)
    })

    const removeSecurityCover = async (id) => {
        if (window.confirm('Are you sure? Yuo wants to delete security')) {
            await deleteSecurityCover({
                security_cover_id: id,
                property_id: property?.id
            }, {
                onSuccess: () => {
                    refetch();
                }
            })
        }
    }
    useEffect(() => {
        if (country?.states) {
            const options = []
            country?.states.map(item => {
                options.push({ value: item.id, label: item.name })
            });
            setStates(options)
        }
    }, [country]);
    useEffect(() => {
        if (property?.share_type == 'NCD') {
            console.log(property?.security_cover_description, property?.current_tranche)
            reset3({
                security_cover_description: property?.security_cover_description,
                current_tranche: property?.current_tranche,
                borrower: property?.borrower,
                security_cover: property?.security_cover,
                interest_payouts: property?.interest_payouts
            })
        }
    }, [property])
    useEffect(() => {
        if (property) {
            if (property?.legal_state_id != null) {
                let selectedOption = states.find(({ value }) => value == property.legal_state_id);
                // prefill selected states legal dropdown
                onStateChange(selectedOption)
                reset({
                    purchase_price: property?.purchase_price,
                    circle_value: property?.circle_value,
                    stamp_duty: property?.stamp_duty,
                    brokrage_fee: property?.brokrage_fee,
                    legal_fee: property?.legal_fee,
                    reserves: property?.reserves,
                    escrow_account: property?.escrow_account,
                    escrow_agent: property?.escrow_agent,
                    legal_advisory: property?.legal_advisory,
                    asset_management: property?.asset_management,
                    brokrage: property?.brokrage,
                    legal_state_id: selectedOption
                })
            }
        }
    }, [property, states]);

    const onSubmit = (data) => {
        trigger();
        data.property_id = property.id;
        data.legal_state_id = data?.legal_state_id?.value; // extract value from react-select
        dispatch(propertyActions.storeAdditionalThree(data, stepper))
    }

    const onStateChange = (item) => {
        if (item !== undefined) {
            var stateLegal = country?.states.find(({ id }) => id == item.value);
            var options = stateLegal?.propertyLegal;
            setLegalDropdown(options);
            setValue('legal_state_id', item)
        } else {
            setValue('legal_state_id', null)
        }
    }

    const legalDropdownOptions = (id) => {
        return (
            legalDropdown?.map((item, i) => {
                if (item.legal_type_id != id) return false;
                return (
                    <option value={item.id} key={i}>{item.name}</option>
                )
            })
        )
    }
    const addEditModal = (mode, data = null) => {
        if (mode == 'ADD') {
            setMode("ADD")
            reset2({
                header: '',
                body: ''
            })
            setModal2(true);
        } else if (mode == 'EDIT') {
            setId(data.id);
            setMode('EDIT')
            reset2(
                {
                    header: data.header,
                    body: data.body
                }
            )
            setModal2(true);
        }

    }
    const onSubmit3 = (values) => {
        dispatch(propertyActions.updateKeyHighLight(property?.id, values));
    }
    return (
        <>
            <div className='legal-main px-0'  >
                <div className='row'>
                    <div className='content-header'>
                        <h3 className='mb-0 rz-heading rz-text-18'>Asset Legals</h3>
                    </div>
                    <div className='col-12'>
                        <Card className="bg-white shadow border-0 ">
                            {
                                property?.share_type == 'NCD' &&
                                <Form className='p-3' onSubmit={handleSubmit3(onSubmit3)}>
                                    <>
                                        <div className='content-header'>
                                            <h3 className='mb-0 text-muted'>Key Highlights </h3>
                                        </div>
                                        <Row>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`current_tranche`}> Current Tranche (in ₹)  <span className='required-star'>*</span> </Label>
                                                <input
                                                    id={`current_tranche`}
                                                    placeholder='Enter Tranche amount'
                                                    {...register3(`current_tranche`)}
                                                    className={classnames({ 'is-invalid': errors3.current_tranche, 'form-control': true })}
                                                />
                                                {errors3?.current_tranche && <div className='mt-2 required-star'>{errors3.current_tranche.message}</div>}
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`borrower`}> Borrower <span className='required-star'>*</span></Label>
                                                <input
                                                    id={`borrower`}
                                                    placeholder='Enter Borrower Name'
                                                    {...register3(`borrower`)}
                                                    className={classnames({ 'is-invalid': errors3.borrower, 'form-control': true })}
                                                />
                                                {errors3?.borrower && <div className='mt-2 required-star'>{errors3.borrower.message}</div>}
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`security_cover`}> Security Cover <span className='required-star'>*</span></Label>
                                                <input
                                                    id={`security_cover`}
                                                    placeholder='Enter Security Cover'
                                                    {...register3(`security_cover`)}
                                                    className={classnames({ 'is-invalid': errors3.security_cover, 'form-control': true })}
                                                />
                                                {errors3?.security_cover && <div className='mt-2 required-star'>{errors3.security_cover.message}</div>}
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`interest_payouts`}> Interest Payouts <span className='required-star'>*</span></Label>
                                                <input
                                                    id={`interest_payouts`}
                                                    placeholder='Enter Interest Payouts'
                                                    {...register3(`interest_payouts`)}
                                                    className={classnames({ 'is-invalid': errors3.interest_payouts, 'form-control': true })}
                                                />
                                                {errors3?.interest_payouts && <div className='mt-2 required-star'>{errors3.interest_payouts.message}</div>}
                                            </FormGroup>
                                            <FormGroup tag={Col} md='12'>
                                                <Label >Security Cover Description   <span className='required-star'>*</span></Label>
                                                <textarea
                                                    className={classnames({ 'is-invalid': errors3.security_cover_description, 'form-control': true })}
                                                    {...register3(`security_cover_description`)}
                                                    rows="4"
                                                    placeholder="Enter Description" />
                                                {errors3?.security_cover_description && <div className='mt-2 required-star'>{errors3.security_cover_description.message}</div>}
                                            </FormGroup>
                                            <Col sm={12} className='d-flex mt-3 justify-content-end' >
                                                <Button type='submit' className='rz-button-primary'>Submit</Button>
                                            </Col>
                                        </Row>
                                    </>
                                </Form>
                            }
                            <Form className='mt-3 p-3' onSubmit={handleSubmit(onSubmit)}>
                                {
                                    property?.share_type != 'NCD' &&
                                    <><div className='content-header'>
                                        <h3 className='mb-0 rz-heading rz-text-16'>Asset pricing information </h3>
                                    </div>
                                        <Row>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`purchase_price`}> Purchase Price </Label>
                                                <input
                                                    id={`purchase_price`}
                                                    placeholder='Enter amount'
                                                    {...register(`purchase_price`)}
                                                    className={classnames({ 'is-invalid': errors.purchase_price, 'form-control': true })}
                                                />
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`stamp_duty`}> Stamp Duty </Label>
                                                <input
                                                    id={`stamp_duty`}
                                                    placeholder='Enter amount'
                                                    {...register(`stamp_duty`)}
                                                    className={classnames({ 'is-invalid': errors.stamp_duty, 'form-control': true })}
                                                />
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`circle_value`}> Registration Fee </Label>
                                                <input
                                                    id={`circle_value`}
                                                    placeholder='Enter amount'
                                                    {...register(`circle_value`)}
                                                    className={classnames({ 'is-invalid': errors.circle_value, 'form-control': true })}
                                                />
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`legal_fee`}> Legal Fee </Label>
                                                <input
                                                    id={`legal_fee`}
                                                    placeholder='Enter amount'
                                                    {...register(`legal_fee`)}
                                                    className={classnames({ 'is-invalid': errors.legal_fee, 'form-control': true })}
                                                />
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`reserves`}> Corpus Fund  </Label>
                                                <input
                                                    id={`reserves`}
                                                    placeholder='Enter amount'
                                                    {...register(`reserves`)}
                                                    className={classnames({ 'is-invalid': errors.reserves, 'form-control': true })}
                                                />
                                            </FormGroup>
                                            <FormGroup tag={Col} md='6'>
                                                <Label className='form-label' htmlFor={`brokrage_fee`}> Brokrage Fee </Label>
                                                <input
                                                    id={`brokrage_fee`}
                                                    placeholder='Enter amount'
                                                    {...register(`brokrage_fee`)}
                                                    className={classnames({ 'is-invalid': errors.brokrage_fee, 'form-control': true })}
                                                />
                                            </FormGroup>
                                        </Row></>}
                                <div className='content-header  pt-3'>
                                    <h3 className='mb-0 rz-heading rz-text-16'>Legal Information</h3>
                                </div>
                                <Row>
                                    <FormGroup tag={Col} md='6'>
                                        <Label htmlFor="states">State</Label>
                                        <Controller
                                            name="legal_state_id"
                                            control={control}
                                            render={({ field, formState }) => {
                                                return (
                                                    <Select
                                                        id='legal_state_id'
                                                        {...field}
                                                        styles={{
                                                            control: styles => ({
                                                                ...styles,
                                                                boxShadow: formState.errors.legal_state_id ? 'none' : styles.boxShadow,
                                                                borderColor: formState.errors.legal_state_id ? 'red' : styles.borderColor
                                                            })
                                                        }}
                                                        onChange={onStateChange}
                                                        className='basic-multi-select'
                                                        options={states}
                                                        classNamePrefix="select"
                                                    />
                                                )
                                            }
                                            }
                                        />
                                    </FormGroup>
                                    {
                                        property?.share_type != 'NCD' &&

                                        <FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`escrow_agent`}>
                                                Escrow agent <span className='required-star'>*</span>
                                            </Label>
                                            <select className={classnames({ 'is-invalid': errors.escrow_agent, 'form-control': true })} {...register(`escrow_agent`)} id="escrow_agent">
                                                <option value=''>Select Option</option>
                                                {legalDropdownOptions(5)}
                                            </select>
                                        </FormGroup>
                                    }

                                </Row>
                                <Row>
                                    <FormGroup tag={Col} md='6'>
                                        <Label className='form-label' htmlFor={`escrow_account`}>
                                            {property?.share_type == 'NCD' ? 'Escrow Provider account' : 'Escrow account'}
                                        </Label>
                                        <select className={classnames({ 'is-invalid': errors.escrow_account, 'form-control': true })} {...register(`escrow_account`)} id="escrow_account">
                                            <option value=''>Select Option</option>
                                            {legalDropdownOptions(1)}
                                        </select>
                                    </FormGroup>
                                    <FormGroup tag={Col} md='6'>
                                        <Label className='form-label' htmlFor={`legal_advisory`}>
                                            {property?.share_type == 'NCD' ? 'Debenture Trustee' : 'Legal Advisory'}
                                        </Label>
                                        <select className={classnames({ 'is-invalid': errors.legal_advisory, 'form-control': true })} {...register(`legal_advisory`)} id="legal_advisory">
                                            <option value=''>Select Option</option>
                                            {legalDropdownOptions(2)}
                                        </select>
                                    </FormGroup>
                                    <FormGroup tag={Col} md='6'>
                                        <Label className='form-label' htmlFor={`asset_management`}>
                                            {property?.share_type == 'NCD' ? 'Compliance Team' : 'Asset Management'}
                                        </Label>
                                        <select className={classnames({ 'is-invalid': errors.asset_management, 'form-control': true })} {...register(`asset_management`)} id="asset_management">
                                            <option value=''>Select Option</option>
                                            {legalDropdownOptions(3)}
                                        </select>
                                    </FormGroup>
                                    {
                                        property?.share_type != 'NCD' &&
                                        <FormGroup tag={Col} md='6'>
                                            <Label className='form-label' htmlFor={`brokrage`}>
                                                Brokerag
                                            </Label>
                                            <select className={classnames({ 'is-invalid': errors.brokrage, 'form-control': true })} {...register(`brokrage`)} id="brokrage">
                                                <option value=''>Select Option</option>
                                                {legalDropdownOptions(4)}
                                            </select>
                                        </FormGroup>
                                    }
                                </Row>
                                {
                                    property?.share_type == 'NCD' &&
                                    <>
                                        <div className='content-header border-top  py-4 d-flex justify-content-between align-items-center mb-0'>
                                            <h3 className='mb-0 all-page-heading rz-text-18'>Security Cover Highlights</h3>
                                            <button onClick={() => addEditModal("ADD")} className="btn rz-button-primary" type="button">
                                                <HiOutlinePlusCircle className="mr-1" style={{ fontSize: "20px" }} /> Add
                                            </button>
                                        </div>
                                        <Row className='mb-5'>
                                            <Col>
                                                {
                                                    propertySecurityCover && propertySecurityCover.length > 0 &&
                                                    (
                                                        <>
                                                            <div className="accordion">
                                                                {
                                                                    propertySecurityCover.map((item, i) => {
                                                                        return (
                                                                            <div className="card" key={i}>
                                                                                <div className="card-header p-1 d-flex justify-content-between" id={`heading-${i}`}>
                                                                                    <h2 className="mb-0">
                                                                                        <button onClick={() => toggleCollapse(i)} key={i} className="text-dark btn btn-link btn-block text-left all-page-heading rz-text-14" type="button">
                                                                                            {item.header}
                                                                                        </button>
                                                                                    </h2>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <button onClick={() => removeSecurityCover(item.id)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                                                            <FaRegTrashAlt style={{ color: "#6E6B7B", fontSize: "18px" }} />
                                                                                        </button>
                                                                                        <button onClick={() => addEditModal("EDIT", item)} className="text-muted btn-sm btn btn-link" type="button">
                                                                                            <FiEdit style={{ color: "#6E6B7B", fontSize: "18px" }} />
                                                                                        </button>
                                                                                        <button onClick={() => toggleCollapse(i)} className="text-muted btn-sm btn btn-link" type="button">
                                                                                            <i style={{ color: "#6E6B7B", fontSize: "16px" }} className={`fas fa-chevron-${collapse[i] ? 'up' : 'down'}`}></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <div id={`collapse-${i}`} className={`collapse  ${collapse[i] ? 'show' : ''}`}>
                                                                                    <div className="card-body all-page-heading rz-text-14">
                                                                                        {item.body}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <PropertySetting property={property} />

                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                                    </Button>

                                    <div className='flex align-items-center justify-content-end '>
                                        <Link to='/admin/property'>
                                            <Button type='button' className='rz-button-outline-primary'>
                                                Cancel
                                            </Button>
                                        </Link>
                                        <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
                                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                                        </Button>
                                        <Button type='submit' className='rz-button-primary'>
                                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                                            <span className='align-middle d-sm-inline-block d-none'>Next</span>
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    </div>
                </div>
            </div>
            <Modal centered={true} isOpen={modal2} toggle={toggle2} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle2} close={closeBtn}>
                    <h1 className="mt-1 all-page-heading rz-text-18"  >Add Security Cover Highlights</h1>
                </ModalHeader>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                    <ModalBody className='py-0'>
                        <div>
                            <label>
                                Header *
                            </label>
                            <div className=''>
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register2('header')}
                                    placeholder='Enter header'
                                />
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label>
                                Body *
                            </label>
                            <textarea placeholder='Enter body' rows={3} cols={10} {...register2('body')} className='form-control rz-text-primary p-3' />
                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex justify-content-end'>
                            <div className='d-flex align-items-center'>

                                <Button type='button' onClick={toggle2} className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                                <Button type='submit' className='btn-next ml-3 d-flex justify-content-center align-items-center rz-button-primary'>

                                    <span className='align-middle d-sm-inline-block d-none' >
                                        Save
                                    </span>
                                </Button>

                            </div>
                        </div>
                    </ModalFooter>
                </form>



            </Modal>
        </>
    )
}

export default Legal;