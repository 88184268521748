import { Container, Row, Col, Button } from "reactstrap"
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useParams, useHistory } from "react-router-dom";
import clipboardCopy from "clipboard-copy";
import { IoMdCheckmark } from "react-icons/io";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import { useGetCode } from "hooks/useTokenSellect";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { useGetSpeceficData } from "hooks/useTokenSellect";
const PitchPresentation = () => {
    const { property_id } = useParams();
    const { data: code } = useGetCode({
        id: property_id
    })
    const { data } = useGetSpeceficData({
        id: property_id
    }, true);

    const history = useHistory();
    const handleCopy = (text) => {
        clipboardCopy(text)
            .then(() => {
                toast.success('Text copied to clipboard!');
            })
            .catch((error) => {
                toast.error('Failed to copy text:' + error?.toString());
            });
    };
    return (
        <>
            <div className="px-4 mb-5">
                <Container className="bg-white rz-shadow p-3" fluid>
                    <Row>
                        <Col sm={4}>
                            <div className="d-flex align-items-center">
                                <FaArrowLeft onClick={() => history.goBack()} className="cursor-pointer mr-3" style={{ color: '#6E6B7B' }} />
                                <h1 className="all-page-heading mb-0" style={{ fontSize: '18px' }}>View Asset details</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex pt-5 justify-content-center">
                        {/* <iframe frameborder="0" allowtransparency="true" allowFullscreen="true" style={{ width: "100%", height: " 700px", border: "none" }} src="https://online.visual-paradigm.com/share/slideshow/smaple-1l2dyas3p?p=1"></iframe> */}
                        {data &&
                            <iframe
                                src={`${data?.pitch_deck}`}
                                width="80%"
                                height="700px" />
                        }
                    </div>
                    <div className='mt-5 py-4 align-item-center d-flex justify-content-between'>
                        <div className="d-flex align-item-center">
                            <div className="d-flex mr-5" >
                                <div>
                                    <div>
                                        <p className="mb-0" style={{ color: '#A8B1BD', fontWeight: 500 }}>Asset ID: </p>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <p className="mr-2 mb-0" style={{ color: '#1F2329', fontWeight: 600 }}>{property_id}</p>
                                        <FiCopy onClick={() => handleCopy(code.code)} className="cursor-pointer" style={{ color: '#725AEC', fontSize: '20px' }} />
                                    </div>
                                </div>
                            </div>
                            {
                                code && code?.status == 'Active' && <div className="d-flex" sm='2'>
                                    <div>
                                        <div>
                                            <p className="mb-0" style={{ color: '#A8B1BD', fontWeight: 500 }}>Referral Code: </p>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className="mr-2 mb-0" style={{ color: '#1F2329', fontWeight: 600 }}>{code.code}</p>
                                            <FiCopy onClick={() => handleCopy(code.code)} className="cursor-pointer" style={{ color: '#725AEC', fontSize: '20px' }} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='d-flex  justify-content-end'>
                            <div>
                                <Button type='button' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => history.goBack()}>
                                    <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                    <span className='align-middle d-sm-inline-block d-none'>Back</span>
                                </Button>
                            </div>
                            {
                                (code && code?.status == 'Active') ?
                                    <div style={{ color: ' #28C76F' }} className="d-flex ml-3 align-items-center">
                                        <IoMdCheckmark className="rz-text-18" /> <div className="ml-2 Montserrat-500 ">Approved to Partner</div>
                                    </div>
                                    :
                                    <div>
                                        <Button type='submit' className='btn-next ml-3 rz-button-primary'>
                                            <span className='align-middle d-sm-inline-block d-none'>Request to Partner</span>

                                        </Button>
                                    </div>

                            }
                        </div>
                    </div>

                </Container>
            </div>
        </>
    )
}

export default PitchPresentation
