import Wizard from "./../../../components/Wizard";
import { useRef, useState } from "react";
import Location from "./Location";
import Amenity from "./Amenity";
import Legal from "./Legal";
import Faq from "./Faq";
import { useSelector } from "react-redux";
import PropertyUpdates from "./PropertyUpdates";
import PropertyHostedBy from "./PropertyHostedBy";
import PropertyMetadata from "./PropertyMetadata";
import { checkNotChannelPartner } from "utils";
import DueDiligence from "./DueDiligence";

const Index = ({ parentStepper }) => {
  const ref = useRef(null);
  const [stepper, setStepper] = useState(null);
  const { property } = useSelector((state) => state.propertyReducer);
  var sc = property?.step_completed;
  const steps = [
    {
      id: "location-step-1",
      title: "Location",
      subtitle: sc >= 3.1 ? "Completed" : "In Progress",
      content: (
        <Location
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "legals-step-5",
      title: property?.share_type == 'NCD' ? 'Issuer Details' : 'Asset Hosted By',
      subtitle:
        (Object.keys(property?.propertyHostedBy || {}).length > 0) ? "Completed" : "In Progress",
      content: (
        <PropertyHostedBy
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    },
  ];
  if (checkNotChannelPartner()) {
    steps.push(
      {
        id: "legals-step-2",
        title: "Due-Diligence",
        subtitle:
          property?.propertyDueDiligence?.length <= 0 ? "In Progress" : sc >= 3.4 ? "Completed" : "Not Completed",
        content: (
          <DueDiligence
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },
      {
        id: "amenity-step-2",
        title: "Features & Amenities",
        subtitle:
          sc == 3.1 ? "In Progress" : sc >= 3.2 ? "Completed" : "Not Completed",
        content: (
          <Amenity
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },
      {
        id: "legals-step-3",
        title: property?.share_type == 'NCD' ? 'Asset Info' : 'Asset Legals',
        subtitle:
          sc == 3.2 ? "In Progress" : sc >= 3.3 ? "Completed" : "Not Completed",
        content: (
          <Legal
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },
      {
        id: "legals-step-4",
        title: "Asset Updates",
        subtitle:
          sc == 3.3 ? "In Progress" : sc >= 3.4 ? "Completed" : "Not Completed",
        content: (
          <PropertyUpdates
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },
      {
        id: "legals-step-6",
        title: "T&C and Faq",
        subtitle:
          sc == 3.3 ? "In Progress" : sc >= 3.4 ? "Completed" : "Not Completed",
        content: (
          <Faq
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },
      {
        id: "legals-step-7",
        title: "Asset Metadata",
        subtitle:
          sc == 3.3 ? "In Progress" : sc >= 3.4 ? "Completed" : "Not Completed",
        content: (
          <PropertyMetadata
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },)
  }
  return (
    <div>
      <Wizard
        type="modern-vertical"
        ref={ref}
        steps={steps}
        className="shadow-none"
        options={{
          linear: false,
        }}
        contentclassName="shadow-none border-left rounded-0"
        instance={(el) => setStepper(el)}
      />
    </div>
  );
};

export default Index;
