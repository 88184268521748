import { Fragment, useEffect, useState, useCallback } from 'react'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { FormGroup, Row, Col, Button, Form, Label } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { propertyActions } from './../../../redux/actions'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { deletePropertyObj } from 'utils'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useUpdatePitchDeck } from 'hooks/useProperty'

const Gallary = ({ parentStepper, stepper, type }) => {
    const dispatch = useDispatch()
    const { property } = useSelector(state => state.propertyReducer);

    const [propertyMap, setPropertyMap] = useState(null);
    const [featureImage, setFeatureImage] = useState(null);
    const [featurePreview, setFeaturePreview] = useState('');
    const [mediaType, setMediaType] = useState('Image');
    const [videoUrl, setVideoUrl] = useState("")
    const [featured_video_type, setFeatured_video_type] = useState("")
    const { mutate: updatePithDeck } = useUpdatePitchDeck()
    const urlExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
    const mediaSchema = yup.object().shape({
        media_type: yup.string().required(),
        featured_video_type: yup.string(),
        gallary_images: yup.mixed().test("length", "Upload at least 1 gallary image", (value) => value && value.length !== 0),
        // featured_image: yup.mixed()
        //     .when('media_type', (mediaType, schema) => {
        //         if (mediaType == 'Image') {
        //             return schema.test("length", "Featured image is require", (value) => {
        //                 // if no file selected and feature image already saved no need to validate
        //                 if (!value.length && property.featured_image != null) return true
        //                 return value && value.length !== 0
        //             })
        //                 .test("fileSize", "The file is too large", checkIfFilesAreTooBig)
        //                 .test("fileFormat", "Unsupported Format! Only Image Allowed", (f) => checkIfFilesAreCorrectType(f, ['image/jpeg', 'image/png']))
        //         }
        //     }),
        // property_map_pdf: yup.mixed()
        //     .test("length", "Property attachment is require", (files) => {
        //         // if no file selected and property map already saved no need to validate
        //         if (!files.length && property.property_map_pdf != null) return true
        //         return files && files.length !== 0
        //     })
        //     .test("fileSize", "The file is too large", checkIfFilesAreTooBig)
        //     .test("fileFormat", "Unsupported Format! Only PDF Allowed", (f) => checkIfFilesAreCorrectType(f, ['application/pdf']))
    })

    const { register, handleSubmit, reset, watch, setValue, control, clearErrors, getValues, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(mediaSchema),
        defaultValues: {
            media_type: "Image",
        }
    })


    const onSubmit = async (data) => {
        trigger()
        await updatePithDeck({
            property_id: property?.id,
            pitch_deck: data.pitch_deck
        })
        data.media_type = "Image"
        const formData = new FormData();
        if (featured_video_type == '' || featured_video_type == undefined) {
            toast.error("Please Select Feature Video Type");
            return;
        }
        if (videoUrl == '' || videoUrl == undefined) {
            toast.error("Please Enter Feature Video URL");
            return;
        }
        formData.append('featured_video', videoUrl);
        formData.append('featured_video_type', featured_video_type);
        formData.append('featured_image', featureImage);
        formData.append('hidden_featured_image', property.featured_image)
        formData.append('media_type', data.media_type);
        formData.append('property_map_pdf', propertyMap);
        formData.append('hidden_property_map', property.property_map_pdf)
        formData.append('property_id', property.id);
        reset()
        dispatch(propertyActions.storeMediaOne(formData, stepper))
    }

    useEffect(() => {
        if (property) {
            setValue('pitch_deck', property?.pitch_deck)
            if (property?.media_type != null) {
                setPropertyMap(property?.property_map_pdf)
                setMediaType(property?.media_type)
                setFeaturePreview(property?.featured_image)
                setValue('media_type', property?.media_type)
                setVideoUrl(property?.featured_video)
                setFeatured_video_type(property?.featured_video_type)
                setValue('featured_video', property?.featured_video)
                setFeatureImage(property?.featured_image)
            }
            setValue('gallary_images', property?.medias)
        }
    }, [property]);

    const onDrop = useCallback((acceptedFiles, property) => {

        // verify if only total 5 files can be uploaded...
        let len = property.medias.length;
        let reqFile = acceptedFiles.length;
        if ((reqFile + len) > 20) {
            return toast.error('You can only upload max 20 images. This property already has ' + len + ' images uploaded.');
        }

        const formData = new FormData();
        acceptedFiles.forEach((file, i) => {
            formData.append('gallary_images', file);
        })
        formData.append('property_id', property.id);
        dispatch(propertyActions.storeMediaOneGallary(formData))
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (droppedFiles) => onDrop(droppedFiles, property),
        accept: 'image/jpeg, image/png'
    })

    const onFeatureImageChange = async (e) => {
        if (e.target.files[0]) {
            let file = e.target.files[0]
            const reader = new FileReader()
            reader.onload = () => setFeaturePreview(reader.result);
            reader.readAsDataURL(file)
            setFeatureImage(file);
        } else {
            setFeaturePreview('')
            setFeatureImage('');
        }
    }

    const onPropertyMapChange = async (e) => {
        let file = e.target.files[0]
        if (e.target.files[0]) {
            setPropertyMap(file)
        }
    }

    const onTypeChange = async (e) => {
        setMediaType(e.target.value)
    }

    const onRemoveGallary = (e, id) => {
        e.preventDefault()
        dispatch(propertyActions.deleteMediaOneGallary(id))
    }
    const featured_image = watch("featured_image");
    const gallary_images = watch("gallary_images");
    const allFieldsFilled = featured_video_type !== undefined && featured_video_type !== '' && videoUrl !== undefined && videoUrl !== '' && featured_image !== undefined && gallary_images !== undefined && gallary_images.length > 0;
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading-18'>Gallery</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="card rz-card-border">
                    <div className="card-body rz-shadow-none">
                        <h3 className="rz-heading-16">Featured Image *</h3>
                        {/* <Row>
                            <FormGroup tag={Col} md='6'>
                                <div className="form-check form-check-inline">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" name='media_type' id="media-type-1" {...register(`media_type`, { onChange: (e) => onTypeChange(e) })} value='Image' className={classnames({ 'is-invalid': errors.media_type, 'custom-control-input': true })} />
                                        <label className="custom-control-label" htmlFor="media-type-1">Image</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" name='media_type' id="media-type-2" {...register(`media_type`, { onChange: (e) => onTypeChange(e) })} value='Video' className={classnames({ 'is-invalid': errors.media_type, 'custom-control-input': true })} />
                                        <label className="custom-control-label" htmlFor="media-type-2">Video</label>
                                    </div>
                                </div>
                            </FormGroup>
                        </Row> */}
                        <Row>
                            <Col>
                                <FormGroup className='d-flex align-items-center '>

                                    <div className='pr-4 d-flex align-items-center'>
                                        <div>
                                            <h5 className='mb-0 text-muted'>Main image <span className='required-star'>*</span>:</h5>
                                            <small className='text-muted'>Required image resolution 800x400, image size 10mb.</small>
                                            <div className="custom-file">
                                                <input type="file" {...register(`featured_image`, { onChange: (e) => onFeatureImageChange(e) })} className={classnames({ 'is-invalid': errors.featured_image, 'custom-file-input': true })} id="customFile" accept="image/*" />
                                                <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                            </div>
                                            {errors.featured_image ? (
                                                <small className="form-text text-danger">{errors.featured_image.message}</small>) : (
                                                <small className="form-text text-muted">{featureImage?.name}</small>
                                            )}
                                        </div>
                                    </div>
                                    {featurePreview && (
                                        <div>
                                            <img src={featurePreview} className="img-fluid mr-4 rounded" style={{ width: '200px', height: "200px", objectFit: "contain" }} />
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label className='form-label' htmlFor={`property_type`}>
                                        Select Video Type <span className='required-star'>*</span>
                                    </Label>
                                    <select
                                        onChange={(e) => setFeatured_video_type(e.target.value)}
                                        id="property_type"
                                        className={classnames({ 'is-invalid': errors.featured_video_type, 'form-control': true })}
                                    >
                                        <option value=''>Select Video Type <span className='required-star'>*</span></option>
                                        <option
                                            selected={"youtube" == featured_video_type}
                                            value="youtube">Youtube</option>
                                        <option
                                            selected={"vimeo" == featured_video_type}
                                            value="vimeo">Vimeo</option>
                                    </select>
                                </FormGroup>
                                <FormGroup>
                                    <label >Feature Video Link <span className='required-star'>*</span></label>
                                    <input
                                        value={videoUrl}
                                        onChange={e => setVideoUrl(e.target.value)}
                                        className="form-control w-100"
                                        placeholder="Paste Video Link" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <h3 className='mb-0 text-muted'> Gallery (Upto 10 Images)</h3>
                                <small className='text-muted'>Required image resolution 800x400, image size 10mb.</small>
                                <div className='mt-2'>
                                    <div {...getRootProps()}>
                                        <input  {...getInputProps()} />
                                        <input type='hidden' {...register(`gallary_images`)} />
                                        <div className='dropzone'>
                                            {
                                                isDragActive ?
                                                    <p>Drop the files here ...</p> :
                                                    <p>Drag 'n' drop some files here, or click to select files <br /><i>(Only *.jpeg and *.png images will be accepted)</i></p>
                                            }
                                        </div>
                                    </div>
                                    {errors.gallary_images && (
                                        <small className="form-text text-danger">{errors.gallary_images.message}</small>
                                    )}
                                </div>
                            </Col>
                            <Col>
                                <h3 className='text-muted text-center'>Image previews</h3>
                                <div className='d-flex justify-content-center align-items-center h-100 flex-column'>


                                    {property && property.medias && (
                                        <div className='d-flex flex-row flex-wrap'>
                                            {
                                                property.medias.map((item, i) => {
                                                    return (
                                                        <span className="img-container mb-3 p-1" key={i} >
                                                            <img src={item.url} className="rounded" style={{ width: '175px', cursor: 'pointer' }} />
                                                            <a href='#' className='bg-white' onClick={(e) => onRemoveGallary(e, item.id)}>
                                                                <i className='fa fa-times text-sm text-black rm-gallary-img m-2'></i>
                                                            </a>
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='mt-4'>
                    <h2 className='all-page-heading pl-2 rz-text-18'>Asset Pitch Deck</h2>
                    <FormGroup md={6} tag={Col}>
                        <label >Pitch deck link <span className='required-star'>*</span></label>
                        <input
                            {...register('pitch_deck')}
                            className="form-control"
                            placeholder="Paste Link" />
                    </FormGroup>
                </div>
                {property?.stage == "Pre-leased" &&
                    <>
                        <div className='content-header mt-3'>
                            <h3 className='mb-0 text-muted'>Upload {"Floor Map"}</h3>
                            <small className='text-muted'>Required doc is PDF which should not exceed 25mb.</small>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5 className='mb-0 text-muted'>{property?.property_type_id == 1 ? "Property map" : "Floor Map"}</h5>
                                <small className='text-muted'>Required doc is PDF which should not exceed 25mb.</small>
                                <Row>
                                    <Col md='6'>
                                        <FormGroup className='mt-3'>
                                            <label id='property-map-file'>
                                                Attachments {propertyMap != null && !propertyMap.name && (
                                                    <p>Uploaded PDF<a href={propertyMap} target='_blank'> View</a></p>
                                                )}
                                            </label>
                                            <div className="custom-file">
                                                <input type="file" {...register(`property_map_pdf`, { onChange: (e) => onPropertyMapChange(e) })} className={classnames({ 'is-invalid': errors.property_map_pdf, 'custom-file-input': true })} id="customFile" accept="application/pdf" />
                                                <label className="custom-file-label" id='property-map-file'>Choose file</label>
                                            </div>
                                            {errors.property_map_pdf ? (
                                                <small className="form-text text-danger">{errors.property_map_pdf.message}</small>) : (
                                                <small className="form-text text-muted">{propertyMap?.name}</small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>}
                <div className='mt-5 align-item-center d-flex justify-content-between'>
                    <div>
                        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                            <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                            <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                        </Button>
                    </div>
                    <div className='d-flex align-item-center justify-content-end'>
                        <Link to='/admin/property'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button type='button' className=' ml-3 mr-2  rz-button-outline-primary' onClick={() => parentStepper.previous()}>
                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                        </Button>
                        <Button type='submit' disabled={!allFieldsFilled} className='rz-button-primary'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Next</span>

                        </Button>
                    </div>
                </div>
            </Form>
        </Fragment>
    )
}

export default Gallary
// const fieldNames = ['name', 'property_type_id', 'initiated_year', 'description', 'total_land_area'];
// const watchAllFields = watch(fieldNames);
// const allFieldsFilled = Object.values(watchAllFields).every(field => field !== undefined && field !== null && field !== '');

// function checkIfFilesAreTooBig(files) {
//     let valid = true
//     if (files.length > 0) {
//         const size = files[0].size / 1024 / 1024
//         if (size > 20) valid = false
//     }
//     return valid
// }

// function checkIfFilesAreCorrectType(files, types) {
//     let valid = true
//     if (files.length > 0) {
//         if (!types.includes(files[0].type)) valid = false
//     }
//     return valid
// }
// function changeVideoThumbnail(value, preview) {
//     setVideoUrl(value);
//     // if (preview && preview == "preview") {
//     //     if (value) {
//     //         let value_arr = value.split("/");
//     //         if (value_arr.length > 0) {
//     //             if (value_arr[3]) {
//     //                 let youtube_id = value_arr[3];
//     //                 let video_url = `https://i1.ytimg.com/vi/${youtube_id}/hqdefault.jpg`;
//     //                 return video_url
//     //             }
//     //         }
//     //     }
//     // } else {
//     //     setVideoUrl(value);
//     //     if (value) {
//     //         let value_arr = value.split("/");
//     //         if (value_arr.length > 0) {
//     //             if (value_arr[3]) {
//     //                 let youtube_id = value_arr[3];
//     //                 let video_url = `https://i1.ytimg.com/vi/${youtube_id}/hqdefault.jpg`;
//     //                 return
//     //             }
//     //         }
//     //     }
//     // }
// }
