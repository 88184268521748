import { store } from "redux/store";

export function isLogin(){
    let user = JSON.parse(localStorage.getItem('user'));
    return user ? true : false;
}

export function getStorage(key){
    return JSON.parse(localStorage.getItem(key))
}

export function truncate(str, n = 10){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

export function moneyFormat(price, localeType = 'en-IN'){
    if (price === undefined) price = 0;

    let locale = Intl.NumberFormat(localeType, {
        style: "currency",
        currency: "INR",
    });
    return locale.format(price)
}

export function deletePropertyObj(arrKey, removeId){
    const { property } =  store.getState().propertyReducer
    property[arrKey] = property[arrKey].filter(s => s.id != removeId);
    return property
}
export function updatePropertyDocumentObj(arrKey, removeId, _protected,_status) {
    const { property } = store.getState().propertyReducer
    console.log(arrKey, removeId, _protected,_status)
    if(!_status)
    property[arrKey].filter(s => s.id == removeId)[0].protected = _protected;
    if(_status!=undefined)
    property[arrKey].filter(s => s.id == removeId)[0].status = _status;
    return property
}
export function appTitle(title){
    return document.title = 'Ryzer:: ' + title
}

export function generateArrayOfYears(minYears = 0, maxYears = 4) {
    var currYear = new Date().getFullYear()
    var max = currYear + maxYears
    var min = currYear - minYears
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.unshift(i)
    }
    return years
}
export const PERMISSIONS = {
    MANAGE: 'manage',
    CREATE: 'write',
    READ: 'read',
    UPDATE: 'edit',
    DELETE: 'delete'
}
export const PROPERTY_TYPE = {
    LANDPARCEL: "LAND PARCEL",
    RESIDENTIAL: "RESIDENTIAL",
    COMMERCIAL: "COMMERCIAL",
    HOLIDAY_HOME: "HOLIDAY_HOME",
    // AGRICULTURE_LAND: "AGRICULTURE LAND",
}
export const PROPERTY_TYPE_VALUE = {
    LANDPARCEL: 1,
    RESIDENTIAL: 2,
    COMMERCIAL: 3,
    HOLIDAY_HOME: 4,
    // AGRICULTURE_LAND: 5,
}
export const RESOURCE = {
    Property: 'Property',
    Company: 'Company',
    Configuration: 'Configuration',
    Amenity: 'Amenity',
    Order: 'Order',
    Role: 'Role',
    Customer: 'Customer',
    Employee: 'Employee',
    Reviews: 'Reviews',
    Feedback: 'Feedback',
    DAO: 'DAO',
    Setting: 'Setting',
    Report: 'Report',
    Channel_Partner: "Channel Partner",
    SuperAdmin: "SuperAdmin",
}

export function CheckPermission(action, resource) {
    if (Object.keys(store.getState().authReducer).length != 0) {
        const { rules } = store.getState().authReducer;
        var checkPermission = false;
        rules?.map((rule) => {
            if (rule[1] == resource) {
                if (rule[0] == action) {
                    checkPermission = true;
                }
            }
        })
        return checkPermission
    }
}

export const addTokenToMetamask = (
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
) => {
    const { ethereum } = window;
    if (ethereum) {
        ethereum
            .request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: "https://reallyyappproduction.s3.ap-south-1.amazonaws.com/logomark.png", // A string url of the token logo
                    },
                },
            })
            .catch((error) => {
                if (error.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    console.log('We can encrypt anything without the key.');
                } else {
                    console.error(error);
                }
            });
    }
};

export const redirectToPropertyDao = (property) => {
    localStorage.setItem("property_id", property?.id)
    localStorage.setItem("property_name", property?.name)
    localStorage.setItem("sto_sale_address", property?.sto_sale_address);
    localStorage.setItem("blockchain_metadata", JSON.stringify(property?.blockchain_metadata || {}));
}
export function formatNumberWithRupeeSymbol(number) {
    const suffixes = ["", "K", "M", "B"];
    let suffixIndex = 0;

    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
    }

    const formattedNumber = number?.toFixed(2);
    const suffix = suffixes[suffixIndex];
    return `₹${formattedNumber}${suffix}`;
}
export function checkNotChannelPartner() {
    if (localStorage.getItem("org_id")) {
        return false;
    }
    else {
        return true;
    }
}
export function checkAttechment(attachement1) {
    return typeof attachement1 == "string" && attachement1 != ""
}

export function getPropertyValue(property) {
    const asset_category = property?.asset_category;
    const property_type_id = property?.property_type_id;
    const share_type = property?.share_type;
    if (share_type == "NCD") {
        return 3;
    }
    /*
    Asset Categoty-: Developed Land
    1. Land Parcel (Developed Land)
    ==>Return SVG Upload
        */
    if (asset_category === "Developed Land" && property_type_id == 1) {
        return 1;
    }
    /* 
    Asset Categoty-: Tower
    1. Residencial  Flow
    2. Holiday Homes
    ==>Return Plot Adding
        */
    else if (asset_category === "Tower" && (property_type_id == 2 || property_type_id == 4)) {
        return 2;
    }
    /* 
    Asset Categoty-: Villa
    1. Residencial Flow (Villa)
    2. Holiday Homes (Villa)
    3. Commercial (Tower or Villa) 
    4. Land Parcel (Individual Land)  
    ==>Return Single Plot
    */
    else if (asset_category === "Villa"
        && (property_type_id == 2 || property_type_id == 4) ||
        (property_type_id == 3) ||
        (asset_category === "Individual Land" && property_type_id == 1)
    ) {
        return 3;
    } else {
        return null; // Return null or handle the case when no matching condition is found
    }
}
export function Serializer(obj) {
    var str = [];
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            // Check if the value is not undefined, null, or an empty string
            if (obj[p] !== undefined && obj[p] !== null && obj[p] !== "") {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }
    return str.join("&");
}