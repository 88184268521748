import React, { useState } from 'react'
import { Card, CardBody, Button, CardSubtitle, CardTitle, Nav, NavItem } from 'reactstrap';
import TransectionList from './TransectionList';
import { moneyFormat } from 'utils';
import { FaArrowLeft } from "react-icons/fa6";
import { AiOutlineHome } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import briefcaseIcon from './../../../../assets/img/briefcase.png';
import { useGetSellerWithdraw } from 'hooks/useFeeManagement ';
import WithdrawTimeline from './WithdrawTimeline';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const styles = {
    cardStyle: {
        display: 'flex',
        marginTop: "3%",
        justifyContent: "space-between"
    },
    textStyle: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#82868B',
    },
    numberStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '24px',
        color: '#7367F0',
    }
}
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}
export default function ChanelPartnerMyCommission({
    data,
    isLoading,
    total,
    type
}) {
    const ColorCard = ({ color, title, subTitle, icon }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };
        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingTop: "40px",
                    paddingBottom: "40px"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{moneyFormat(title)}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                    {icon && (<div style={{
                        marginLeft: 2
                    }}>
                        <img src={icon} />
                    </div>)}
                </CardBody>
            </Card>
        );
    };
    const [tab, setTab] = useState(1);
    const withdrawData = useGetSellerWithdraw({
        type: type == 1 ? 'Asset Seller' : 'Token Seller'
    })
    console.log(withdrawData,"withdrawData")
   
    return (
        <>
            <div className="px-4">
                <div className="row mx-0">
                    <div className='col-6 pl-2'>
                        <div className='d-flex align-items-center'>
                            <h3 className='mb-0' style={headingstyle}>My Commission</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-4">
                        <div className="row">
                            <div className="col-12">
                                <ColorCard
                                    color="#E9573E"
                                    title={total?.totalAmountSum}
                                    subTitle="Total Balance"
                                    icon={briefcaseIcon}
                                />
                            </div>
                            <div className="col-6 mt-4">
                                <ColorCard
                                    color="#289B96"
                                    title={total?.totalWithdrawSum}
                                    subTitle="Total Withdrawn (net)"
                                // icon="fas fa-lock"
                                />
                            </div>
                            <div className="col-6 mt-4">
                                <ColorCard
                                    color="#7C278A"
                                    title={total?.totalAmountSum}
                                    subTitle="Total Collected"
                                // icon="fas fa-arrow-up"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-4">
                        <Card className='py-3 px-4' style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                            <div className="d-flex justify-content-between">
                                <h2 style={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#5E5873',
                                }} tag="h2">Fee Breakup</h2>
                                {/* <p tag="p"
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#6E6B7B',
                                    }}
                                >Updated 1 hour ago <img className='ml-2' src={refreshIcon}/></p> */}
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Assets</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.totalAssets}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Orders</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.totalOrderSum}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Transactions</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.totalOrderSum * 2}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Customers</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.total_user}</CardSubtitle>
                            </div>
                            <div className='mt-5' style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Last Transaction</h2>
                                <p tag="h3"
                                    style={
                                        {
                                            fontFamily: 'Montserrat',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            color: '#797979',
                                        }
                                    }>{new Date(data?.data?.[0]?.created_at)?.toDateString()}</p>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className='d-flex pt-5'>
                    <Button
                        type="button cursor-pointer"
                        className={`rz-shadow-none ${tab === 1 ? 'rz-button-primary' : 'white'
                            }`}
                        onClick={() => setTab(1)}
                    >
                        <AiOutlineHome style={{ color: tab == 1 ? 'white' : '', fontSize: "20px" }} />
                        <span className=''> Asset List</span>
                    </Button>
                    <Button
                        type="button"
                        className={`rz-shadow-none button cursor-pointer ${tab === 2 ? 'rz-button-primary' : 'white'
                            }`}
                        onClick={() => setTab(2)}
                    >
                        <BiTimeFive style={{ color: tab == 2 ? 'white' : '', fontSize: "20px" }} />
                        <span >Withdraw timeline</span>
                    </Button>
                </div>
                <div className='mt-5'>
                    {tab == 1 && <TransectionList
                        type={type}
                        loading={isLoading}
                        orders={data?.data}
                    />}
                    {!withdrawData.isLoading && tab == 2 && <WithdrawTimeline data={withdrawData?.data} />}
                </div>
            </div>
        </>
    )
}
