import { userConstants } from '../../constants';

export function meReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ME_REQUEST:
            return { gettingMe: true };
        case userConstants.GET_ME_SUCCESS:
            return { me: action.payload.data ? action.payload.data : action.payload };
        case userConstants.GET_ME_FAILURE:
            return {};
        default:
            return state
    }
}