import classNames from "classnames";
import { useUpdateChannelKycDetails } from "hooks/useMeQuery";
import { useGetChannelKycWithId } from "hooks/useMeQuery";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Button, ModalFooter } from "reactstrap";
const CustomModal = ({ title, content, onClose, status, onSubmitChannel }) => {
  const [reason, setReason] = useState('');
  const onSubmit = () => {
    onSubmitChannel(reason);
    onClose();
  }
  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          {title == "Decline" &&
            <i className="fas fa-times-circle" style={{
              fontSize: '80px',
              color: "#EA5455"
            }} />
          }
          {title == "Hold" &&
            <i className="fas fa-pause-circle" style={{
              fontSize: '80px',
              color: "#EA5455"
            }} />
          }
          {title == "Approve" &&
            <i className="fas fa-check-circle" style={{
              fontSize: '80px',
              color: "#28C76F"
            }} />
          }

          <div>
            <p className='mt-2'
              style={{
                textAlign: "center",
                // width: "40%"
              }}>
              {content}
            </p>
            {title != "Approve" &&
              <Form>
                <Row>
                  <FormGroup tag={Col}>
                    <Label className='form-label' htmlFor={`reason`}>
                      Reason
                    </Label>
                    <input
                      id={`reason`}
                      placeholder='Enter a reason '
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      className={classNames({ 'form-control': true })}
                    />
                  </FormGroup>
                </Row>
              </Form>
            }

          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-outline-primary"
          onClick={onClose}>
          No
        </Button>
        <Button
          color='primary'
          className='btn-next'
          onClick={onSubmit}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const ChannelPartnerKYC = ({ }) => {
  const location = useLocation();
  const [isRejectedOpen, setIsRejectedOpen] = useState(false);
  const { user } = location.state;
  const { data } = useGetChannelKycWithId({
    user_id: user.id
  })
  const handleCloseModals = () => {
    setIsRejectedOpen(false);
  };
  const { mutate: updateChannelParnetKyc } = useUpdateChannelKycDetails()
  const onSubmitChannel = async (status) => {
    data.user_id = user.id
    data.status = status;
    if (status == "rejected") {
      setIsRejectedOpen(true);
      return;
    }
    await updateChannelParnetKyc(data, {
      onSuccess: (res) => {
        toast.success(res?.message);
      }
    })
  };
  const onSubmitReject = async (reason) => {
    data.user_id = user.id
    data.status = "rejected";
    data.remark = reason;
    await updateChannelParnetKyc(data, {
      onSuccess: (res) => {
        toast.success(res?.message);
      }
    })
  };
  return (
    <Container>
      <Row>
        <Col>
          <h3>KYC Information</h3>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{
            padding: 10
          }}>
            <h2>Personal Information</h2>
            <div>
              <p>
                <strong>Name:</strong> {data?.name}
              </p>
              <p>
                <strong>Email:</strong> {data?.email}
              </p>
              <p>
                <strong>Phone:</strong> {data?.phone}
              </p>
              <p>
                <strong>Address:</strong> {data?.address}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="mt-2"
            style={{
              padding: 10
            }}>
            <h2>Aadhar Information</h2>
            <p>
              <strong>Aadhar Number:</strong> {data?.kyc?.aadhar_metadata?.aadhar_number}
            </p>
            <div className="d-flex mt-2 justify-content-start align-items-center">
              <div>
                <img
                  style={{
                    width: "70%",
                    height: "70%",
                  }}
                  src={data?.kyc?.aadhar_metadata?.front_photo} alt="Aadhar Front" />
              </div>
              <div>
                <img
                  style={{
                    width: "70%",
                    height: "70%",
                  }}
                  src={data?.kyc?.aadhar_metadata?.back_photo} alt="Aadhar Back" />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mt-2"
            style={{
              padding: 10
            }}>
            <h2>PAN Information</h2>
            <p>
              <strong>PAN Number:</strong> {data?.kyc?.pan_metadata?.pan_number}
            </p>
            <img
              style={{
                width: "70%",
                height: "70%",
              }}
              src={data?.kyc?.pan_metadata?.front_photo} alt="PAN Front" />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="mt-2"
            style={{
              padding: 10
            }}
          >
            <h2>Bank Information</h2>
            <p>
              <strong>IFSC Code:</strong> {data?.kyc?.video_kyc_metadata?.ifsc}
            </p>
            <p>
              <strong>Account Number:</strong> {data?.kyc?.video_kyc_metadata?.account_number}
            </p>
            <p>
              <strong>Full Name:</strong> {data?.kyc?.video_kyc_metadata?.bank_data?.user?.full_name}
            </p>
            <p>
              <strong>Bank Address:</strong> {data?.kyc?.video_kyc_metadata?.bank_data?.bank?.ADDRESS}
            </p>
            <p>
              <strong>RERA Number:</strong> {data?.kyc?.video_kyc_metadata?.RERA_number}
            </p>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="mt-2"
            style={{
              padding: 10
            }}
          >
            <h2>RERA Information</h2>
            <p>
              <strong>Number:</strong> {data?.kyc?.video_kyc_metadata?.reraAggentNumber}
            </p>
          </Card>
          <Card className="mt-2"
            style={{
              padding: 10
            }}>
            <p>
              <strong>User KYC Status:</strong> {data?.kyc?.status}
            </p>
          </Card>
        </Col>
      </Row>
      {
        (data?.kyc?.status != 'auto_approved' && data?.kyc?.status != 'rejected') ?
          <div className="d-flex mt-2 justify-content-center align-items-center">
            <button type="button"
              onClick={() => onSubmitChannel("rejected")}
              className="btn btn-danger">Rejected</button>
            <button type="button"
              onClick={() => onSubmitChannel("auto_approved")}
              className="btn btn-success">Approved</button>
          </div> :
          null
      }
      {isRejectedOpen && (
        <CustomModal
          title="Decline"
          status="Rejected"
          content="Are you sure want to decline this KYC details? Please enter relevant reason so the channel partner can retry."
          onClose={handleCloseModals}
          onSubmitChannel={onSubmitReject}
        />
      )}
    </Container>
  );
};

export default ChannelPartnerKYC;
