import Wizard from "./../../../components/Wizard";
import { useRef, useState } from "react";
import CreateERC20 from "./CreateERC20";
import CompanySelect from "./CompanySelect";
import CreateDAO from "./CreateDAO";
import STORole from "./STORole";

import { useSelector } from "react-redux";
import CreateINDR from "./CreateINDR";

const Index = ({ parentStepper }) => {
    const ref = useRef(null);
    const [stepper, setStepper] = useState(null);
    const property = useSelector((state) => state.propertyReducer.property);
    var stepNo = null;
    var sc = property?.step_completed;
    const steps = [
        {
            id: "company-1",
            title: "Select Company",
            subtitle: sc >= 4.1 ? "Completed" : "In Progress",
            content: (
                <CompanySelect
                    parentStepper={parentStepper}
                    stepper={stepper}
                    type="wizard-vertical"
                />
            ),
        },
        {
            id: "company-2",
            title: "Create Security token",
            subtitle: (property?.sto_erc20_address != undefined) ? "Completed" : "Not Completed",
            content: (
                <CreateERC20
                    parentStepper={parentStepper}
                    stepper={stepper}
                    type="wizard-vertical"
                />
            ),
        },
        {
            id: "company-3",
            title: "Create INDR Wrapper token",
            subtitle: (property?.indr_token_address != undefined) ? "Completed" : "Not Completed",
            content: (
                <CreateINDR
                    parentStepper={parentStepper}
                    stepper={stepper}
                    type="wizard-vertical"
                />
            ),
        },
        {
            id: "company-4",
            title: "Create DAO",
            subtitle: (property?.sto_sale_address != undefined) ? "Completed" : "Not Completed",
            content: (
                <CreateDAO
                    parentStepper={parentStepper}
                    stepper={stepper}
                    type="wizard-vertical"
                />
            ),
        },
        {
            id: "company-5",
            title: "DAO Role Setup",
            subtitle: (property?.sto_sale_address != undefined) ? "Completed" : "Not Completed",
            content: (
                <STORole
                    parentStepper={parentStepper}
                    stepper={stepper}
                    type="wizard-vertical"
                />
            ),
        },

    ];

    return (
        <div>
            <Wizard
                type="modern-vertical"
                ref={ref}
                steps={steps}
                className="shadow-none"
                options={{
                    linear: false,
                }}
                contentclassName="shadow-none border-left rounded-0"
                instance={(el) => setStepper(el)}
            />
        </div>
    );
};

export default Index;
