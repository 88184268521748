import { useMutation, useQuery } from "react-query";
import { api } from "../helpers";
import { toast } from "react-toastify";
const useGetSecurityCover = (options = {}) => {
    return useQuery([`security_cover`, options],
        async ({ queryKey }) => {
            console.log(options, "data.data")
            const { data } = await api.get(`/property/security_cover/byProperty/${options.property_id}`);
            console.log(data, "data.data")
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useAddSecurityCoverMutataion = () => {
    return useMutation(async (options) => {
        await api.post('/property/security_cover', options);
    },
        {
            onSuccess: () => {
                toast.success("Security Cover Add sucessfully");
            },
            onSettled: () => {

            },
            onError: (error) => {

            },
        }
    )
}
const useUpdateSecurityCoverMutataion = () => {
    return useMutation(async ({ data, id }) => {
        await api.put(`/property/security_cover/${id}`, data);
    },
        {
            onSuccess: () => {
                toast.success("Security Cover Update sucessfully");
            },
            onSettled: () => {

            },
            onError: (error) => {

            },
        }
    )
}
const useDeleteSecurityCoverMutataion = () => {
    return useMutation(async (option) => {
        await api.delete(`/property/security_cover/${option.security_cover_id}?property_id=${option.property_id}`);
    },
        {
            onSuccess: () => {
                toast.success("Security Cover Delete sucessfully");
            },
            onSettled: () => {

            },
            onError: (error) => {

            },
        }
    )
}
export {
    useGetSecurityCover,
    useDeleteSecurityCoverMutataion,
    useAddSecurityCoverMutataion,
    useUpdateSecurityCoverMutataion
}